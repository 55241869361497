import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles, TextField } from '@material-ui/core';
import {
  FORM_WRAPPER, FORM, CONTENT_HEADER, CONTENT_TEXT
} from '../styles/global';
import { setNewPassword as setNewPasswordAction } from '../actions/user';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getServiceError } from '../lib/http';
import CustomButton from '../components/CustomButton';
import { getErrorMessage, getSuccessMessage } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...FORM_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  form: {
    ...FORM(theme)
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNewPassword: (token, newPassword) => dispatch(setNewPasswordAction(token, newPassword)),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message))
});

const ResetPassword = ({ classes, setNewPassword, showGlobalSnackbar }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();

  const handleResetPassword = () => {
    if (!password || !confirmPassword) {
      showGlobalSnackbar(getErrorMessage('All fields are required.'));
      return;
    }

    if (password !== confirmPassword) {
      showGlobalSnackbar(getErrorMessage('Passwords do not match.'));
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    setNewPassword(token, password)
      .then(() => history.push('/password-reset/confirmation'))
      .catch((error) => showGlobalSnackbar(getServiceError(error)));
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>Reset Password</p>
      <p className={classes.text}>Enter and confirm your new password below.</p>
      <form
        className={classes.form}
        onSubmit={(e) => e.preventDefault()}
      >
        <TextField
          autoFocus
          className={classes.input}
          label="New Password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          value={password}
          variant="filled"
        />
        <TextField
          className={classes.input}
          label="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          value={confirmPassword}
          variant="filled"
        />
        <CustomButton
          onClick={handleResetPassword}
          type="submit"
        >
          reset password
        </CustomButton>
      </form>
    </div>
  );
};

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    ResetPassword
  )
);
