import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImagePlaceholder from '../media/image-placeholder.png';

const mapStateToProps = (state) => ({
  config: state.config
});

const ProgressiveImage = memo(({
  className, mediaSource, altText, config, isVideo, videoAutoplay, videoMuted
}) => {
  const [loading, setLoading] = useState(true);
  const [source, setSource] = useState(ImagePlaceholder);

  useEffect(() => {
    const fullMediaPath = `${config.mediaPath}${mediaSource}`;

    // Start loading the original image.
    const mediaToLoad = isVideo ? document.createElement('video') : new Image();
    mediaToLoad.src = fullMediaPath;

    mediaToLoad[isVideo ? 'onloadeddata' : 'onload'] = () => {
      // When the original media is finished loading, use it to replace the placeholder image.
      setLoading(false);
      setSource(fullMediaPath);
    };
  }, [mediaSource]);

  const getImageMarkup = () => (
    <img
      alt={altText}
      className={className}
      src={source}
      style={{
        opacity: loading ? 0.5 : 1,
        transition: 'opacity .15s linear'
      }}
    />
  );

  const getVideoMarkup = () => (
    <div>
      {loading ? (
        getImageMarkup()
      ) : (
        <video
          autoPlay={videoAutoplay}
          className={className}
          controls
          muted={videoMuted}
          src={source}
        />
      )}
    </div>
  );

  return (
    <>
      {config.mediaPath && (
        isVideo ? getVideoMarkup() : getImageMarkup()
      )}
    </>
  );
});

ProgressiveImage.defaultProps = {
  altText: '',
  className: '',
  isVideo: false,
  videoAutoplay: false,
  videoMuted: false
};

ProgressiveImage.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.shape({
    mediaPath: PropTypes.string.isRequired
  }).isRequired,
  isVideo: PropTypes.bool,
  mediaSource: PropTypes.string.isRequired,
  videoAutoplay: PropTypes.bool,
  videoMuted: PropTypes.bool
};

export default
connect(mapStateToProps)(
  ProgressiveImage
);
