import { DARK, TEXT_COLOR } from './palette';

export const HEADER_HEIGHT = 60;

export const CONTENT_WRAPPER = (theme) => ({
  padding: '50px 25px 85px 25px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(14, 14, 16, 1)',
  [theme.breakpoints.down('xs')]: {
    padding: '20px 25px 55px 25px',
  }
});

export const INNER_CONTENT_WRAPPER = () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 700
});

export const CONTENT_HEADER = (theme) => ({
  fontSize: 36,
  marginBottom: 35,
  textAlign: 'center',
  color: TEXT_COLOR,
  letterSpacing: 1,
  [theme.breakpoints.down('xs')]: {
    fontSize: 30,
    marginBottom: 22
  }
});

export const CONTENT_SUB_HEADER = (theme) => ({
  fontSize: 32,
  marginBottom: 30,
  textAlign: 'center',
  color: TEXT_COLOR,
  [theme.breakpoints.down('xs')]: {
    fontSize: 26,
    marginBottom: 18
  }
});

export const CONTENT_TEXT = (theme) => ({
  fontSize: 20,
  marginBottom: 35,
  marginTop: 0,
  textAlign: 'center',
  lineHeight: 1.3,
  color: TEXT_COLOR,
  maxWidth: 900,
  letterSpacing: 1,
  [theme.breakpoints.down('xs')]: {
    fontSize: 18
  }
});

// DEPRECATED: stop using row--put margins directly on text and inputs instead.
export const CONTENT_ROW = (theme) => ({
  marginBottom: 35,
  [theme.breakpoints.down('xs')]: {
    marginBottom: 22
  }
});

export const FORM_WRAPPER = (theme) => ({
  ...CONTENT_WRAPPER(theme),
  justifyContent: 'center'
});

export const FORM = () => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

export const HOME_WRAPPER = () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const HOME_GAME_WRAPPER = () => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#c7c7c7'
});

export const HOME_HEADER = () => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0'
});

export const HOME_VIDEO = (theme) => ({
  width: 820,
  height: 461,
  margin: '25px 0',
  [theme.breakpoints.down('sm')]: {
    width: 640,
    height: 360
  },
  [theme.breakpoints.down('xs')]: {
    width: 300,
    height: 169
  }
});

export const HOME_CTA_BUTTON = () => ({
  marginBottom: 25,
  fontSize: '32px !important',
  '&:hover': {
    opacity: 0.9
  }
});

export const HOME_SUMMARY_WRAPPER = (theme) => ({
  position: 'relative',
  padding: '100px 120px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '20px'
  },
});

export const HOME_SUMMARY = (theme) => ({
  marginBottom: 50,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0
  }
});

export const HOME_SUMMARY_HEADER = (theme) => ({
  fontSize: 40,
  color: TEXT_COLOR,
  [theme.breakpoints.down('xs')]: {
    fontSize: 32
  }
});

export const HOME_SUMMARY_TEXT = (theme) => ({
  lineHeight: 1.5,
  fontSize: 22,
  fontWeight: 100,
  color: TEXT_COLOR,
  [theme.breakpoints.down('xs')]: {
    fontSize: 18
  }
});

export const FOCUSED_SECTION = () => ({
  backgroundColor: DARK,
  padding: '10px 25px',
  borderRadius: 5
});

// Use INLINE_IMAGE when you want to dispaly images in between chunks of text.
export const INLINE_IMAGE = () => ({
  maxWidth: 700,
  height: 'auto',
  marginBottom: 21
});

export const ACTION_BUTTON = () => ({
  marginTop: 15
});

// This is used in the CLIENT_SELECT_BUTTON style below, as well as in the
// ClientSelectNativeButton component, hence the export.
export const CLIENT_SELECT_OPTION_WIDTH = 350;

export const CLIENT_SELECT_BUTTON_TEXT = (theme) => ({
  fontSize: 22,
  marginRight: 8,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18
  }
});

export const MEDIA_PLATFORM_CONSUMER_TOOLBAR_HEIGHT = 90;

export const CHAT_COLLAPSED_WIDTH = 60;

export const CHAT_EXPANDED_WIDTH = 350;

export const LIGHT_BOX_SHADOW = () => ({
  boxShadow: '#cdcdcd 0px 0px 2px 0px',
});

export const BROWSER_BUTTON_ICON = () => ({
  width: 30,
  marginRight: 8
});

export const CONTENT_TEXT_WRAPPER = () => ({
  boxShadow: '#cdcdcd 0px 0px 2px 0px',
  borderRadius: 12,
  padding: '38px 30px',
  marginBottom: 35,
  backgroundColor: '#18181b',
  maxWidth: 700
});

export const CONTENT_ICON_LARGE = (theme) => ({
  fontSize: 100,
  marginBottom: 35,
  [theme.breakpoints.down('sm')]: {
    fontSize: 70
  }
});
