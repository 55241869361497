import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT
} from '../styles/global';
import { isMobileDevice, isIOS, isAndroid } from '../lib/browser';
import AppStoreButton from './AppStoreButton';
import PlayStoreButton from './PlayStoreButton';
import useGames from '../hooks/useGames';
import GlobalProgressSpinner from './GlobalProgressSpinner';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  }
});

const mapStateToProps = (state) => ({
  games: state.games
});

const Mobile = ({ classes, history, games }) => {
  const [game, setGame] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const referringGame = params.get('game');
  const osName = isIOS() ? 'iOS' : 'Android';

  useGames();

  useEffect(() => {
    if (!isMobileDevice()) {
      history.push('/games');
    }
  }, [history]);

  useEffect(() => {
    if (games.length) {
      const currentGame = games.find((g) => g.slug === referringGame);
      setGame(currentGame);
    }
  }, [games]);

  if (referringGame && !game) {
    return (<GlobalProgressSpinner />);
  }

  return (
    <div className={classes.wrapper}>
      {referringGame ? (
        <p>
          <p className={classes.header}>Want to play on mobile?</p>
          <p className={classes.text}>
            {game.name} isn&apos;t available on mobile yet,
            but you can check out LGN Poker on {osName}.
          </p>
        </p>
      ) : (
        <p className={classes.header}>Want to play on mobile?</p>
      )}
      {isIOS() && (
        <AppStoreButton />
      )}
      {isAndroid() && (
        <PlayStoreButton />
      )}
    </div>
  );
};

Mobile.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  withRouter(
    connect(mapStateToProps)(
      Mobile
    )
  )
);
