import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CustomImage from '../containers/CustomImage';

const WindowsButton = ({ className, imageClassName }) => (
  <Link
    className={className}
    target="_blank"
    to="/download"
  >
    <CustomImage
      alt="windows download button"
      className={imageClassName}
      src="/windows-button.png"
    />
  </Link>
);

WindowsButton.defaultProps = {
  className: '',
  imageClassName: ''
};

WindowsButton.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string
};

export default WindowsButton;
