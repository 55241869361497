import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getErrorMessage, getSuccessMessage } from '../lib/string';

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message))
});

const MediaPlatformCopyInviteButton = ({
  className, classes, disabled, showGlobalSnackbar, children, ...props
}) => {
  const location = useLocation();

  const handleCopyInviteUrlClick = () => {
    const params = new URLSearchParams(location.search);
    const role = params.get('role');
    let inviteLocation = window.location.toString();

    if (role === 'host') {
      inviteLocation = inviteLocation.replace('role=host', 'role=participant');
    }

    navigator.clipboard.writeText(inviteLocation)
      .then(() => showGlobalSnackbar(getSuccessMessage('The invite link has been copied to your clipboard.')))
      .catch(() => showGlobalSnackbar(getErrorMessage('Unable to copy the invite URL to your clipboard')));
  };

  return (
    <CustomButton
      className={className}
      disabled={disabled}
      onClick={handleCopyInviteUrlClick}
      size="small"
      {...props}
    >
      copy invite link
    </CustomButton>
  );
};

MediaPlatformCopyInviteButton.defaultProps = {
  children: null,
  className: '',
  disabled: false
};

MediaPlatformCopyInviteButton.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
connect(mapStateToProps, mapDispatchToProps)(
  MediaPlatformCopyInviteButton
);
