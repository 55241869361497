import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_SUB_HEADER, CONTENT_TEXT
} from '../styles/global';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  subHeader: {
    ...CONTENT_SUB_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  waitTimeText: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  supportLink: {
    marginTop: 36
  }
});

const Support = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>Live Game Night Support</p>
    <p className={classes.subHeader}>Support Hours</p>
    <p className={classes.text}>Monday - Friday, 9:00am - 5:00pm PST (except holidays)</p>
    <p className={classNames(classes.text, classes.waitTimeText)}>
      Maximum wait time for a response is three to five days.
    </p>
    <p className={classes.header}>Contact</p>
    <p className={classes.text}>
      <a href="mailto:support@livegamenight.com">
        support@livegamenight.com
      </a>
    </p>
    <p className={classNames(classes.text, classes.supportLink)}>
      For further assistance, please&nbsp;
      <a
        href="https://livegamenight.zendesk.com/hc/en-us/requests/new"
        rel="noopener noreferrer"
        target="_blank"
      >
        open a support ticket
      </a>.
    </p>
  </div>
);

Support.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Support
);
