// ******** WHAT IS THIS? ********
//
// This file contains the data for the LGN Portal FAQ. It is stored in JavaScript
// rather than JSON because JSON doesn't allow comments, and it's best that this
// is documented for future users.
//
// ******** DATA SCHEMA ********
//
// Each entry is an object, with the following properties:
//
//   question: The plain text or HTML to display as the question text.
//   answer: The plain text or HTML to display as the answer text.
//           (wrap each paragraph in a <p> tag for best results)
//   images: An array of strings, each representing the path to an image
//           that will be displayed in the answer text.
//   category: A string that represents the category for the question.
//
// ******** DISPLAYING IMAGES IN ANSWER TEXT ********
//
// Use the following format to designate areas in the answer text that should
// have an image injected:
//
//   %image0%
//
// The number following the 'image' text should be 0 based, and correspond to the index
// in the images array that holds the path for the image you'd like to inject.
//
// For example, the following FAQ object will result in the first image in the images
// array being injected in place of %image0% and the second image in the images array
// being injected in place of %image1%.
//
// {
//   question: 'Is this question annoying?',
//   answer: 'If you look like %image0%, then yes, if you look like %image1%, then no.',
//   images: ['/hosted/path/to/annoying/face', '/hosted/path/to/friendly/face']
// }
//
export default [
  {
    question: 'I have some ideas to improve the game, additional questions, or comments. Where can I send them?',
    answer: '<p>Great! We love to hear from our players. You can send any suggestions to our support alias <a href="mailto:support@livegamenight.com">support@livegamenight.com</a>.</p>',
    images: [],
    category: 'general',
  },
  {
    question: 'Can I use this to host a large poker tournament?',
    answer: '<p>LGN Poker supports tournaments with up to 30 players (3 tables with 10 players per table). If you have more than 30 players, you would need to run multiple smaller tournaments and feed the winners into a playoff tournament.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'What\'s the maximum number of players per table?',
    answer: '<p>We currently support up to 10 players per table, including the host.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'The invite link is not working. What do I do?',
    answer: '<p>If you cannot access a game with the invite link, you can try join using the room code.</p><p>If you are a host, send the room code directly to your friends. If you\'re not the host, request the room code from the host.</p><p>After logging in to the game, enter the room code and click "Join Game".</p><p><strong>Please note: The host needs to be in the room before guests can join.</strong></p>%image0%',
    images: ['/images/faq/join.png'],
    category: 'poker'
  },
  {
    question: 'What is Live Game Night?',
    answer: '<p>Live Game Night is the go-to website and mobile game for live games you can play with your friends online and on video. Our first game, LGN Poker, allows you to play Texas Hold’em Poker. We’ll be releasing more new, fun games in the future.</p>',
    images: [],
    category: 'general'
  },
  {
    question: 'I blocked a player in a specific game. Why am I still seeing that player in other games?',
    answer: '<p>Player blocking works on a game level, so blocking a player in one game does not mean that the player you blocked will be automatically blocked in all games.</p>',
    images: [],
    category: 'general'
  },
  {
    question: 'My browser has permission to use my video camera, but LGN tells me my camera isn\'t enabled. What\'s going on?',
    answer: '<p>Users on Windows can have problems with video in LGN when they have other applications such as Zoom or Slack actively using their camera during gameplay. To solve this issue, close any application other than LGN that\'s using your camera.</p>',
    images: [],
    category: 'general'
  },
  {
    question: 'What is LGN Poker?',
    answer: '<p>LGN Poker is an online Texas Hold’em poker game designed to be played with friends. Think of it as an online version of a poker night in your home with your buddies. The great thing about LGN Poker versus other online poker games is that you can see and hear your friends live. Everyone in the game appears via a video feed. This allows you to chat, joke, tease, commiserate, and even read tells!</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'How do I play Texas Hold\'em Poker?',
    answer: '%image0%',
    images: ['/images/faq/holdem_rules.png'],
    category: 'poker'
  },
  {
    question: 'Where can I play LGN Poker?',
    answer: '<p>You can paly LGN Poker on the web at the <a href="https://play.livegamenight.com/games/poker">Live Game Night website</a>. To play on your mobile device, get the LGN Poker app from the App Store or Google Play Store.</p><p>You can even play using Zoom by intalling our Zoom app from the <a href="https://marketplace.zoom.us/apps/QRmdBUnwSpuQkFnEnUfq5g">Zoom App Marketplace.</a></p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'Is LGN Poker a gambling game?',
    answer: '<p>LGN Poker does not offer real money gambling or an opportunity to win real money or prizes. You start a game with a buy-in -- an amount of chips you’d like to use in the game. These chips cannot be exchanged for real money. You use these chips to place ‘bets’ during gameplay. The player with the most chips at the end of the game wins.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'How do I host a room in LGN Poker?',
    answer: '<p>When you first log into the game you will see the Main Screen. To host a new game, click the "Start New Game" button in the bottom left corner of this screen. Alternatively, if you already have a game session active, you can rejoin that session by clicking on the "Host Game" button in the active games list in the middle of the screen.</p>%image0%<p>When you click "Start New Game" you will be shown a screen that allows you to configure and pay for your game. You can choose whether you want to host a 6 or 10 player table, and whether you want to use your Home Game Code (a 4 character code that is always tied to your account) or a brand-new randomly generated code.</p><p>This screen also allows you to copy an invite link that you can share with your friends to get into the game (you can also get a copy of the link from within the game after it\'s started).</p>%image1%<p>You pay for games using [Room Cards](#what-are-room-cards). A 6 player table costs 1 Room Card and a 10 player table costs 2 Room Cards. Room Cards may be purchased in the <a href="what-is-the-store">Store</a> using <a href="what-are-gems">Gems</a>. Gems may also be purchased in the Store using a credit card or your mobile payment provider.</p>',
    images: ['/images/faq/host.png', '/images/faq/configure_game.png'],
    category: 'poker'
  },
  {
    question: 'What is the Store?',
    answer: '<p>The store is the place in LGN Poker where you can purchase Gems, Room Cards, and Party Favors. You can access it by clicking on the "Store" button in the upper right corner of the game.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'What are Gems?',
    answer: '<p>Gems are the primary currency in LGN Poker. Once you have Gems you can buy anything else! Gems packs come in different sizes. The larger the pack, the better the deal. Load up on Gems then buy Room Cards and Party Favors for yourself and your friends!%image0%</p>',
    images: ['/images/faq/gem_store.png'],
    category: 'poker'
  },
  {
    question: 'What are Room Cards?',
    answer: '<p>Room Cards are used to create a room and host a game. You only need to buy them if you plan on hosting a game for others. Like Gems, Room Cards are discounted when purchased in larger quantities. Six player tables cost one room card and ten player tables cost two.</p>%image0%',
    images: ['/images/faq/room_card_store.png'],
    category: 'poker'
  },
  {
    question: 'What are Party Favors?',
    answer: '<p>Party Favors are gifts you can buy for yourself and your friends! When you "equip" a Party Favor and it will show up next to your video and pay out Party Points with every bet. Party Favors only pay out a certain number of Party Points and once they\'ve paid out they will be automatically removed. What are Party Points? We\'re not saying yet, but we think you\'ll like them!</p>%image0%',
    images: ['/images/faq/party_favor_store.png'],
    category: 'poker'
  },
  {
    question: 'How do I invite friends to play LGN Poker?',
    answer: '<p>You can invite friends to play by hosting a room and sending them an invite link. To get the invite link, click the ‘Copy Link’ button in the room setup screen or in-game. A link to the game will be copied to the clipboard. Send this link to your friends via email or online chat. Alternatively, you can tell your friends the game code for your room and they can sign in using the <a href="the-invite-link-is-not-working-what-do-i-do">Join Room</a> panel.</p>%image0%<p>There’s also a button in the game menu item that allows you to get the invite link.</p>%image1%<p>And the game code appears at the top of the screen.</p>%image2%',
    images: ['/images/faq/configure_game.png', '/images/faq/game_menu.png', '/images/faq/top_bar.png'],
    category: 'poker'
  },
  {
    question: 'Someone sent me an invitation to play LGN Poker. What do I do?',
    answer: '<p>There are two ways to invite someone to play LGN Poker, either via a link or by sending the game code. If you received a link, simply click it and you will be taken to a page where you can play the game if you don’t already have it. Register for the game by providing a name, email address and password and you will be taken to the game room that your host set up.</p>%image0%<p>If you received only a game code, go to the LGN Poker website to play the game. Register for the game by providing a name, email address and password. Enter the room code and click the "Join" button. Room names are not case-sensitive.</p>%image1%',
    images: ['/images/faq/register.png', '/images/faq/join.png'],
    category: 'poker'
  },
  {
    question: 'How do I turn the volume up or down?',
    answer: '<p>On desktop and web you can adjust the game volume in the settings dialog. Select "Settings" from the main menu on the left.</p>%image0%%image1%',
    images: ['/images/faq/game_menu.png', '/images/faq/settings.png'],
    category: 'poker'
  },
  {
    question: 'I hear a bad echo when playing. How can I fix this?',
    answer: '<p>LGN Poker employs echo cancellation technology to help reduce echos, however, there are certain times when echos can occur.</p><p>If you are encountering echos, you can try to have everyone playing use headphones.</p><p>You can also try reducing the graphics complexity of the game as this can sometimes impact the game\'s ability to perform echo cancellation. You can adjust the graphics complexity in the settings dialog.</p>%image0%',
    images: ['/images/faq/settings.png'],
    category: 'poker'
  },
  {
    question: 'This game makes my computer really hot and my fan run. What can I do?',
    answer: '<p>Open the settings dialog and adjust the graphics complexity of the game, which should help your computer run cooler.</p>%image0%',
    images: ['/images/faq/settings.png'],
    category: 'poker'
  },
  {
    question: 'How do I switch to a different camera or speakers on my computer in LGN Poker?',
    answer: '<p>If your computer has more than one camera or audio output mechanism, you can switch to the one you want in the settings dialog. Access the settings from the main menu on the left.</p>%image0%',
    images: ['/images/faq/settings.png'],
    category: 'poker'
  },
  {
    question: 'What are the system requirements to play LGN Poker?',
    answer: '<p>To play the downloadable version of LGN Poker you need a Windows computer with a webcam, microphone, and speakers. Headphones can be used, but are not required.</p><p>LGN Poker is also available to play directly in any modern web browser, or on Apple or Android mobile devices.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'What types of payment do you accept?',
    answer: '<p>We currently only accept credit cards and mobile payments.</p>',
    images: [],
    category: 'general'
  },
  {
    question: 'Does any playing time that I purchased carry over to other days?',
    answer: '<p>No, the room fee pays for eight continuous hours of session time beginning as soon as the payment is processed.</p>',
    images: [],
    category: 'poker'
  },
  {
    question: 'When I click on LGN Poker for Zoom, it starts to load and then quits. How can I fix this?',
    answer: 'Some Windows computers need an additional component from Microsoft called Webview2 in order to play LGN Poker for Zoom. You can download it <a target="_blank" href="https://developer.microsoft.com/en-us/microsoft-edge/webview2/#download-section">here</a>. Once downloaded, click to install. You will need to restart Zoom when the installation is complete.',
    images: [],
    category: 'zoom'
  },
  {
    question: 'I installed LGN Poker for Zoom, but I don’t see it in my list of Zoom Apps. Where is it?',
    answer: 'Some players have multiple Zoom accounts and this can cause confusion. Ensure that you are logged in to your Zoom client with the same account that you are using with the <a href="https://links.livegamenight.com/a/2237/click/87/2/fa988c319f8feb778dfae0a270c949305eecac5d/775df1159a931d34c8cca8cd8359e4242f8ce52d">Zoom App Marketplace</a>.',
    images: [],
    category: 'zoom'
  },
  {
    question: 'I\'m unable to access LGN game content on Zoom. What\'s the problem?',
    answer: 'In some cases, especially if you\'re on an Internet connection managed by a business, LGN content might be blocked by your network\'s firewall. Please contact your network administrator and ask them to whitelist livegamenight.com.',
    images: [],
    category: 'zoom'
  },
  {
    question: 'What if I need to take a break in the middle of the game?',
    answer: 'Leaving LGN Dice during gameplay will remove you from the game, and you won’t be able to rejoin until a new game begins.',
    images: [],
    category: 'dice'
  },
  {
    question: 'I forgot how to play. What dice rolls can I use to earn points?',
    answer: 'LGN Dice rules are provided directly in-game, including information about how many points you can earn based on the dice you roll each round. Click on the menu button at the top of the game to open the rules window.',
    images: [],
    category: 'dice'
  },
  {
    question: 'What is the “Upper Bonus”?',
    answer: 'The upper bonus is an additional 50 bonus points you can earn by getting a combined score of 63 or higher by adding the total of the values you earn in the ones, twos, threes, fours, fives, and sixes scoring categories, located in the “upper” section of the scoring table.',
    images: [],
    category: 'dice'
  },
  {
    question: 'Why does my score table keep changing?',
    answer: 'The score table changes to reflect the score of the player currently rolling the dice. You can switch the scoring table view to see any player’s score at any time by clicking the tab above the scoring table with the name of the player whose score you would like to see.',
    images: [],
    category: 'dice'
  },
  {
    question: 'Can I change the number of rounds per game?',
    answer: 'No, LGN Dice games always have 13 rounds.',
    images: [],
    category: 'dice'
  },
  {
    question: 'The game dice graphics appear to have frozen in the middle of a roll. Is the game still working?',
    answer: 'Browsers sometimes reduce the amount of resources allocated to LGN Dice when the game tab is not focused in the browser. If you find that the dice have frozen mid-roll after returning to the browser tab that contains LGN Dice, continue playing as usual, and the dice will return to normal.',
    images: [],
    category: 'dice'
  },
  // {
  //   question: 'What are teams? Does everyone have to join a team?',
  //   answer: 'Teams are optional, and any player can choose to join a team or play solo during any game round. It is possible to have a combination of teams and solo players in the same game. Users who participate on a team will have their scores combined at the end of the game.',
  //   images: [],
  //   category: 'boggle'
  // },
  // {
  //   question: 'How can I turn off the music and/or sound effects?',
  //   answer: 'Clicking the menu button at the top of the game will allow you to adjust sound effect and music volume, or turn either off completely.',
  //   images: [],
  //   category: 'boggle'
  // },
  // {
  //   question: 'Why can’t I change the time limit, minimum word length, or board size before a game?',
  //   answer: 'All players can make changes in the “player settings” panel before a game, but only the game host has the ability to make changes to the game’s overall configuration.',
  //   images: [],
  //   category: 'boggle'
  // },
  // {
  //   question: 'Why can I see words next to the game board that I didn’t find?',
  //   answer: 'The word results panel next to the game displays all words found by all players during a game round. Players who find words first earn more points for that word than players who find it later on.',
  //   images: [],
  //   category: 'boggle'
  // },
  // {
  //   question: 'Some tiles have “DL,” “DW”, “TL,” or “TW” written on them. What does that mean?',
  //   answer: 'These values stand for “double letter,” “double word,” “triple letter,” and “triple word,” respectively. Including these tiles in your word choices lead to higher scores for a given word. These tiles can be turned on or off by the host before any game.',
  //   images: [],
  //   category: 'boggle'
  // },
  {
    question: 'How do I draw?',
    answer: 'You can use any input device, including your mouse to draw pictures in LGN Illustrious.',
    images: [],
    category: 'illustrious'
  },
  {
    question: 'Why does a negative number sometimes appear while a player is drawing?',
    answer: 'LGN Illustrious answer values decrease as time progresses. The sooner a player correctly guesses a drawing, the higher the score!',
    images: [],
    category: 'illustrious'
  },
  {
    question: 'How do I start from scratch while in the middle of a drawing?',
    answer: 'Use the clear button beneath the drawing area to start from scratch in the middle of a drawing round.',
    images: [],
    category: 'illustrious'
  },
  {
    question: 'How is the player who guessed a drawing correctly awarded points?',
    answer: '<p>The player drawing should click the “Got It!” button as soon as possible once a player guesses correctly so that the winning player is awarded the full number of points available at the time of guessing correctly.</p><p>Once the round is ended, the player drawing will be able to select the player who guessed correctly.</p>',
    images: [],
    category: 'illustrious'
  }
];
