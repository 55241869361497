import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, FormControl, InputLabel,
  Select, MenuItem, FilledInput
} from '@material-ui/core';
import { VIDEO_INPUT_DEVICE, AUDIO_OUTPUT_DEVICE, AUDIO_INPUT_DEVICE } from '../enums/local-storage';

const styles = () => ({
  input: {
    width: 300,
    marginBottom: 8
  }
});

const getMediaTypeText = (type) => {
  switch (type) {
    case VIDEO_INPUT_DEVICE:
      return 'Video Input';
    case AUDIO_INPUT_DEVICE:
      return 'Audio Input';
    case AUDIO_OUTPUT_DEVICE:
      return 'Audio Output';
    default:
      return '';
  }
};

const MediaDeviceSelect = ({
  classes, className, selectedDeviceId, onChange, type, devices
}) => (
  <>
    {devices && devices.length > 0 && (
      <FormControl
        className={className}
        variant="filled"
      >
        <InputLabel id={`${type}-select-label`}>{getMediaTypeText(type)}</InputLabel>
        <Select
          className={classes.input}
          id={`${type}-select`}
          input={
            <FilledInput />
          }
          inputProps={{
            className: classes.input
          }}
          labelId={`${type}-select-label`}
          onChange={(event) => onChange(event.target.value)}
          value={selectedDeviceId}
          variant="filled"
        >
          {devices.map((device) => (
            <MenuItem
              key={device.deviceId}
              value={device.deviceId}
            >
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </>
);

MediaDeviceSelect.defaultProps = {
  className: ''
};

MediaDeviceSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  devices: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedDeviceId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  MediaDeviceSelect
);
