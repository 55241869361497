import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Computer as ComputerIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import CustomButton from './CustomButton';
import { CLIENT_SELECT_OPTION_WIDTH } from '../styles/global';
import { isMacOS } from '../lib/browser';

const styles = (theme) => ({
  icon: {
    marginLeft: 5
  },
  note: {
    fontSize: 16,
    textAlign: 'center',
    width: CLIENT_SELECT_OPTION_WIDTH,
    marginTop: 25,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      width: CLIENT_SELECT_OPTION_WIDTH - 50,
      marginLeft: 0,
      marginTop: 25
    }
  },
  iframe: {
    display: 'none'
  }
});

const ClientSelectNativeButton = ({ classes, className }) => {
  const [launchNative, setLaunchNative] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const gameID = searchParams.get('id');
  let queryParams = '';

  if (gameID) {
    queryParams = `?id=${gameID}`;
  }

  return (
    <div className={className}>
      <CustomButton
        className={classes.button}
        disabled={isMacOS()}
        onClick={() => setLaunchNative(true)}
      >
        <span>Play on Computer</span>
        <ComputerIcon className={classes.icon} />
      </CustomButton>
      <div className={classes.note}>
        {isMacOS() ? (
          <>
            The MacOS native client is no longer available.&nbsp;
            <Link to="/mac-deprecation">
              Learn more.
            </Link>
          </>
        ) : (
          <>
            You must have the game&nbsp;
            <Link to="/download">
              downloaded and installed&nbsp;
            </Link>
            on your computer.
          </>
        )}
      </div>
      {launchNative && (
        <iframe
          className={classes.iframe}
          src={`lgn://livepokernight/${queryParams}`}
          title="Lauch Native Game Client"
        />
      )}
    </div>
  );
};

ClientSelectNativeButton.defaultProps = {
  className: ''
};

ClientSelectNativeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default
withStyles(styles)(
  ClientSelectNativeButton
);
