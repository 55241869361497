import { GetHostComplete } from '../enums/action-type';

export default (state = '', action) => {
  switch (action.type) {
    case GetHostComplete: {
      return action.host;
    }
    default:
      return state;
  }
};
