import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Icon } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT, CONTENT_ICON_LARGE
} from '../styles/global';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  icon: {
    ...CONTENT_ICON_LARGE(theme)
  }
});

const ForgotPasswordConfirm = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>Password Reset Email Sent</p>
    <Icon className={classes.icon}>
      <CheckCircleIcon className={classes.icon} />
    </Icon>
    <p className={classes.text}>
      A password reset link has been sent to your email address.
      Please open the email and click the &quot;Change Password&quot; button to
      reset your passwrod.
    </p>
  </div>
);

ForgotPasswordConfirm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ForgotPasswordConfirm
);
