import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getErrorMessage, getSuccessMessage } from '../lib/string';

const styles = () => ({
  codeButtonLabel: {
    color: '#d9d9d9',
    opacity: 0.6
  },
  code: {
    marginLeft: 8,
    fontWeight: 'bold',
    fontSize: 18,
    color: 'white'
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message))
});

const MediaPlatformGameCodeButton = ({
  classes, className, gameCode, showGlobalSnackbar
}) => {
  const handleClick = () => {
    navigator.clipboard.writeText(gameCode)
      .then(() => showGlobalSnackbar(getSuccessMessage(`The game code ${gameCode} has been copied to your clipboard.`)))
      .catch(() => showGlobalSnackbar(getErrorMessage('Unable to copy the game code to your clipboard')));
  };

  return (
    <CustomButton
      className={className}
      onClick={handleClick}
      size="small"
    >
      <span className={classes.codeButtonLabel}>
        game code
      </span>
      <span className={classes.code}>
        {gameCode}
      </span>
    </CustomButton>
  );
};

MediaPlatformGameCodeButton.defaultProps = {
  className: ''
};

MediaPlatformGameCodeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  gameCode: PropTypes.string.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    MediaPlatformGameCodeButton
  )
);
