import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGames } from '../actions/game';

const useGames = () => {
  const games = useSelector((state) => state.games);
  const dispatch = useDispatch();

  useEffect(() => {
    if (games.length === 0) {
      dispatch(getGames());
    }
  }, []);
};

export default useGames;
