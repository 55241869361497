import RouteEnum from '../enums/route';

export const isLocalhost = () => (window.location.host.includes('localhost'));

export const isQatest = () => (window.location.host === 'qatest-play.livegamenight.com');

export const isPlaytest = () => (window.location.host === 'playtest-play.livegamenight.com');

export const isZoom = () => {
  const params = new URLSearchParams(window.location.search);
  const zoomParam = params.get('zoom');

  if (zoomParam) {
    return (Boolean(zoomParam));
  }

  return false;
};

export const isFutureFlagEnabled = () => (isLocalhost() || isQatest());

export const getCdnPath = () => {
  if (isLocalhost() || isQatest()) {
    return 'https://cdne-qatest-ssl.kitsuneapi.com';
  }

  if (isPlaytest()) {
    return 'https://cdne-playtest-ssl.kitsuneapi.com';
  }

  return 'https://cdn-ssl.kitsuneapi.com';
};

export const isFutureFlaggedGame = (game) => (
  game.status.toLowerCase() === 'future'
);

export const getClientVersion = () => {
  const { hostname } = window.location;
  const hostnameSegments = hostname.split('.');

  if (hostnameSegments.length) {
    return hostnameSegments[0];
  }

  return '';
};

/**
 * Get the hostname of the local Node server. This is only useful when running
 * the UI from a local dev server rather than serving it via the Node server.
 * @returns {string} - the hostname of the local Node server.
 */
export const getLocalServerHostname = () => {
  const { hostname, origin } = window.location;
  return hostname === 'localhost' ? 'http://localhost:83' : origin;
};

/**
 * Determine whether a UI should trigger a user login.
 * @returns {boolean}
 */
export const shouldSkipLogin = () => {
  const { pathname } = window.location;

  return (
    pathname === RouteEnum.FAQ
    || pathname === RouteEnum.PRIVACY_POLICY
    || pathname === RouteEnum.TERMS_OF_USE
    || pathname === RouteEnum.SUPPORT
    || pathname === RouteEnum.DOWNLOAD
    || pathname === RouteEnum.MAC_DEPRECATION
    || pathname === RouteEnum.MEDIA_PERMISSIONS_HELP
  );
};
