import UAParser from 'ua-parser-js';
import { ALLOW_COOKIES } from '../enums/local-storage';

const parser = new UAParser(navigator.userAgent);
const operatingSystem = parser.getOS().name;
const device = parser.getDevice().model;

export const isMacOS = () => (operatingSystem === 'Mac OS');

export const isWindows = () => (operatingSystem === 'Windows');

export const isIPad = () => (
  device === 'iPad'
  || /iPad/.test(navigator.platform)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
);

export const isIOS = () => (operatingSystem === 'iOS' || isIPad());

export const isAndroid = () => (operatingSystem === 'Android');

export const isWebGL2Supported = () => (!!document.createElement('canvas').getContext('webgl2'));

export const isMobileDevice = () => (isIOS() || isAndroid());

export const grantCookiePermission = () => {
  if (window.gtag) {
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted'
    });

    window.localStorage.setItem(ALLOW_COOKIES, true);
  }
};

export const denyCookiePermission = () => {
  window.localStorage.setItem(ALLOW_COOKIES, false);
};

export const getCurrentBrowser = () => (parser.getBrowser());

export const getCurrentBrowserName = () => (getCurrentBrowser().name);
