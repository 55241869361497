import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT } from '../styles/global';
import { getCurrentBrowserName } from '../lib/browser';
import BrowserButton from './BrowserButton';
import Browser from '../enums/browser';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  }
});

const getLinkPath = (browser) => {
  switch (browser) {
    case Browser.EDGE:
      return 'https://www.picmonkey.com/help/errors-and-troubleshooting/crashes-and-performance/how-to-enable-webgl#:~:text=Microsoft%20Edge-,Enable%20WebGL%20status,so%20the%20change%20takes%20effect.';
    case Browser.FIREFOX:
      return 'https://www.picmonkey.com/help/errors-and-troubleshooting/crashes-and-performance/how-to-enable-webgl#:~:text=Firefox-,Enable%20WebGL%20status,so%20the%20change%20takes%20effect.';
    case Browser.CHROME:
      return 'https://www.picmonkey.com/help/errors-and-troubleshooting/crashes-and-performance/how-to-enable-webgl#:~:text=Chrome-,Enable%20WebGL%20status,so%20the%20change%20takes%20effect.';
    default:
      return '';
  }
};

const UnsupportedWebGL = ({ classes }) => {
  const browserName = getCurrentBrowserName();
  const history = useHistory();

  if (browserName === Browser.SAFARI) {
    history.push('/unsupported');
  }

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>WebGL Support Disabled</p>
      <p className={classes.text}>Please enable WebGL in your browser to continue playing.</p>
      <p className={classes.text}>{`Click the button below for more information on enabling WebGL for ${browserName}`}.</p>
      <BrowserButton
        browser={browserName}
        linkPath={getLinkPath(browserName)}
        text="Learn More"
      />
    </div>
  );
};

UnsupportedWebGL.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  UnsupportedWebGL
);
