import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { Language as LanguageIcon } from '@material-ui/icons';
import { CLIENT_SELECT_OPTION_WIDTH } from '../styles/global';
import CustomButton from './CustomButton';

const styles = () => ({
  button: {
    width: CLIENT_SELECT_OPTION_WIDTH
  },
  icon: {
    marginLeft: 5
  }
});

const ClientSelectBrowserButton = ({
  classes, className, onClick, ...props
}) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const gameID = searchParams.get('id');
  let queryParams = '';

  if (gameID) {
    queryParams = `?id=${gameID}`;
  }

  return (
    <CustomButton
      className={classNames(classes.button, className)}
      onClick={() => history.push(`/games/poker${queryParams}`)}
      {...props}
    >
      <span>Play in Browser</span>
      <LanguageIcon className={classes.icon} />
    </CustomButton>
  );
};

ClientSelectBrowserButton.defaultProps = {
  className: ''
};

ClientSelectBrowserButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  ClientSelectBrowserButton
);
