import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from '../styles/global';
import { isZoom, shouldSkipLogin } from '../lib/env';
import PrimaryNavigation from './PrimaryNavigation';
import SecondaryNavigation from './SecondaryNavigation';
import FeaturedNavigation from '../containers/FeaturedNavigation';
import CustomImage from '../containers/CustomImage';
import SocialLinks from './SocialLinks';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    boxSizing: 'border-box',
    padding: '0 30px 0 15px',
    backgroundColor: '#18181b'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  logo: {
    width: 100,
    marginRight: 30
  },
  featuredNavigation: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
});

const getHeaderImage = (classes) => {
  const image = (
    <CustomImage
      alt="live game night logo"
      className={classes.logo}
      src="/logo.png"
    />
  );

  if (isZoom() || shouldSkipLogin()) {
    return image;
  }

  return (
    <Link
      className={classes.headerColumn}
      to="/games"
    >
      {image}
    </Link>
  );
};

const Header = ({ classes }) => (
  <header className={classes.wrapper}>
    <div className={classes.left}>
      {getHeaderImage(classes)}
      {!shouldSkipLogin() && (
        <>
          <PrimaryNavigation />
          <SocialLinks />
        </>
      )}
    </div>
    {!shouldSkipLogin() && (
      <FeaturedNavigation className={classes.featuredNavigation} />
    )}
  </header>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Header
);
