import { ShowGlobalSnackbar, HideGlobalSnackbar } from '../enums/action-type';

const initialState = {
  open: false,
  message: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ShowGlobalSnackbar: {
      const { message } = action;

      return {
        open: true,
        message
      };
    }
    case HideGlobalSnackbar: {
      return initialState;
    }
    default:
      return state;
  }
}
