import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CONTENT_WRAPPER } from '../styles/global';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    color: '#cdcdcd',
    lineHeight: 1.4
  },
  innerWrapper: {
    maxWidth: 768
  }
});

const PrivacyPolicy = ({ classes }) => (
  <div className={classes.wrapper}>
    <div className={classes.innerWrapper}>
      {/* eslint-disable */}
      <h1>Live Game Night Privacy Policy</h1>

      <p>The Live Game Night website (www.livegamenight.com, the &ldquo;Site&rdquo;) and the Live Game Night game or app (the &ldquo;Game&rdquo;) are owned and operated by FlowPlay, Inc. (&ldquo;FlowPlay&rdquo;, &ldquo;we,&rdquo; &ldquo;us&rdquo;). We are committed to providing a fun, entertaining, and safe experience. This Privacy Policy outlines how we handle personal information we collect from you when you visit the Site, play the Game, or use any products or services available from the other sites we operate (the Site, the Game, and our products and services are collectively referred to as &ldquo;LGN&rdquo;). It also applies to any personal information we collect by other means, for example, if you email us or provide us information over the phone.</p>

      <h2>Purpose</h2>

      <p>This policy (&ldquo;Privacy Policy&rdquo;) describes, among other things:</p>

      <ul>
        <li>The personal information we collect about you</li>
        <li>How we use the personal information we collect</li>
        <li>The types of third parties with whom we share personal information, and how we interact with linked sites</li>
        <li>Your right to review and correct personal information we have collected about you</li>
        <li>What we do to protect personal information we have collected</li>
        <li>Your right to opt-out of commercial email messages</li>
        <li>Terms applicable to residents of countries or states with local privacy laws, such as California, the European Union, and Switzerland, including your right to object to the collection of personal information</li>
        <li>Our right to change the terms of this Privacy Policy</li>
        <li>Together with our Terms and Conditions of Websites Use (&ldquo;Terms&rdquo;), this Privacy Policy is a legally binding agreement between you and us. Please read this Privacy Policy and the Terms carefully. By accessing LGN, including but not limited to visiting the Site, playing the Game, or registering a user account, you are agreeing to be bound by this Privacy Policy and the Terms, and you represent and warrant the following:</li>
        <ul>
          <li>you are at least 18 years old</li>
          <li>you have reached the age of legal majority in the country, state, province or locality (&ldquo;Jurisdiction&rdquo;) in which you reside; and</li>
          <li>you are permitted under the applicable rules, regulations, statutes, agency, court decisions, and other acts of government (&ldquo;Laws&rdquo;) to access and use LGN.</li>
          <li>We may, from time to time, unilaterally modify this Privacy Policy or the Terms without notice to you, so it is important that you review this Privacy Policy and the Terms every time you access or use LGN. If you do not agree to this Privacy Policy or the Terms, you may not access or use LGN, including registering a user account</li>
        </ul>
      </ul>

      <h2>Why do we collect personal information?</h2>

      <p>We collect and process personal information to provide and improve our products and services, to comply with our own legal obligations, and for other purposes, such as sending you information about our new games or features and other products and services that may be of interest to you. If you do want to receive communications from us, you can opt-out, as outlined below (though you may still receive communications regarding the status of your LGN account). If you want to review, correct, or remove personal information we have collected, you can do so by emailing <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>.</p>

      <h2>What personal information do we collect?</h2>

      <p>We collect personal information that you provide directly, for example when registering an account, or indirectly, when you play our games, if you call us, send us email, or otherwise access or use LGN.</p>

      <h3>Information You Provide to Us</h3>
      <p>We collect and store personal information you provide to us, except that we will not store payment card numbers and other financial transaction data, except as necessary to process your payment. For example, to create a user account (&ldquo;Account&rdquo;) we ask you to provide an email address and username. We may also ask you to provide other personal information in connection with other features of LGN, for example, if you make a purchase. It is your choice to provide us with this information. If you decide not to provide certain information, you may not be able to use some features of LGN.</p>
      
      <h3>Account Information</h3>
      <p>To play the Game, you must create a user account (&ldquo;Account&rdquo;). You will be asked to select a user name and a profile image, which we will store for your future game play. The user name will be visible to all other users (including guest users) and to us. We strongly encourage you not to include any personal information in your user name. Other information you provide for your Account, such as images captured in-game, virtual items you have purchased, and other and other information will also be visible to other users unless you select otherwise in your Account&rsquo;s User Settings.</p>
      
      <h3>Information You Provide to Other Users</h3>
      <p>You may choose to disclose information about yourself during game play or in other multi-user communications available on LGN. We strongly encourage you not to disclose personal information about yourself in these communications.</p>
      
      <h3>Automated Collection</h3>
      <p>We automatically receive and store certain types of personal information when you use or visit LGN, such as the name of your internet service provider, your IP address, the browser and operating system you are using, the date and time you access LGN, search terms you used to find the LGN, and your navigation of the Site. When you play our games, we record your gameplay statistics and add this information to your profile, which can be viewed by other players.</p>
      
      <h3>Purchases</h3>
      <p>If you choose to make a purchase from LGN, we will require your payment information, including your name, credit card number, expiration date, billing address, and authentication codes or related information as needed to authorize the charge and complete the transaction. When processing payments through the internet, your payment information is encrypted. After your information reaches us, it is stored on a secure server that resides behind firewalls designed to block unauthorized access from outside FlowPlay. If you make a purchase through a third-party payment processor, such as PayPal, they have their own privacy policy and terms, which will apply to you. Please be sure to review them when providing us payment information.</p>
      
      <h3>Cookies</h3>
      <p>When you visit or use LGN, we automatically receive and store certain types of information through the use of cookies to keep track of your preferences and activities. We use analytics and customization cookies for authentication, to remember your settings, to keep track of your activity during a visit to LGN, to improve the LGN&rsquo;s performance, and to implement certain features. We also use cookies and other data we collect to help identify and prevent fraud, and to track visits from our affiliates or partners. If you set your browser to reject cookies, you may still visit and use LGN, but your ability to use some features of LGN, including the Site or Game, may be limited.</p>
      
      <h3>Marketing Emails and Advertisements</h3>
      <p>We will not sell, transfer or otherwise provide your personal information to any third-parties for their marketing or promotional use. We do, however, use third party service providers to send our marketing e-mails, which tell you about other LGN or FlowPlay products and services. If you click on a link included in the e-mail, a persistent cookie is placed on your computer. This cookie is used to measure the effectiveness of our e-mail marketing efforts, better understand how our users navigate through LGN, and enhance user experience. The cookie will typically remain for 30 days after the last time you clicked on the coded link in the e-mail, or until you delete it. Third party providers may also service or host advertisements on LGN. The cookies generated from these advertisements do not contain Personal Information and may remain on your hard drive three or more years unless you delete them. If you do not want Personal Information about you used for this purpose, you may opt-out by following the procedure described below.</p>
      
      <h3>Invite a Friend</h3>
      <p>You may invite friends to join LGN by using the &ldquo;invite a friend&rdquo; feature in the Game. You will be required to submit your name and email address, as well as your friend&rsquo;s name and email address. Your friend&rsquo;s personal information that you submit in this feature will be used only to send the email you request, and will not be stored or maintained by us.</p>
      
      <h3>Social Media Integration</h3>
      <p>We may provide you the opportunity to connect your Account with other social media platforms, including Facebook. When you participate in social features, the information you provide may be shared with others by the operators of those platforms. We do not have any control over a third-party&rsquo;s use of personal information or any other information you may make available on those platforms, so please be sure to read the applicable privacy policies carefully to determine whether you want to connect other social media.</p>
      
      <h3>Information from Third Parties</h3>
      <p>If you play our games on a mobile device, tablet, or other device that uses a third-party service, such as a cell service provider, we receive information about you from the platform provider. This information includes your username and/or device ID and other information necessary to validate your Account. These platforms may also send other information to us as you authorize.</p>
      
      <h2>Children</h2>

      <p>You must be at least 18 years old to play LGN. Neither LGN, the Site, nor the Game is intended for or directed at anyone under the age of 18. We will not knowingly collect any personal information from individuals under 18 or knowingly allow individuals under 18 to access or use LGN or create an Account for playing the Game. If we learn that we have collected personal information from an individual under 18 we will immediately remove such personal information from LGN, including the associated Account.</p>

      <h2>How we use personal information</h2>

      <p>We may use the personal information to:</p>

      <ul>
        <li>Deliver and improve our services and create new features and promotions</li>
        <li>Process payments for purchases</li>
        <li>Tailor your user experience or our advertising to your interests</li>
        <li>Answer your questions and send you information you have requested</li>
        <li>Request your feedback about how we are doing</li>
        <li>Communicate with you, including sending emails and newsletters, about our new games or features and other products and services that may be of interest to you</li>
        <li>Comply with our legal obligations and enforce our Terms</li>
        <li>We typically store your personal information in the United States as long as reasonably necessary to fulfill the purposes described in this Privacy Policy, as we determine is necessary for business records, and as required under applicable law. We may, however, transfer your personal information from the United States to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating our business. By providing any information to us, you consent to such transfer, storage, and processing. To dispose of personal data, we may anonymize it, delete it, or take other appropriate steps. Data may persist in copies made for backup and business continuity purposes for additional time.</li>
      </ul>

      <h2>Disclosure of Personal Information to Third Parties</h2>

      <p>We will not sell, transfer or otherwise provide your personal information to any third-parties for their marketing or promotional use. We will, however, share personal information provided or obtained through LGN with third parties as follows:</p>

      <ul>
        <li>With third parties that process email communications on our behalf, including sending you emails about our products, updates to our products, or offers for other FlowPlay products</li>
        <li>With our service providers that maintain the Site or the Game and perform necessary functions on our behalf, such as financial institutions and business support companies</li>
        <li>As reasonably necessary to comply with laws, statutes, or regulations, or to enforce our Privacy Policy and the Terms and to protect our rights and the rights of others</li>
        <li>As required by a court or government agency or to respond to a claim by you or a third party</li>
        <li>As part of a transfer of our assets, for example in the event of a merger, acquisition, corporate change or, in the unlikely event of a bankruptcy, involving LGN or FlowPlay</li>
        <li>As you may otherwise request or agree</li>
        <li>Upon request we will provide you with information about whether we hold, or process on behalf of a third party, any personal information about you. To request this information please contact us at <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a></li>
      </ul>

      <h2>Commercial Electronic Message Consent</h2>

      <p>By providing your email address to us, you affirmatively and expressly consent to receiving commercial emails from us and the third parties that process email communications on our behalf. These third parties may send you commercial emails in order to deliver the updates about our products, to provide you with more information about available products you have purchased, and to provide you with special offers, and other information.</p>

      <h2>Opting Out</h2>

      <p>We give you control over how much personal information you provide to us and how we are permitted to use it. The simplest way to exercise this control is to not provide us with personal information you do not want us to collect, store or use as described in this Privacy Policy. You can also control certain aspects of how we use personal information we have collected about you:</p>

      <p>If you want to review, erase, or modify any of your personal information, or if you want to stop receiving promotional or commercial communications from us, you may do so by contacting us at privacy@flowplay.com. Please be sure to clearly indicate your contact and data privacy preferences. Also, to help us verify your request, please include the e-mail address you used when you registered with LGN and/or provided the information.</p>
      <p>You have the right to object to use of your personal information for direct marketing purposes. We use personal information to inform you about, for example, new games and other news from LGN, but you may object to such use at any time by contacting us at <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>.</p>
      <p>You may deactivate your Account or delete the personal information associated with it by contacting <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>. By doing so, you will no longer have access to the games or services associated with your Account.</p>
      <p>You have the right to object to and not receive e-mail messages from us. Each email contains a link or instructions at the bottom of the e-mail for unsubscribing. Please allow 10 days for us to process your request.</p>
      
      <h2>Security</h2>

      <p>We use physical and electronic security technologies, procedures, and organizational measures to help protect your personal information from unauthorized access, use, or disclosure. We take protection of your personal information seriously, but we cannot guarantee or warrant the security of any information you disclose or transmit to us. We urge you to help us protect you by selecting user names and passwords that are difficult to hack and by keeping your user name and password secret. To learn more about how to protect yourself online, we encourage you to visit the website http://www.onguardonline.gov/.</p>

      <p>By accessing or using LGN and providing us your personal information, you acknowledge and agree that, despite reasonable security measures, there is always a possibility that personal information may be lost or intercepted by unauthorized parties during such transmission or after receipt. Provided that we have implemented reasonable measures to ensure the security of your personal information, you understand and agree that FlowPlay is not liable for lost or intercepted data.</p>

      <h2>Changes to Privacy Policy</h2>

      <p>We may change the Site, the Game, the User Agreement and this Privacy Policy at any time and in our sole discretion. We will post the updated versions on the Site, and we will notify you by other means if we think the changes will significantly impact the treatment of personal information you have already provided. By continuing to access or use LGN, you confirm your acceptance of any modifications of the Privacy Policy. Our use of information gathered or obtained at any time is subject to the Privacy Policy in effect at the time of such use. If you have any questions or concerns after reading this, please email us at: <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>.</p>

      <h2>Your California Privacy Rights</h2>

      <p>If you are a California resident, you have the right, under the California Consumer Privacy Act, to know and see what data we have collected about you over the past 12 months, including:</p>

      <ul>
        <li>The specific personal information we have collected about you</li>
        <li>The types of sources from which the personal information is collected</li>
        <li>The business or commercial purpose for collecting your personal information</li>
        <li>The types of third parties with whom we have shared your personal information</li>
        <li>You have the right to request that we delete the personal information we have collected from you, and that we direct our service providers to do the</li>
      </ul>

      <p>Once a year, you may also request certain information about our disclosure of personal information to third parties for their own direct marketing purposes during the preceding 12 months. You may not be discriminated against for exercising any of the rights listed above.</p>

      <p>Certain information may be exempt from your request. For example, as described above, certain information is required to use certain functions of LGN. So, if you ask us to delete it, you may no longer be able to access or use LGN.</p>

      <p>To exercise your rights under the California Consumer Privacy Act, please contact us by either sending an e-mail to <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>, or by phone at <a href="tel:1-877-FLOWPLA">1-877-FLOWPLA</a> (356-9752). Please provide your full name and e-mail address so that we may respond to your request as quickly as possible. You will be required to verify your identify before we fulfill your request. To do so, you will need to provide us with certain account information, such as your full name and email address. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>

      <p>The CCPA sets forth certain obligations for businesses that &ldquo;sell&rdquo; personal information. We share certain information and allow third parties to collect certain information about you and your activity, as described above. But FlowPlay does not &ldquo;sell&rdquo; your personal information, so we do not believe these certain obligations under the CCPA apply.</p>

      <p>Any request for a disclosure required under this California law must be sent to us via email at <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a> or via regular mail at the address below.</p>

      <p>We support and honor &ldquo;Do Not Track&rdquo; web browser settings. If you enable Do Not Track settings in the browser you are using, we will not collect, store, or use information about websites you visit using that browser other than livegamenight.com. Other parties, however, may not honor Do Not Track signals. These parties may collect personal information about your online activities over time and across different web sites when you visit livegamenight.com, for example by using cookies on our site. We have no access to or control over other parties&rsquo; information collection practices, even those with which we may have an affiliation. You should carefully review the privacy policy and terms of any website you visit. For more information about Do Not Track, please visit <a href="http://www.allaboutdnt.org">http://www.allaboutdnt.org</a>.</p>

      <h2>International Visitors</h2>

      <p>If you reside in the European Economic Area (&ldquo;EEA&rdquo;) or Switzerland, the information in this section is relevant to you. If you do not reside in the EEA or Switzerland, you should not rely on the information in this section.</p>

      <p>LGN, including the Site and the Game, is hosted in and provided from the United States. If you access or use LGN from the EEA, Switzerland, Canada or other regions, the laws applicable to you governing data collection and use may differ from U.S. law. In this regard, please note that you are transferring your personal data to the United States. The United States does not have the same data protection laws as other regions. By providing your personal information, you consent to the transfer of your personal data to the United States and the use of your personal information, in accordance with this Privacy Policy.</p>

      <p>As required under the General Data Protection Regulation (&ldquo;GDPR&rdquo;), we have carefully balanced our legitimate business interests against your data protection rights. Because FlowPlay has users around the globe, we believe that processing users&rsquo; personal information for internal administrative purposes, customer service, direct marketing, and as otherwise described in this Privacy Policy, are legitimate interests under the GDPR.</p>

      <p>You have the right to request access to the personal information that we collect about you, to have your personal information corrected or erased, to restrict the processing of your personal information, as well as the right to data portability (certain of these rights are subject to our obligation to verify your identity). To exercise these rights or if you have a complaint, you must submit a written request to <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>. If you are not satisfied with the way we handle your request, you can lodge a complaint with the relevant authority in your home country.</p>

      <p>If we have obtained your consent to collect, store, and use your personal information, you have the right to withdraw your consent at any time. To withdraw your consent to e-mail marketing or your consent to the use of cookies, use the unsubscribe link in the email and/or email us at <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>. This will not affect the lawfulness of our collection, use, and processing that has occurred based on your consent prior to the withdrawal.</p>

      <p>You also have the right to object, at any time, to the collection, storage, and use of your personal information, even where it is based on FlowPlay&rsquo;s legitimate interests, provided that we may retain your personal information for reasonably necessary purposes such as legal, auditing, accounting and billing purposes. If we use your personal information for direct marketing purposes, you have the right to object at any time to such use, including for profiling purposes to the extent that it is related to direct marketing.</p>

      <p>LGN complies with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework as set forth by the US Department of Commerce regarding the collection, use, and retention of personal information from European Union member countries and Switzerland transferred to the United States pursuant to Privacy Shield. LGN has certified that it adheres to the Privacy Shield Principles. If there is any conflict between the policies in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles govern. To learn more about the Privacy Shield program, and to view our certification page, please visit https://www.privacyshield.gov/.</p>

      <p>Except as otherwise provided in this Privacy Policy, we only disclose personal information to third parties who reasonably need to have access to it for the purpose of the transaction or activity for which it was originally collected or to provide services to or perform tasks or our behalf or under our instruction. All such third parties must agree to use such the personal information we provide to them only the purposes for which we have engaged them and they must agree in writing to provide adequate protections for the personal information that are no less protective than those set out in this Privacy Policy. Where we have knowledge that an entity to whom we have provided personal information is using or disclosing personal information in a manner contrary to this Privacy Policy, we will take reasonable and appropriate steps to prevent, remediate or stop the use or disclosure. In cases of onward transfer to third parties of data of EU and Swiss individuals received pursuant to the EU-US and Swiss-US Privacy Shield Frameworks, LGN is potentially liable.</p>

      <p>We also may disclose personal information for other purposes or to other third parties when you have consented to or requested such disclosure. Please be aware that we will disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We are not liable for appropriate onward transfers of personal data to non-agent third parties.</p>

      <p>We may retain third parties to process or analyze personal Information we collect from LGN. For example, the Site may be maintained or hosted by a third party service provider, a promotion may be administered by a sales promotion agency, and/or products may be fulfilled by a wholesaler. These suppliers and other third parties who provide services for us are contractually obligated not to use personal information about you except as we authorize.</p>

      <p>We use Google Analytics for aggregated, anonymized website traffic analysis. In order to track your session usage, Google drops a cookie (_ga) with a randomly-generated ClientID in your browser. This ID is anonymized and contains no identifiable information like email, phone number, name, etc. We also send Google your IP Address. We use GA to track aggregated website behavior, such as what pages you looked at, for how long, and so on. This information is important to us for improving the user experience and determining site effectiveness. To request the removal of analytics data, please contact us at <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>.</p>

      <p>Privacy Shield: Your rights include: a right to access information we hold about you and to have incorrect personal information about you corrected. In compliance with the Privacy Shield Principles, FlowPlay commits to resolve complaints about your privacy and our collection or use of your personal information transferred to the United States pursuant to Privacy Shield. Residents of the EEA and Switzerland with Privacy Shield inquiries or complaints should first contact LGN at: <a href="mailto:privacy@flowplay.com">privacy@flowplay.com</a>. LGN has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by the Council of Better Business Bureaus. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit www.bbb.org/EU-privacy-shield/for-euconsumers for more information and to file a complaint. This service is provided free of charge to you. If your Privacy Shield complaint cannot be resolved through these channels, you may complain to your home data protection authority and as a last resort, may invoke binding arbitration for some residual claims not resolved by other redress mechanisms.</p>

      <p>Contact details for the EU data protection authorities can be found at http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm. The Federal Trade Commission has jurisdiction over our compliance with this Privacy Policy and the EUUS Privacy Shield Framework and the Swiss-US Privacy Shield Framework.</p>

      <h2>Unity Integration</h2>

      <p>Some of our games utilize Unity Analytics to collect data that helps us improve the game. Please see <a href="https://docs.unity3d.com/Manual/UnityAnalyticsDataPrivacy.html">Unity’s Privacy Policy</a> for more information.</p>

      <h2>Contact</h2>

      <p>We take your privacy seriously, and invite you to contact us at the address below with any questions or concerns you may have regarding this Privacy Policy or our collection, storage, and use of your personal information: FlowPlay, Inc. 1008 Western Ave, Suite 300 Seattle, Washington 98104. Should you so choose, however, you have the right to lodge a formal complaint regarding our use of personal information about you with the Federal Trade Commission, the supervisory authority for the United States, using the following contact information: Federal Trade Commission 600 Pennsylvania Avenue, Northwest Washington, DC 20580 Tel. +1 (202) 326-2222.</p>

      <p>Effective May 1, 2020.</p>
      {/* eslint-enable */}
    </div>
  </div>
);

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  PrivacyPolicy
);
