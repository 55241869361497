import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  modal: {
    position: 'fixed',
    zIndex: 99999,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    color: '#6CAAFF',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: 200,
    marginBottom: 35,
    [theme.breakpoints.down('xs')]: {
      width: 125
    }
  },
  progress: {
    color: '#6CAAFF',
    marginBottom: 15
  },
  message: {
    color: '#FFFFFF',
    letterSpacing: 1.2,
    textAlign: 'center',
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      padding: '0 35px'
    }
  }
});

const GlobalProgressSpinner = ({ classes, message }) => (
  <div className={classes.modal}>
    <div className={classes.contentWrapper}>
      <CustomImage
        alt="logo"
        className={classes.logo}
        src="/logo.png"
      />
      <CircularProgress className={classes.progress} />
      <p className={classes.message}>{message}</p>
    </div>
  </div>
);

GlobalProgressSpinner.defaultProps = {
  message: ''
};

GlobalProgressSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string
};

export default
withStyles(styles)(
  GlobalProgressSpinner
);
