import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import CustomButton from './CustomButton';
import LGNZoomLogoTieUp from './LGNZoomLogoTieUp';
import {
  HOME_WRAPPER, HOME_HEADER, HOME_GAME_WRAPPER, HOME_VIDEO,
  HOME_CTA_BUTTON, HOME_SUMMARY_WRAPPER, HOME_SUMMARY,
  HOME_SUMMARY_HEADER, HOME_SUMMARY_TEXT
} from '../styles/global';
import ProgressiveMedia from '../containers/ProgressiveMedia';

const styles = (theme) => ({
  wrapper: {
    ...HOME_WRAPPER()
  },
  gameWrapper: {
    ...HOME_GAME_WRAPPER()
  },
  header: {
    ...HOME_HEADER(theme),
    backgroundColor: 'black'
  },
  video: {
    ...HOME_VIDEO(theme)
  },
  ctaButton: {
    ...HOME_CTA_BUTTON(theme),
    backgroundColor: 'rgba(45, 140, 255, 1)',
    border: 'solid 3px rgba(60, 180, 255, 1)',
    '&:hover': {
      backgroundColor: 'rgba(45, 140, 255, 0.9)'
    },
    animation: '$grow 5000ms forwards'
  },
  summaryWrapper: {
    ...HOME_SUMMARY_WRAPPER(theme),
    backgroundColor: 'rgba(45, 140, 255, 1)'
  },
  summary: {
    ...HOME_SUMMARY(theme)
  },
  summaryHeader: {
    ...HOME_SUMMARY_HEADER(theme)
  },
  summaryText: {
    ...HOME_SUMMARY_TEXT(theme)
  },
  mobileComingSoon: {
    backgroundColor: 'rgba(45, 140, 255, 1)',
    marginBottom: 0
  },
  '@keyframes grow': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(1.1)'
    }
  }
});

const ZoomHome = ({ classes }) => {
  useEffect(() => {
    // Make sure the user sees the top of the UI on load.
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          content="Learn more about live, virtual gaming with friends on Zoom!"
          name="description"
        />
        <title>LGN on Zoom</title>
        <link
          href="https://play.livegamenight.com/zoom/home"
          rel="canonical"
        />
      </Helmet>
      <div className={classes.header}>
        <LGNZoomLogoTieUp />
      </div>
      <div className={classes.gameWrapper}>
        <ProgressiveMedia
          className={classes.video}
          isVideo
          mediaSource="/zoom-poker-gameplay.mp4"
          videoAutoplay
          videoMuted
        />
        <CustomButton
          className={classNames(
            classes.ctaButton,
            'cta-button'
          )}
          isExternalLinkButton
          linkPath="https://marketplace.zoom.us/apps/QRmdBUnwSpuQkFnEnUfq5g"
          size="large"
        >
          play on zoom
        </CustomButton>
      </div>
      <div className={classes.summaryWrapper}>
        <div className={classes.summary}>
          <h1 className={classes.summaryHeader}>Virtual Happy Hour – On Zoom</h1>
          <p className={classes.summaryText}>
            Enjoy your favorite live poker experience on even more platforms
            with LGN Poker for Zoom.
          </p>
        </div>
        <div className={classes.summary}>
          <h1 className={classes.summaryHeader}>Available Now</h1>
          <p className={classes.summaryText}>
            LGN Poker for Zoom is the same one-of-a-kind, invite-only, Texas
            hold ’em style poker game you know and love, playable directly
            in your desktop Zoom app. Click the Play On Zoom button above to check out LGN Poker
            on the Zoom App Marketplace and get started playing for FREE today!
          </p>
        </div>
      </div>
    </div>
  );
};

ZoomHome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomHome
);
