import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  sortBy, remove, concat, cloneDeep, uniq, pull
} from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import {
  withStyles, InputAdornment, TextField, Chip
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Cancel as CancelIcon
} from '@material-ui/icons';
import FAQData from '../data/faq';
import { CONTENT_WRAPPER } from '../styles/global';
import { TEXT_COLOR } from '../styles/palette';
import { getSanitizedFAQQuestionText } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    alignItems: 'flex-start',
    paddingLeft: 100,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 25,
      paddingTop: 25
    }
  },
  textField: {
    width: 400,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      width: '98%'
    }
  },
  clearSearch: {
    cursor: 'pointer'
  },
  question: {
    fontSize: 20,
    cursor: 'pointer',
    marginBottom: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  category: {
    color: TEXT_COLOR,
    fontSize: 26
  },
  chip: {
    marginRight: 5,
    cursor: 'pointer',
    width: 85,
    boxSizing: 'border-box',
    color: TEXT_COLOR,
    backgroundColor: '#33496e',
    '&.selected': {
      backgroundColor: '#33496e',
      border: 'solid 2px #cdcdcd',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 5
    }
  }
});

const getCategoryText = (category) => {
  switch (category) {
    case 'general':
      return 'General Questions';
    case 'poker':
      return 'LGN Poker';
    case 'zoom':
      return 'LGN on Zoom';
    case 'dice':
      return 'LGN Dice';
    // case 'boggle':
    //   return 'Boggle';
    case 'illustrious':
      return 'LGN Illustrious';
    default:
      return '';
  }
};

const FAQ = ({ classes }) => {
  const ALL_CHIP_TEXT = 'all';
  const [searchTerm, setSearchTerm] = useState('');
  const [mappedFaqData, setMappedFaqData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const location = useLocation();
  let currentCategory = '';

  useEffect(() => {
    // Sort the FAQ entries by category, ensure that all Zoom-related entries appear
    // first, then filter by search term.
    const faqDataClone = cloneDeep(FAQData);
    const faqZoomEntries = remove(faqDataClone, (datum) => datum.category === 'zoom');
    const sortedFaqData = sortBy(faqDataClone, 'category');
    const zoomFirstSortedFaqData = concat(faqZoomEntries, sortedFaqData);
    let filteredFaqData = zoomFirstSortedFaqData.filter(
      (faqDatum) => faqDatum.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedCategories.length) {
      filteredFaqData = filteredFaqData.filter(
        (faqDatum) => selectedCategories.includes(faqDatum.category)
      );
    }

    setMappedFaqData(filteredFaqData);
  }, [searchTerm, selectedCategories]);

  useEffect(() => {
    const mappedCategories = FAQData.map((datum) => datum.category);
    const uniqueCategories = uniq(mappedCategories);

    setCategories(uniqueCategories);
  }, []);

  const handleChipClick = (event) => {
    const innerText = event.target.innerText.replace(' ', '-');
    let selectedCategoriesClone = cloneDeep(selectedCategories);

    if (innerText === ALL_CHIP_TEXT) {
      selectedCategoriesClone = [];
    } else if (selectedCategoriesClone.includes(innerText)) {
      pull(selectedCategoriesClone, innerText);
    } else {
      selectedCategoriesClone.push(innerText);
    }

    setSelectedCategories(selectedCategoriesClone);
  };

  const categoryHeaderRef = useCallback((node) => {
    const { hash } = location;

    // If the currently referenced category header element ID matches the value
    // in the hash (without the #), scroll to the categroy header.
    if (node && hash && (node.id === hash.substring(1))) {
      const element = document.getElementById(node.id);
      element.scrollIntoView();
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <TextField
        className={classes.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment
              className={classes.clearSearch}
              position="end"
            >
              <CancelIcon onClick={() => setSearchTerm('')} />
            </InputAdornment>
          )
        }}
        label="Search FAQ"
        onChange={(event) => setSearchTerm(event.target.value)}
        value={searchTerm}
        variant="filled"
      />
      {categories.length > 0 && (
        <div>
          <p>Filter by category</p>
          <Chip
            className={classNames(classes.chip, selectedCategories.length === 0 && 'selected')}
            clickable
            label={ALL_CHIP_TEXT}
            onClick={handleChipClick}
          />
          {categories.map((category) => (
            <span key={category}>
              <Chip
                className={classNames(classes.chip, selectedCategories.includes(category) && 'selected')}
                clickable
                label={category.replace('-', ' ')}
                onClick={handleChipClick}
              />
            </span>
          ))}
        </div>
      )}
      {mappedFaqData.map((datum) => {
        // Convert empty spaces to hyphens, and remove all special characters in the
        // question text so it can be used as the answer URI.
        const sanitizedQuestionText = getSanitizedFAQQuestionText(datum.question);

        const markup = (
          <>
            {datum.category !== currentCategory && (
              <p
                key={datum.category}
                ref={categoryHeaderRef}
                className={classes.category}
                id={datum.category}
              >
                {getCategoryText(datum.category)}
              </p>
            )}
            <Link
              key={sanitizedQuestionText}
              className={classes.question}
              dangerouslySetInnerHTML={{ __html: datum.question }}
              to={`/faq/${sanitizedQuestionText}`}
            />
          </>
        );

        currentCategory = datum.category;

        return markup;
      })}
    </div>
  );
};

FAQ.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  FAQ
);
