import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  withStyles, Dialog, DialogActions,
  DialogContent, FormControlLabel, Checkbox,
  DialogContentText, CircularProgress
} from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import MediaDeviceSelect from '../components/MediaDeviceSelect';
import {
  setEnableVideoOnJoinGame as setEnableVideoOnJoinGameAction,
  setEnableAudioOnJoinGame as setEnableAudioOnJoinGameAction,
  setVideoInputDevice as setVideoInputDeviceAction,
  setAudioOutputDevice as setAudioOutputDeviceAction,
  setAudioInputDevice as setAudioInputDeviceAction,
  setVideoEnabled as setVideoEnabledAction,
  setAudioEnabled as setAudioEnabledAction,
  setHasSeenPlatformSettings as setHasSeenPlatformSettingsAction,
  setShowSettingsOnLoad as setShowSettingsOnLoadAction,
  setShowSettingsOnLoadComplete as setShowSettingsOnLoadCompleteAction,
  showSettingsDialog as showSettingsDialogAction
} from '../actions/settings';
import {
  VIDEO_INPUT_DEVICE, AUDIO_INPUT_DEVICE
} from '../enums/local-storage';
import { LIGHT_BOX_SHADOW } from '../styles/global';
import useDevices from '../hooks/useDevices';
import DialogTitleWithLogo from '../components/DialogTitleWithLogo';
import CustomImage from './CustomImage';
import Route from '../enums/route';

const COLUMN_PADDING = 25;

const styles = (theme) => ({
  dialogPaper: {
    ...LIGHT_BOX_SHADOW(theme),
    width: 722,
    height: 716
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  columnsWrapper: {
    display: 'flex'
  },
  label: {
    fontSize: 18,
    marginBottom: 12,
    color: '#c7c7c7'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    paddingRight: COLUMN_PADDING
  },
  rightColumn: {
    paddingLeft: COLUMN_PADDING
  },
  rightColumnRow: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 18
  },
  videoOffIcon: {
    fontSize: 100
  },
  button: {
    margin: 0
  },
  testButton: {
    width: 100,
    marginBottom: 12
  },
  buttonIcon: {
    marginRight: 5
  },
  preview: {
    border: 'solid 2px rgba(255, 255, 255, 0.2)',
    boxSizing: 'border-box',
    borderRadius: 4,
    marginBottom: 8,
    width: 300,
    height: 169,
    transform: 'rotate(180deg) scaleY(-1)'
  },
  videoPreview: {
    backgroundColor: 'black',
    objectFit: 'cover'
  },
  audioPreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  audioIcon: {
    width: 100
  },
  checkboxInput: {
    marginBottom: 0
  },
  pokerDialogAcceptButton: {
    textTransform: 'uppercase'
  },
  text: {
    textAlign: 'center',
    lineHeight: 1.4,
    marginBottom: 35,
    '&:last-child': {
      marginBottom: 0
    }
  },
  loadingContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  progressSpinner: {
    color: '#6CAAFF',
    marginBottom: 25
  },
  loadingMessage: {
  }
});

const mapStateToProps = (state) => ({
  settings: state.settings,
  hasLoadedLGNVideoLibrary: state.hasLoadedLGNVideoLibrary,
  platform: state.platform
});

const mapDispatchToProps = (dispatch) => ({
  setEnableVideoOnJoinGame: (enable) => dispatch(setEnableVideoOnJoinGameAction(enable)),
  setEnableAudioOnJoinGame: (enable) => dispatch(setEnableAudioOnJoinGameAction(enable)),
  setVideoInputDevice: (device) => dispatch(setVideoInputDeviceAction(device)),
  setAudioInputDevice: (device) => dispatch(setAudioInputDeviceAction(device)),
  setAudioOutputDevice: (device) => dispatch(setAudioOutputDeviceAction(device)),
  setVideoEnabled: (enabled) => dispatch(setVideoEnabledAction(enabled)),
  setAudioEnabled: (enabled) => dispatch(setAudioEnabledAction(enabled)),
  setHasSeenPlatformSettings: (hasSeen) => dispatch(setHasSeenPlatformSettingsAction(hasSeen)),
  setShowSettingsOnLoad: (show) => dispatch(setShowSettingsOnLoadAction(show)),
  setShowSettingsOnLoadComplete: (complete) => dispatch(setShowSettingsOnLoadCompleteAction(complete)), // eslint-disable-line
  showSettingsDialog: (showDialog) => dispatch(showSettingsDialogAction(showDialog))
});

const MediaPlatformSettings = ({
  classes, open, onClose,
  settings, setEnableVideoOnJoinGame, setEnableAudioOnJoinGame,
  setVideoInputDevice, setAudioInputDevice, setAudioOutputDevice,
  setVideoEnabled, setAudioEnabled, hasLoadedLGNVideoLibrary,
  setHasSeenPlatformSettings, setShowSettingsOnLoad, setShowSettingsOnLoadComplete,
  platform, showSettingsDialog
}) => {
  const [videoStream, setVideoStream] = useState(null);
  const [videoInputDeviceId, setVideoInputDeviceId] = useState('');
  const [audioInputDeviceId, setAudioInputDeviceId] = useState('');
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState('');
  const [enableVideoOnJoin, setEnableVideoOnJoin] = useState(null);
  const [enableAudioOnJoin, setEnableAudioOnJoin] = useState(null);
  const [alwaysShow, setAlwaysShow] = useState(settings.showSettingsOnLoad);

  const videoRef = useCallback((node) => {
    if (node && videoStream) {
      node.srcObject = videoStream; // eslint-disable-line
    }
  }, [videoStream]);

  const handleClose = () => {
    setHasSeenPlatformSettings(true);

    // Only mark the settings dialog as having been viewed if user is currently
    // active in the media platform or Poker.
    if (platform.isActive || platform.isPokerActive) {
      setShowSettingsOnLoadComplete(true);
    }

    onClose();
  };

  const handleAcceptClick = () => {
    if (settings.videoInputDevice !== videoInputDeviceId) {
      setVideoInputDevice(videoInputDeviceId);
    }

    if (settings.audioInputDevice !== audioInputDeviceId) {
      setAudioInputDevice(audioInputDeviceId);
    }

    if (settings.audioOutputDevice !== audioOutputDeviceId) {
      setAudioOutputDevice(audioOutputDeviceId);
    }

    if (settings.enableVideoOnJoinGame !== enableVideoOnJoin) {
      setEnableVideoOnJoinGame(enableVideoOnJoin);
    }

    if (settings.enableAudioOnJoinGame !== enableAudioOnJoin) {
      setEnableAudioOnJoinGame(enableAudioOnJoin);
    }

    if (settings.showSettingsOnLoad !== alwaysShow) {
      setShowSettingsOnLoad(alwaysShow);
    }

    if (
      hasLoadedLGNVideoLibrary
      && (
        !settings.hasSeenPlatformSettings
        || (settings.showSettingsOnLoad && !settings.showSettingsOnLoadComplete)
      )
    ) {
      setVideoEnabled(enableVideoOnJoin);
      setAudioEnabled(enableAudioOnJoin);
    }

    handleClose();
  };

  const getActionButtons = () => {
    // If the settings dialog is auto-showing when either Poker or a media platform game
    // is loaded, use a single button with text that indicates choosing the settings
    // will move the player into the game; otherwise, show the standard cancel/save buttons.
    if (
      settings.showSettingsOnLoad
      && !settings.showSettingsOnLoadComplete
      && (platform.isActive || platform.isPokerActive)
    ) {
      return (
        <CustomButton
          className={classes.button}
          onClick={handleAcceptClick}
        >
          continue
        </CustomButton>
      );
    }

    return (
      <>
        <CustomButton
          className={classes.button}
          onClick={handleClose}
          primary={false}
        >
          cancel
        </CustomButton>
        {settings.videoInputDevices.length > 0 && settings.audioInputDevices.length > 0 && (
          <CustomButton
            className={classes.button}
            onClick={handleAcceptClick}
          >
            save
          </CustomButton>
        )}
      </>
    );
  };

  useDevices();

  useEffect(() => {
    if (videoInputDeviceId) {
      const constraints = {
        video: {
          width: 300,
          height: 169,
          deviceId: { exact: videoInputDeviceId }
        }
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          setVideoStream(stream);
        });
    }
  }, [videoInputDeviceId]);

  useEffect(() => () => {
    if (videoStream) {
      const videoTrack = videoStream.getTracks()[0];
      videoTrack.stop();
    }
  }, [videoStream]);

  useEffect(() => {
    if (settings.videoInputDevice) setVideoInputDeviceId(settings.videoInputDevice);
    if (settings.audioInputDevice) setAudioInputDeviceId(settings.audioInputDevice);
    if (settings.audioOutputDevice) setAudioOutputDeviceId(settings.audioOutputDevice);
    if (settings.enableVideoOnJoinGame) setEnableVideoOnJoin(settings.enableVideoOnJoinGame);
    if (settings.enableAudioOnJoinGame) setEnableAudioOnJoin(settings.enableAudioOnJoinGame);
  }, [
    settings.videoInputDevice,
    settings.audioInputDevice,
    settings.audioOutputDevice,
    settings.enableVideoOnJoinGame,
    settings.enableAudioOnJoinGame
  ]);

  useEffect(() => {
    const handlePopState = () => showSettingsDialog(false);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  });

  // If the media permissions check is not in progress and permissions has not been
  // granted, show the dialog asking the user to enable media permissions.
  if (!platform.isCheckingMediaPermissions && !platform.hasMediaPermissions) {
    return (
      <Dialog
        maxWidth="xs"
        open={open}
        PaperProps={{
          className: classes.dialogPaper
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <DialogTitleWithLogo text="Please enable media permissions" />
          <DialogContentText className={classes.text}>
            Live Game Night uses your camera and microphone to allow
            players to see and hear you.
          </DialogContentText>
          <DialogContentText className={classes.text}>
            Please ensure your camera is not in use by another
            app, and grant media access to your browser.
          </DialogContentText>
          <DialogContentText className={classes.text}>
            <Link
              target="_blank"
              to={Route.MEDIA_PERMISSIONS_HELP}
            >
              Learn More
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={onClose}>Continue</CustomButton>
        </DialogActions>
      </Dialog>
    );
  }

  // If media input devices are being loaded, or media permissions are still being
  // verified (which is a pre-requisite to loading media devices), show the device
  // loading dialog.
  if (settings.isLoadingDevices || platform.isCheckingMediaPermissions) {
    return (
      <Dialog
        maxWidth="lg"
        open={open}
        PaperProps={{
          className: classes.dialogPaper
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <DialogTitleWithLogo text="media input settings" />
          <div className={classes.loadingContentWrapper}>
            <CircularProgress
              className={classes.progressSpinner}
              size={40}
            />
            <DialogContentText className={classes.loadingMessage}>
              Loading devices...
            </DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogTitleWithLogo text="media input settings" />
        <div className={classes.columnsWrapper}>
          <div className={classNames(classes.column, classes.leftColumn)}>
            <div className={classes.label}>Camera</div>
            {settings.videoInputDevices.length ? (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  className={classNames(classes.preview, classes.videoPreview)}
                />
                <MediaDeviceSelect
                  devices={settings.videoInputDevices}
                  onChange={(deviceId) => setVideoInputDeviceId(deviceId)}
                  selectedDeviceId={videoInputDeviceId}
                  type={VIDEO_INPUT_DEVICE}
                />
                {!platform.isPokerActive && (
                  <FormControlLabel
                    className={classes.checkboxInput}
                    control={(
                      <Checkbox
                        checked={enableVideoOnJoin}
                        onChange={(event) => setEnableVideoOnJoin(event.target.checked)}
                      />
                    )}
                    label="Enable video when joining a game"
                  />
                )}
              </>
            ) : (
              <p>No video input devices found.</p>
            )}
          </div>
          <div className={classNames(classes.column, classes.rightColumn)}>
            <div className={classes.rightColumnRow}>
              <div className={classes.label}>Microphone</div>
              {settings.audioInputDevices.length ? (
                <>
                  <div className={classNames(classes.preview, classes.audioPreview)}>
                    <CustomImage
                      className={classes.audioIcon}
                      src="/icons/mic_on.svg"
                    />
                  </div>
                  <MediaDeviceSelect
                    devices={settings.audioInputDevices}
                    onChange={(deviceId) => setAudioInputDeviceId(deviceId)}
                    selectedDeviceId={audioInputDeviceId}
                    type={AUDIO_INPUT_DEVICE}
                  />
                  {!platform.isPokerActive && (
                    <FormControlLabel
                      className={classes.checkboxInput}
                      control={(
                        <Checkbox
                          checked={enableAudioOnJoin}
                          onChange={(event) => setEnableAudioOnJoin(event.target.checked)}
                        />
                      )}
                      label="Enable audio when joining a game"
                    />
                  )}
                </>
              ) : (
                <p>No audio input devices found.</p>
              )}
            </div>
          </div>
        </div>
        {settings.audioInputDevices.length > 0 && settings.videoInputDevices.length > 0 && (
          <div className={classes.otherSettings}>
            <div className={classes.label}>Options</div>
            <FormControlLabel
              className={classes.checkboxInput}
              control={(
                <Checkbox
                  checked={alwaysShow}
                  onChange={(event) => setAlwaysShow(event.target.checked)}
                />
              )}
              label="Always show settings when starting a game"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {getActionButtons()}
      </DialogActions>
    </Dialog>
  );
};

MediaPlatformSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  hasLoadedLGNVideoLibrary: PropTypes.bool.isRequired,
  hasSeenPlatformSettings: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  platform: PropTypes.shape({
    hasMediaPermissions: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    isCheckingMediaPermissions: PropTypes.bool.isRequired,
    isPokerActive: PropTypes.bool.isRequired
  }).isRequired,
  setAudioEnabled: PropTypes.func.isRequired,
  setAudioInputDevice: PropTypes.func.isRequired,
  setAudioOutputDevice: PropTypes.func.isRequired,
  setEnableAudioOnJoinGame: PropTypes.func.isRequired,
  setEnableVideoOnJoinGame: PropTypes.func.isRequired,
  setHasSeenPlatformSettings: PropTypes.func.isRequired,
  setShowSettingsOnLoad: PropTypes.func.isRequired,
  setShowSettingsOnLoadComplete: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    audioInputDevice: PropTypes.string.isRequired,
    audioInputDevices: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    audioOutputDevice: PropTypes.string.isRequired,
    audioOutputDevices: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    enableAudioOnJoinGame: PropTypes.bool.isRequired,
    enableVideoOnJoinGame: PropTypes.bool.isRequired,
    hasSeenPlatformSettings: PropTypes.bool.isRequired,
    isLoadingDevices: PropTypes.bool.isRequired,
    isVideoEnabled: PropTypes.bool.isRequired,
    showSettingsOnLoad: PropTypes.bool.isRequired,
    showSettingsOnLoadComplete: PropTypes.bool.isRequired,
    videoInputDevice: PropTypes.string.isRequired,
    videoInputDevices: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired
  }).isRequired,
  setVideoEnabled: PropTypes.func.isRequired,
  setVideoInputDevice: PropTypes.func.isRequired,
  showSettingsDialog: PropTypes.func.isRequired,
  showSettingsOnLoad: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    MediaPlatformSettings
  )
);
