import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { CONTENT_WRAPPER, CONTENT_TEXT } from '../styles/global';
import LGNPokerLogo from '../components/LGNPokerLogo';
import { isMobileDevice, isAndroid, isIOS } from '../lib/browser';
import { customFetch } from '../lib/http';
import AppStoreButton from '../components/AppStoreButton';
import PlayStoreButton from '../components/PlayStoreButton';
import { getCdnPath } from '../lib/env';
import useGames from '../hooks/useGames';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  logo: {
    marginBottom: 35
  },
  text: {
    ...CONTENT_TEXT(theme)
  }
});

const mapStateToProps = (state) => ({
  host: state.host,
  games: state.games
});

const AffiliateHome = ({ classes, host, games }) => {
  useGames();

  useEffect(() => {
    if (!isMobileDevice() && games && games.length) {
      const params = new URLSearchParams(window.location.search);
      const network = params.get('network');
      const cacheBuster = Math.random().toString().substring(2);
      const poker = games.find((g) => g.id === 7);
      let gameUrl = `${getCdnPath()}`;

      customFetch(`${host}kitsune/gameClientVersion?game_id=${poker.id}&cb=${cacheBuster}`)
        .then((json) => {
          const { version } = json;

          gameUrl = `${getCdnPath()}${poker.clientPath}?v=${version}`;

          if (network) {
            gameUrl = `${gameUrl}&network=${network}`;
          }

          // Redirect desktop users directly to the production deployment.
          window.location = gameUrl;
        });
    }
  }, [games]);

  return (
    <div className={classes.wrapper}>
      <LGNPokerLogo className={classes.logo} />
      {!isMobileDevice() && (
        <p className={classes.text}>Redirecting to Game Content...</p>
      )}
      {isAndroid() && (
        <PlayStoreButton />
      )}
      {isIOS() && (
        <AppStoreButton />
      )}
    </div>
  );
};

AffiliateHome.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ).isRequired,
  host: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps)(
    AffiliateHome
  )
);
