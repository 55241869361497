import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  config: state.config
});

const CustomImage = ({
  config, alt, src, className, ...rest
}) => (
  <>
    {config.mediaPath && (
      <img
        alt={alt}
        className={className}
        src={`${config.mediaPath}${src}`}
        {...rest}
      />
    )}
  </>
);

CustomImage.defaultProps = {
  alt: '',
  className: ''
};

CustomImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.shape({
    mediaPath: PropTypes.string.isRequired
  }).isRequired,
  src: PropTypes.string.isRequired
};

export default
connect(mapStateToProps)(
  CustomImage
);
