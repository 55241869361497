import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomImage from '../containers/CustomImage';

const PlayStoreButton = ({ className, imageClassName }) => (
  <a
    className={classNames(
      className,
      'cta-button'
    )}
    href="https://play.google.com/store/apps/details?id=com.lgn.livepokernight"
    target="blank"
  >
    <CustomImage
      alt="play store"
      className={imageClassName || ''}
      src="/play-store.png"
    />
  </a>
);

PlayStoreButton.defaultProps = {
  className: '',
  imageClassName: ''
};

PlayStoreButton.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
};

export default PlayStoreButton;
