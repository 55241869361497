import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showLoginDialog, hideLoginDialog } from '../actions/login-dialog';
import { isMobileDevice } from '../lib/browser';

const useLogin = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.isAuthenticating && !user.isAuthenticated && !isMobileDevice()) {
      dispatch(showLoginDialog());
    }
  }, []);

  useEffect(() => {
    if (!user.isAuthenticating && user.isAuthenticated) {
      dispatch(hideLoginDialog());
    }
  }, [user.isAuthenticating, user.isAuthenticated]);
};

export default useLogin;
