import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = () => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 25
  },
  titleLogo: {
    width: 52,
    height: 40,
    marginRight: 12
  },
  titleText: {
    fontSize: 20,
    textTransform: 'capitalize'
  },
});

const DialogTitleWithLogo = ({ classes, text }) => (
  <div className={classes.title}>
    <CustomImage
      className={classes.titleLogo}
      src="/logo-bubble.png"
    />
    <p className={classes.titleText}>{text}</p>
  </div>
);

DialogTitleWithLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  DialogTitleWithLogo
);
