import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TEXT_COLOR } from '../styles/palette';

const sharedButtonStyles = {
  padding: '0 15px',
  marginRight: 8,
  textTransform: 'capitalize',
  color: '#d9d9d9',
  boxSizing: 'border-box',
  borderRadius: 6,
  '&.small': {
    height: 30,
    fontSize: 14,
    padding: '6px 20px 6px 20px'
  },
  '&.medium': {
    height: 50,
    fontSize: 20,
    padding: '12px 55px 12px 55px'
  },
  '&.large': {
    height: 70,
    fontSize: 24,
    padding: '24px 85px 24px 85px'
  },
  '&.primary': {
    border: 'solid 1px rgba(146, 255, 255, 0.4)',
    backgroundColor: '#33496e',
    '&:hover': {
      backgroundColor: '#4b5f7f'
    },
    '&:active': {
      backgroundColor: '#2b3d5e'
    }
  },
  '&.secondary': {
    border: 'solid 1px rgba(255, 255, 255, 0.2)',
    backgroundColor: '#45454b',
    '&:hover': {
      backgroundColor: '#6a6a6f'
    },
    '&:active': {
      backgroundColor: '#37373c'
    }
  }
};

const styles = {
  button: {
    ...sharedButtonStyles
  },
  linkButton: {
    ...sharedButtonStyles,
    color: TEXT_COLOR,
    display: 'inline-flex',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(10, 76, 167, 0.8)'
    }
  }
};

const getSizeClass = (size) => {
  switch (size) {
    case 'small':
      return 'small';
    case 'large':
      return 'large';
    case 'medium':
    default:
      return 'medium';
  }
};

const CustomButton = ({
  classes, isInternalLinkButton, isExternalLinkButton,
  linkPath, children, onClick,
  className, disabled, size, primary,
  ...rest
}) => (
  <>
    {!isInternalLinkButton && !isExternalLinkButton && (
      <Button
        className={classNames(
          classes.button,
          getSizeClass(size),
          primary ? 'primary' : 'secondary',
          className
        )}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Button>
    )}
    {isInternalLinkButton && (
      <Link
        className={classNames(
          classes.linkButton,
          getSizeClass(size),
          primary ? 'primary' : 'secondary',
          className
        )}
        to={linkPath}
        {...rest}
      >
        {children}
      </Link>
    )}
    {isExternalLinkButton && (
      <a
        className={classNames(
          classes.linkButton,
          getSizeClass(size),
          primary ? 'primary' : 'secondary',
          className
        )}
        href={linkPath}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    )}
  </>
);

CustomButton.defaultProps = {
  className: '',
  disabled: false,
  isExternalLinkButton: false,
  isInternalLinkButton: false,
  linkPath: '',
  onClick: () => {},
  primary: true,
  size: 'medium'
};

CustomButton.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isExternalLinkButton: PropTypes.bool,
  isInternalLinkButton: PropTypes.bool,
  linkPath: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  size: PropTypes.string
};

export default
withStyles(styles)(
  CustomButton
);
