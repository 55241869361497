/**
 * Get the name of a media input device.
 * @param {Array} devices - a collection of all devices.
 * @param {string} deviceType - the type of device (e.g., audioinput, videoinput, etc.).
 * @param {*} deviceId - the device ID.
 * @returns {string} - the name of the device.
 */
export const getMediaDeviceName = (devices, deviceType, deviceId) => {
  if (devices && devices.length && deviceType && deviceId) {
    const device = devices.find((d) => d.kind === deviceType && d.deviceId === deviceId);
    return device.label || null;
  }

  return null;
};
