import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import CustomImage from './CustomImage';
import CustomButton from '../components/CustomButton';
import { showSettingsDialog as showSettingsDialogAction } from '../actions/settings';
import { showLoginDialog as showLoginDialogAction } from '../actions/login-dialog';
import LoginDialogTab from '../enums/login-dialog-tab';
import { SAVE_ACCOUNT_BUTTON } from '../enums/element-id';

const styles = () => ({
  links: {
    display: 'flex'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 25,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    marginRight: 8
  },
  text: {
    fontSize: 20,
    textTransform: 'capitalize',
    fontWeight: 100,
    color: '#d9d9d9'
  },
  button: {
    width: 100
  }
});

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  showSettingsDialog: (showDialog) => dispatch(showSettingsDialogAction(showDialog)),
  showLoginDialog: (tab) => dispatch(showLoginDialogAction(tab))
});

const FeaturedNavigation = ({
  className, classes, user,
  showSettingsDialog, showLoginDialog
}) => {
  const handleSaveGuestAccount = () => {
    showLoginDialog(LoginDialogTab.REGISTER);
  };

  return (
    <div className={className}>
      {user.isAuthenticated ? (
        <div className={classes.links}>
          <div
            className={classes.link}
            onClick={() => showSettingsDialog(true)}
          >
            <CustomImage
              alt="settings icon"
              className={classes.icon}
              src="icons/settings.svg"
            />
            <span className={classes.text}>settings</span>
          </div>
          {user.isGuest ? (
            <Link // eslint-disable-line
              className={classes.link}
              id={SAVE_ACCOUNT_BUTTON}
              onClick={handleSaveGuestAccount}
            >
              <CustomImage
                alt="account icon"
                className={classes.icon}
                src="icons/account.svg"
              />
              <span className={classes.text}>save account</span>
            </Link>
          ) : (
            <Link
              className={classes.link}
              to="/account"
            >
              <CustomImage
                alt="account icon"
                className={classes.icon}
                src="icons/account.svg"
              />
              <span className={classes.text}>account</span>
            </Link>
          )}
        </div>
      ) : (
        <div className={classes.buttons}>
          <CustomButton
            className={classes.button}
            onClick={() => showLoginDialog(LoginDialogTab.LOGIN)}
            primary={false}
            size="small"
          >
            log in
          </CustomButton>
          <CustomButton
            className={classes.button}
            onClick={() => showLoginDialog(LoginDialogTab.REGISTER)}
            size="small"
          >
            sign up
          </CustomButton>
        </div>
      )}
    </div>
  );
};

FeaturedNavigation.defaultProps = {
  className: ''
};

FeaturedNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  platform: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    isPokerActive: PropTypes.bool.isRequired
  }).isRequired,
  showLoginDialog: PropTypes.func.isRequired,
  showSettingsDialog: PropTypes.func.isRequired,
  user: PropTypes.shape({
    hasRequestedRegistration: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isGuest: PropTypes.bool.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    FeaturedNavigation
  )
);
