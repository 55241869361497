import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import GetStartedBullets from './GetStartedBullets';

const styles = () => ({
  gameInfoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 15px 70px 15px',
    backgroundColor: '#121214',
    fontSize: 16,
    border: 'solid 1px rgba(255, 255, 255, 0.1)',
    borderRadius: 2,
    color: '#d9d9d9',
    opacity: 0.7,
    lineHeight: 1.5,
    boxSizing: 'border-box',
    margin: '5px 15px 50px 15px',
    '& > p': {
      marginTop: 0
    }
  },
  innerWrapper: {
    textAlign: 'center',
    maxWidth: 1200
  },
  getStartedBullets: {
    padding: '50px 0'
  }
});

const MediaPlatformGameInfo = ({
  className, classes, game, style
}) => (
  <div
    className={classNames(classes.gameInfoWrapper, className)}
    style={style}
  >
    <div className={classes.innerWrapper}>
      <GetStartedBullets
        className={classes.getStartedBullets}
        game={game}
        useBackgroundStyle={false}
        useDarkNumbers
        useSolidColor
      />
      <p>{game.shortDescription}</p>
      <p>{game.longDescription}</p>
    </div>
  </div>
);

MediaPlatformGameInfo.defaultProps = {
  className: '',
  style: {}
};

MediaPlatformGameInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  game: PropTypes.shape({
    longDescription: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired
  }).isRequired,
  style: PropTypes.object
};

export default
withStyles(styles)(
  MediaPlatformGameInfo
);
