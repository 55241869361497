import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT, ACTION_BUTTON
} from '../styles/global';
import CustomButton from './CustomButton';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  image: {
    marginBottom: 35
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  viewCatalogButton: {
    ...ACTION_BUTTON()
  }
});

const RegisterSuccess = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>Welcome to LGN!</p>
    <CustomImage
      className={classes.image}
      src="/logo-bubble.png"
    />
    <p className={classes.text}>
      Now that you&apos;re part of the Live Game Night community,
      you have access to live gaming with friends in any game on our platform.
    </p>
    <p className={classes.text}>
      How about checking out our game catalog?
    </p>
    <CustomButton
      className={classes.viewCatalogButton}
      isInternalLinkButton
      linkPath="/games"
    >
      view game catalog
    </CustomButton>
  </div>
);

RegisterSuccess.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  RegisterSuccess
);
