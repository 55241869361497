import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_SUB_HEADER,
  CONTENT_TEXT, FOCUSED_SECTION
} from '../styles/global';
import CustomButton from '../components/CustomButton';
import {
  logout as logoutAction,
  loginGuest as loginGuestAction
} from '../actions/user';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getServiceError } from '../lib/http';
import { getSuccessMessage } from '../lib/string';
import useLogin from '../hooks/useLogin';
import LoginRequired from './LoginRequired';
import { GUEST_USER_ID } from '../enums/local-storage';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  subheader: {
    ...CONTENT_SUB_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme),
    textAlign: 'left'
  },
  focusedSection: {
    ...FOCUSED_SECTION(theme),
    marginBottom: 35,

  }
});

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  loginGuest: (guestUserId) => dispatch(loginGuestAction(guestUserId))
});

const Account = ({
  classes, user, logout,
  history, showGlobalSnackbar, loginGuest
}) => {
  useLogin();

  const handleLogoutSuccess = () => {
    showGlobalSnackbar(getSuccessMessage('You have successfully logged out.'));
    history.push('/games');
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        const storedGuestUserId = localStorage.getItem(GUEST_USER_ID);

        // If a guest user was previously created, log them back in after a
        // registered user logout.
        if (storedGuestUserId) {
          loginGuest(storedGuestUserId).finally(() => handleLogoutSuccess());
        } else {
          handleLogoutSuccess();
        }
      })
      .catch((error) => getServiceError(error));
  };

  if (!user.isAuthenticated) {
    return (<LoginRequired />);
  }

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>You are logged into Live Game Night</p>
      {user.username && user.email && (
        <div className={classes.focusedSection}>
          <p className={classes.subheader}>User Details</p>
          <p className={classes.text}>Username: {user.username}</p>
          <p className={classes.text}>Email: {user.email}</p>
        </div>
      )}
      <CustomButton onClick={handleLogout}>
        Logout
      </CustomButton>
    </div>
  );
};

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  loginGuest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
      Account
    )
  )
);
