import { useEffect } from 'react';

const useKeyup = (key, onKeyup) => {
  useEffect(() => {
    const handleKeyup = (event) => {
      const { keyCode } = event;

      if (keyCode === key) {
        onKeyup();
      }
    };

    document.addEventListener('keyup', handleKeyup);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, []);
};

export default useKeyup;
