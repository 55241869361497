import { SendTrackingEvent, SendTrackingEventComplete, SendTrackingEventError } from '../enums/action-type';
import { customFetch } from '../lib/http';
import { getCurrentBrowserName } from '../lib/browser';

export const sendTrackingEvent = (event, detail) => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      dispatch({ type: SendTrackingEvent });

      const browserName = getCurrentBrowserName();
      const params = new URLSearchParams(window.location.search);
      const { host } = getState();
      const gameId = 1;
      const network = params.get('network');
      let url = `${host}kitsune/portalTracking?game_id=${gameId}&event=${encodeURIComponent(event)}&user_agent=${browserName}`;

      if (network) {
        url = `${url}&source_url=${network}`;
      }

      if (detail) {
        url = `${url}&detail=${encodeURIComponent(detail)}`;
      }

      fetch(url, { method: 'POST' })
        .then((response) => {
          if (response.ok && response.status === 200) {
            dispatch({ type: SendTrackingEventComplete });
            resolve();
          } else {
            dispatch({ type: SendTrackingEventError });
            reject();
          }
        })
        .catch((error) => {
          dispatch({ type: SendTrackingEventError });
          reject(error);
        });
    })
  )
);
