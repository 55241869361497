import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import classNames from 'classnames';
import {
  withStyles, TextField, CircularProgress, Tooltip
} from '@material-ui/core';
import CustomImage from '../containers/CustomImage';
import { TEXT_COLOR } from '../styles/palette';
import { CHAT_COLLAPSED_WIDTH, CHAT_EXPANDED_WIDTH } from '../styles/global';
import CustomButton from './CustomButton';
import { getErrorMessage } from '../lib/string';
import { ENTER } from '../enums/keyboard';
import { MEDIA_PLATFORM_CHAT_INPUT } from '../enums/element-id';

const EXPAND_CHAT_BUTTON_HEIGHT = 45;
const MAX_MESSAGE_LENGTH = 128;
const MESSAGES_PADDING = 5;
const colors = ['#7DD4A1', '#FF532C', '#2CA1FF', '#6FFF2C', '#2CE4FF', '#D62CFF', '#FFA72C', '#3DFF2C', '#FFF82C', '#C7E3E0', '#90AE02', '#02AE93', '#E0DC16', '#16E0D9', '#8BF5F0', '#DB8BF5', '#8BC6F5', '#F58B95', '#F6D5D8', '#EFF6D5'];

const styles = () => ({
  wrapper: {
    width: CHAT_COLLAPSED_WIDTH,
    backgroundColor: '#1f1f23',
    '&.expanded': {
      width: CHAT_EXPANDED_WIDTH
    }
  },
  expandChatButton: {
    cursor: 'pointer',
    height: EXPAND_CHAT_BUTTON_HEIGHT,
    border: 0,
    borderTop: 'solid 1px #070708',
    borderBottom: 'solid 1px #070708',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#18181b',
    width: '100%',
    '&.expanded': {
      justifyContent: 'flex-start',
      paddingLeft: 15
    }
  },
  chatExpandIcon: {
    width: 18,
    height: 17,
    '&.expanded': {
      transform: 'rotate(180deg)'
    }
  },
  expandButtonText: {
    textTransform: 'capitalize',
    color: TEXT_COLOR,
    marginLeft: 25
  },
  notificationsWrapper: {
    display: 'flex',
    height: EXPAND_CHAT_BUTTON_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  notificationsIcon: {
    width: 30,
    height: 28,
  },
  notificationCount: {
    fontSize: 12,
    position: 'absolute',
    marginTop: -4
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${EXPAND_CHAT_BUTTON_HEIGHT}px)`
  },
  messagesWrapper: {
    flex: 1,
    padding: MESSAGES_PADDING,
    margin: MESSAGES_PADDING,
    overflow: 'auto'
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  messagePlayerName: {
    fontWeight: 'bold',
    marginBottom: 0
  },
  messageText: {
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
    marginTop: 8,
    lineHeight: 1.3
  },
  newMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: MESSAGES_PADDING,
    paddingTop: 0
  },
  newMessageTextField: {
  },
  newMessageInput: {
    width: '100%',
    marginBottom: MESSAGES_PADDING
  },
  newMessageOverflow: {
    color: 'red'
  },
  sendMessageButton: {
    width: '100%'
  },
  initializationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column'
  },
  initializationText: {
    fontSize: 18
  },
  initializationProgressSpinner: {
    color: '#6CAAFF'
  }
});

const MediaPlatformChat = ({
  onOpenStateChange, classes, isExpanded, game, showGlobalSnackbar, playerProfiles
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  // Track profiles in a local map so if a player leaves, their profile data needed to
  // display their historical chats won't disappear with them.
  const [playerProfileMap, setPlayerProfileMap] = useState({});
  const [lastCheckedMessageIndex, setLastCheckedMessageIndex] = useState(0);

  const messagesRef = useCallback((node) => {
    if (node !== null && chatHistory.length) {
      node.scrollTop = node.scrollHeight; // eslint-disable-line
    }
  }, [chatHistory]);

  const handleSendMessage = () => {
    if (newMessage.length > MAX_MESSAGE_LENGTH) {
      return;
    }

    window.Kitsune.sendRoomChat(newMessage);
    setNewMessage('');
  };

  const hasNewMessages = () => ((chatHistory.length - lastCheckedMessageIndex) > 0);

  const getNewMessageCount = () => {
    const newMessageCount = chatHistory.length - lastCheckedMessageIndex;

    if (newMessageCount > 99) {
      return '99+';
    }

    return newMessageCount;
  };

  useEffect(() => {
    const handleKeyup = (event) => {
      const { keyCode } = event;

      if ((keyCode === ENTER) && newMessage) {
        handleSendMessage();
      }
    };

    document.addEventListener('keyup', handleKeyup);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [isExpanded, newMessage]);

  // Initialize the game chat with the Kitsune library and subscribe to chat events.
  useEffect(() => {
    const handleNewMessage = (err, message) => {
      if (err) {
        showGlobalSnackbar(getErrorMessage(JSON.stringify(err)));
      } else {
        const chatHistoryClone = cloneDeep(chatHistory);
        const messageClone = cloneDeep(message);

        messageClone.dateCreated = new Date();
        chatHistoryClone.push(messageClone);

        // Update the chat history, but don't let it grow to over 1,000 entries
        // to prevent performance issues.
        setChatHistory(
          chatHistoryClone.length > 1000
            ? chatHistoryClone.slice(1)
            : chatHistoryClone
        );
      }
    };

    window.Kitsune.changeRoom(game.id)
      .then(() => window.Kitsune.subscribe(window.Kitsune.ChatEvent.ROOM_CHAT, handleNewMessage))
      .catch((error) => showGlobalSnackbar(getErrorMessage(error.message)));

    return () => {
      window.Kitsune.unsubscribe(window.Kitsune.ChatEvent.ROOM_CHAT, handleNewMessage);
    };
  }, [chatHistory]);

  useEffect(() => {
    if (isExpanded) {
      setLastCheckedMessageIndex(chatHistory.length);
    }
  }, [isExpanded, chatHistory]);

  useEffect(() => {
    if (playerProfiles.length) {
      const playerProfileMapClone = cloneDeep(playerProfileMap);

      playerProfiles.forEach((playerProfile) => {
        if (!playerProfileMapClone[playerProfile.userID]) {
          playerProfileMapClone[playerProfile.userID] = {
            ...playerProfile,
            color: colors[Math.trunc(Math.random() * colors.length)]
          };
        }
      });

      setPlayerProfileMap(playerProfileMapClone);
    }
  }, [playerProfiles]);

  return (
    <div className={classNames(classes.wrapper, isExpanded && 'expanded')}>
      <button
        className={classNames(classes.expandChatButton, isExpanded && 'expanded')}
        onClick={onOpenStateChange}
        type="button"
      >
        <CustomImage
          className={classNames(classes.chatExpandIcon, isExpanded && 'expanded')}
          src="/icons/chat_expander.svg"
        />
        {isExpanded && (
          <p className={classes.expandButtonText}>game chat</p>
        )}
      </button>
      {!isExpanded && hasNewMessages() && (
        <div className={classes.notificationsWrapper}>
          <CustomImage
            className={classes.notificationsIcon}
            src="/icons/chat_notification.svg"
          />
          <span className={classes.notificationCount}>{getNewMessageCount()}</span>
        </div>
      )}
      {isExpanded && (
        <div className={classes.innerWrapper}>
          {!isEmpty(playerProfileMap) ? (
            <>
              <div
                ref={messagesRef}
                className={classes.messagesWrapper}
              >
                {chatHistory.length > 0 ? (
                  chatHistory.map((chat) => {
                    const player = playerProfileMap[chat.fromUserID];

                    return (
                      <Tooltip title={chat.dateCreated.toLocaleString()}>
                        <div className={classes.messageWrapper}>
                          <p
                            className={classes.messagePlayerName}
                            style={{ color: player.color }}
                          >
                            <span>{player.name}</span>
                          </p>
                          <p className={classes.messageText}>
                            <span>{chat.text}</span>
                          </p>
                        </div>
                      </Tooltip>
                    );
                  })
                ) : (
                  <p>No new chat messages.</p>
                )}
              </div>
              <div className={classes.newMessageWrapper}>
                <TextField
                  autoFocus
                  className={classes.newMessageTextField}
                  id={MEDIA_PLATFORM_CHAT_INPUT}
                  InputLabelProps={{
                    style: { color: newMessage.length > MAX_MESSAGE_LENGTH ? 'red' : 'white' }
                  }}
                  InputProps={{
                    className: classes.newMessageInput
                  }}
                  label={`New Message (${newMessage.length} / ${MAX_MESSAGE_LENGTH.toString()})`}
                  multiline
                  // Remove carriage breaks from the text so a space isn't inserted
                  // in the text field when enter is used to submit the message.
                  // We could just remove multiline to make this work, but then the user's
                  // text wouldn't wrap during typing, making it hard to view the entire message.
                  onChange={(event) => setNewMessage(event.target.value.replace('\n', ''))}
                  value={newMessage}
                  variant="filled"
                />
                <CustomButton
                  className={classes.sendMessageButton}
                  disabled={newMessage === '' || newMessage.length > MAX_MESSAGE_LENGTH}
                  onClick={handleSendMessage}
                >
                  send
                </CustomButton>
              </div>
            </>
          ) : (
            <div className={classes.initializationWrapper}>
              <p className={classes.initializationText}>Initializing chat...</p>
              <CircularProgress
                className={classes.initializationProgressSpinner}
                size={28}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MediaPlatformChat.propTypes = {
  classes: PropTypes.object.isRequired,
  game: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onOpenStateChange: PropTypes.func.isRequired,
  playerProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  MediaPlatformChat
);
