import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import CustomButton from '../components/CustomButton';
import { FORM_WRAPPER } from '../styles/global';
import AgeVerificationCheckbox from '../components/AgeVerificationCheckbox';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import { getErrorMessage } from '../lib/string';
import { getServiceError } from '../lib/http';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import {
  logout as logoutAction,
  createGuestAccount as createGuestAccountAction,
  loginGuest as loginGuestAction
} from '../actions/user';
import { hideLoginDialog as hideLoginDialogAction } from '../actions/login-dialog';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const styles = (theme) => ({
  wrapper: {
    ...FORM_WRAPPER(theme)
  },
  topMessage: {
    textAlign: 'center'
  }
});

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  logout: () => dispatch(logoutAction()),
  createGuestAccount: () => dispatch(createGuestAccountAction()),
  loginGuest: (userId) => dispatch(loginGuestAction(userId)),
  hideLoginDialog: () => dispatch(hideLoginDialogAction()),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const PlayAsGuest = ({
  classes, className, showGlobalSnackbar, logout,
  createGuestAccount, loginGuest, hideLoginDialog,
  user, sendTrackingEvent
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const [hasAgeError, setHasAgeError] = useState(false);
  const location = useLocation();

  const handlePlayAsGuestClick = () => {
    if (!ageVerified) {
      setHasAgeError(!ageVerified);
      showGlobalSnackbar(getErrorMessage('Age verification incomplete.'));
      return;
    }

    sendTrackingEvent(TrackingEvent.GUEST_PLAY_CLICK, location.pathname);

    setIsLoading(true);

    logout()
      .then(() => createGuestAccount())
      .then((guestUserData) => loginGuest(guestUserData.userID))
      .then(() => hideLoginDialog())
      .catch((error) => {
        sendTrackingEvent(TrackingEvent.GUEST_PLAY_ERROR, error.message);
        showGlobalSnackbar(getServiceError(error));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={classNames(classes.wrapper, className)}>
      {user.isGuest ? (
        <p>You are already logged in as a guest.</p>
      ) : (
        <>
          <AgeVerificationCheckbox
            checked={ageVerified}
            hasError={hasAgeError}
            onChange={setAgeVerified}
          />
          <CustomButton
            onClick={handlePlayAsGuestClick}
            size="large"
          >
            Play as Guest
          </CustomButton>
        </>
      )}
      {isLoading && (
        <GlobalProgressSpinner />
      )}
    </div>
  );
};

PlayAsGuest.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  createGuestAccount: PropTypes.func.isRequired,
  hideLoginDialog: PropTypes.func.isRequired,
  loginGuest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isGuest: PropTypes.bool.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    PlayAsGuest
  )
);
