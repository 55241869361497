import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import CustomButton from './CustomButton';
import { isMobileDevice, isIOS, isAndroid } from '../lib/browser';
import {
  HOME_WRAPPER, HOME_HEADER, HOME_VIDEO,
  HOME_CTA_BUTTON, HOME_SUMMARY_WRAPPER, HOME_SUMMARY,
  HOME_SUMMARY_HEADER, HOME_SUMMARY_TEXT, HOME_GAME_WRAPPER
} from '../styles/global';
import AnnouncementBanner from './AnnouncementBanner';
import { setLoginDialogTab as setLoginDialogTabAction } from '../actions/login-dialog';
import GlobalProgressSpinner from './GlobalProgressSpinner';
import AppStoreButton from './AppStoreButton';
import PlayStoreButton from './PlayStoreButton';
import CustomImage from '../containers/CustomImage';
import GetStartedBullets from './GetStartedBullets';
import useGames from '../hooks/useGames';
import LoginDialogTab from '../enums/login-dialog-tab';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';
import { isImage } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...HOME_WRAPPER()
  },
  header: {
    ...HOME_HEADER(theme)
  },
  gameWrapper: {
    ...HOME_GAME_WRAPPER()
  },
  logo: {
    height: 75
  },
  media: {
    ...HOME_VIDEO(theme)
  },
  ctaButton: {
    ...HOME_CTA_BUTTON(theme),
    animation: '$grow 5000ms forwards'
  },
  summaryWrapper: {
    ...HOME_SUMMARY_WRAPPER(theme)
  },
  summary: {
    ...HOME_SUMMARY(theme)
  },
  summaryHeader: {
    ...HOME_SUMMARY_HEADER(theme)
  },
  summaryText: {
    ...HOME_SUMMARY_TEXT(theme)
  },
  mobileComingSoon: {
    margin: 0
  },
  '@keyframes grow': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(1.1)'
    }
  },
  illustriousImage: {
    width: 'auto'
  }
});

const mapStateToProps = (state) => ({
  games: state.games,
  config: state.config
});

const mapDispatchToProps = (dispatch) => ({
  setLoginDialogTab: (tab) => dispatch(setLoginDialogTabAction(tab)),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const GameHome = ({
  classes, games, config,
  setLoginDialogTab, sendTrackingEvent
}) => {
  const [game, setGame] = useState(null);
  const { search } = window.location;
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  let ctaURL = params.slug === 'poker' ? `/games/${params.slug}` : `/games/${params.slug}/join`;

  if (search) {
    ctaURL = `${ctaURL}?${search.substring(1)}`;
  }

  useGames();

  useEffect(() => {
    if (games.length) {
      const gameData = games.find((g) => g.slug === params.slug);

      if (gameData) {
        setGame(gameData);
      } else {
        history.push('/invalid-game');
      }
    }
  }, [games, location]);

  useEffect(() => {
    // Make sure the user sees the top of the UI on load.
    window.scrollTo(0, 0);
  }, []);

  const handleCtaClick = () => {
    setLoginDialogTab(LoginDialogTab.GUEST);
    sendTrackingEvent(TrackingEvent.GAME_CTA_CLICK, location.pathname);
    history.push(ctaURL);
  };

  const getCallToAction = () => {
    if (isMobileDevice()) {
      if (game.slug === 'poker') {
        if (isIOS()) {
          return (
            <AppStoreButton
              className={classNames(classes.ctaButton, classes.mobileButton)}
              imageClassName={classes.mobileButton}
            />
          );
        }

        if (isAndroid()) {
          return (
            <PlayStoreButton
              className={classNames(classes.ctaButton, classes.mobileButton)}
              imageClassName={classes.mobileButton}
            />
          );
        }
      } else {
        return (
          <AnnouncementBanner
            className={classes.mobileComingSoon}
            style={{ backgroundColor: game.colors[0] }}
            text="Coming soon to iOS and Android!"
          />
        );
      }
    } else {
      return (
        <CustomButton
          className={classNames(
            classes.ctaButton,
            'cta-button'
          )}
          onClick={handleCtaClick}
          size="large"
          style={{ backgroundColor: 'rgba(34, 126, 22, 1)' }}
        >
          play now!
        </CustomButton>
      );
    }

    return (<></>);
  };

  if (!game) {
    return (<GlobalProgressSpinner />);
  }

  const mediaUrl = game.media.videos[0];

  return (
    <div>
      <Helmet>
        <meta
          content={`Learn more about live, virtual gaming with friends with ${game.name}!`}
          name="description"
        />
        <title>{`${game.name} - Home`}</title>
        <link
          href={`${location.host}${location.path}`}
          rel="canonical"
        />
      </Helmet>
      <div
        className={classes.header}
        style={{ backgroundColor: game.colors[0] }}
      >
        <CustomImage
          alt={`${game.name} Logo`}
          className={classes.logo}
          src={game.media.logo}
        />
      </div>
      <div className={classes.gameWrapper}>
        {isImage(mediaUrl) ? (
          <img
            alt="gameplay"
            className={classes.media}
            src={`${config.mediaPath}${mediaUrl}`}
          />
        ) : (
          <video
            autoPlay
            className={classes.media}
            controls
            muted
            poster={`${process.env.PUBLIC_URL}/images/${game.slug}-poster.png`}
            src={`${config.mediaPath}${mediaUrl}`}
          />
        )}
        {getCallToAction()}
      </div>
      <GetStartedBullets game={game} />
      <div
        className={classes.summaryWrapper}
        style={{ backgroundColor: game.colors[0] }}
      >
        <div className={classes.summary}>
          <h1 className={classes.summaryHeader}>Virtual Happy Hour – With Your Buddies</h1>
          <p className={classes.summaryText}>
            {game.shortDescription}
          </p>
        </div>
        <div className={classes.summary}>
          <h1 className={classes.summaryHeader}>Get Started Free</h1>
          <p className={classes.summaryText}>
            {game.longDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

GameHome.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.shape({
    mediaPath: PropTypes.string.isRequired
  }).isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      colors: PropTypes.arrayOf(
        PropTypes.string.isRequired
      ).isRequired,
      media: {
        logo: PropTypes.string.isRequired,
        videos: PropTypes.arrayOf(
          PropTypes.string.isRequired
        ).isRequired
      },
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
  setLoginDialogTab: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    GameHome
  )
);
