import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomButton from './CustomButton';
import CustomImage from '../containers/CustomImage';

const styles = () => ({
  icon: {
    marginRight: 8
  }
});

const FullscreenButton = ({
  classes, className, onClick, text, ...props
}) => (
  <CustomButton
    className={className}
    onClick={onClick}
    size="small"
    {...props}
  >
    <CustomImage
      className={classes.icon}
      src="/icons/full_screen.svg"
    />
    {text}
  </CustomButton>
);

FullscreenButton.defaultProps = {
  className: '',
  text: 'Fullscreen'
};

FullscreenButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string
};

export default
withStyles(styles)(
  FullscreenButton
);
