import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INNER_CONTENT_WRAPPER
} from '../styles/global';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'initial'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme)
  }
});

const ZoomUserGuideFAQ = ({ classes }) => (
  <section
    className={classes.innerWrapper}
    id="faq"
  >
    <p className={classes.header}>Frequently Asked Questions</p>
    <p className={classes.text}>
      Please visit the&nbsp;
      <a
        href="https://play.livegamenight.com/faq"
        rel="noopener noreferrer"
        target="_blank"
      >
        LGN FAQ
      </a>
      &nbsp;to learn more about Live Game Night games and
      find answers to commonly asked questions.
    </p>
  </section>
);

ZoomUserGuideFAQ.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideFAQ
);
