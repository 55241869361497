import { GetGames, GetGamesComplete, GetGamesError } from '../enums/action-type';

const gameData = [
  {
    id: 7,
    clientPath: '/fx/lgn/client/games/7/html/index.html',
    name: 'LGN Poker',
    slug: 'poker',
    media: {
      logo: 'lgn-poker-logo.png',
      images: ['lgn-poker-splash.png'],
      videos: ['poker-gameplay.mp4']
    },
    colors: ['#0c134e', '#08080c'],
    tagline: '',
    shortDescription: 'Play Texas Hold ’em poker!',
    longDescription: 'Missing poker nights with your friends? Live Game Night (LGN) Poker is an online Texas Hold’em poker game designed to be played live with your buddies. Think of it as an online version of a poker night in your home. Play poker, chat, joke, bluff, and even read your friend\'s tells, just like playing in person.',
    status: 'Released'
  },
  {
    id: 11,
    clientPath: '/fx/lgn/client/games/11/html/index.html',
    name: 'LGN Dice',
    slug: 'dice',
    media: {
      logo: 'lgn-dice-logo.png',
      images: ['lgn-dice-splash.png'],
      videos: ['lgn-dice-trailer.mp4']
    },
    colors: ['rgba(223, 171, 12, 1)', 'rgba(82, 8, 124, 1)'],
    tagline: '',
    shortDescription: 'Dice Dice, Baby!',
    longDescription: 'A fun, classic board game with a brand new look. Play dice with friends in this amazing multiplayer game. Find out why millions have loved this dice game for more than 50 years!  It\'s simple to learn but will provide hours of fun! LGN Dice is the BEST version of the classic dice game!',
    status: 'Released'
  },
  // {
  //   id: 13,
  //   clientPath: '/fx/lgn/client/games/13/html/index.html',
  //   name: 'Boggle',
  //   slug: 'boggle',
  //   media: {
  //     logo: 'lgn-boggle-logo.png',
  //     images: ['boggle-splash.png'],
  //     videos: ['boggle-trailer-optimized.mp4']
  //   },
  //   colors: ['rgba(250, 162, 30, 1)', 'rgba(20, 19, 20, 1)'],
  //   tagline: '',
  //   shortDescription: 'The classic word game, online, with friends over video.',
  //   longDescription: 'Boggle is a one-of-a-kind, invite-only, REAL word game that can be played with your friends over video and audio conferencing! It is an exciting, innovative, interactive game which provides the player with a true word game experience unlike anywhere else--you will feel like you are sitting at a Boggle table game with your friends. All you need to bring are your own snacks!',
  //   status: 'Released'
  // },
  {
    id: 15,
    clientPath: '/fx/lgn/client/games/15/html/index.html',
    name: 'LGN Illustrious',
    slug: 'illustrious',
    media: {
      logo: 'lgn-illustrious-logo.png',
      images: ['lgn-illustrious-splash.png', 'lgn-illustrious-gameplay-screenshot.png'],
      videos: ['illustrious-trailer-optimized.mp4']
    },
    colors: ['rgba(96, 195, 236, 1)', 'rgba(255, 117, 0, 1)'],
    tagline: '',
    shortDescription: 'Guess what your friends are drawing before time runs out!',
    longDescription: 'LGN Illustrious is a hilarious new addition to your weekly game night that lets you draw with friends and see their reactions over video! You can team up or play against friends & family in a drawing showdown. Take turns drawing & guessing doodles or masterpieces — artistic ability is not required. Guess the clue before time runs out!',
    status: 'Released'
  },
  {
    id: 18,
    clientPath: '/fx/lgn/client/games/18/html/index.html',
    name: 'Bet I Know That',
    slug: 'trivia',
    media: {
      logo: 'lgn-trivia-logo.png',
      images: ['lgn-trivia-splash.png'],
      videos: ['lgn-trivia-screenshot.jpg']
    },
    colors: ['rgba(81, 158, 246, 1)', 'rgba(5, 20, 33, 1)'],
    tagline: '',
    shortDescription: 'Your new trivia night.',
    longDescription: 'Can’t make it out to your local trivia night? With Bet I Know That by LGN, you don’t need to go anywhere to engage friends face to face over live video for some friendly rounds of trivia. With constantly updated categories, you’ll be challenging your friends more than ever with better trivia questions without ever having to leave your house. Stay at home while staying on top of your trivia game!',
    status: 'Released'
  },
];

export const getGames = () => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      const { host } = getState();

      dispatch({ type: GetGames });

      if (!host) {
        dispatch({ type: GetGamesError });
        reject(new Error('The required host is not defined in actions/games/getGames'));
      }

      dispatch({ type: GetGamesComplete, games: gameData });
      resolve(gameData);
    })
  )
);
