import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { CONTENT_WRAPPER, CONTENT_TEXT } from '../styles/global';
import { showLoginDialog as showLoginDialogAction } from '../actions/login-dialog';
import CustomImage from './CustomImage';
import LoginDialogTab from '../enums/login-dialog-tab';
import CustomButton from '../components/CustomButton';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    boxSizing: 'border-box'
  },
  text: {
    ...CONTENT_TEXT(theme),
    marginTop: 35
  },
  logo: {
    width: 400
  },
  nonGuestButton: {
    width: 200
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  showLoginDialog: (tab) => dispatch(showLoginDialogAction(tab))
});

const LoginRequired = ({ classes, showLoginDialog, game }) => (
  <div className={classes.wrapper}>
    {game && game.media && game.media.logo && (
      <CustomImage
        className={classes.logo}
        src={game.media.logo}
      />
    )}
    <p className={classes.text}>
      <p className={classes.text}>Please create a guest account, login, or register to continue.</p>
      <CustomButton
        onClick={() => showLoginDialog(LoginDialogTab.GUEST)}
        size="large"
      >
        Continue as Guest
      </CustomButton>
      <p className={classes.text}>
        Already have an account or want to register a new one?
      </p>
      <div>
        <CustomButton
          className={classes.nonGuestButton}
          onClick={() => showLoginDialog(LoginDialogTab.LOGIN)}
        >
          Login
        </CustomButton>
        <CustomButton
          className={classes.nonGuestButton}
          onClick={() => showLoginDialog(LoginDialogTab.REGISTER)}
        >
          Register
        </CustomButton>
      </div>
    </p>
  </div>
);

LoginRequired.defaultProps = {
  game: null
};

LoginRequired.propTypes = {
  classes: PropTypes.object.isRequired,
  game: PropTypes.shape({
    media: PropTypes.shape({
      logo: PropTypes.string
    })
  }),
  showLoginDialog: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    LoginRequired
  )
);
