import { InitKitsuneAPI, InitKitsuneAPIComplete, InitKitsuneAPIError } from '../enums/action-type';
import { customFetch } from '../lib/http';
import { API_TOKEN } from '../enums/kitsune';
import { DEVICE_ID, HOST } from '../enums/local-storage';

export const initKitsuneAPI = () => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      // config.json comes from the Node server that hosts this client app rather
      // than the Kitsune server, so make sure that it's looking for it at localhost:83
      // rather than the Kitsune server proxy defined in package.json for development.
      const path = window.location.hostname === 'localhost' ? 'http://localhost:83/config.json' : '/config.json';

      dispatch({ type: InitKitsuneAPI });

      customFetch(path)
        .then((configData) => {
          dispatch({ type: InitKitsuneAPIComplete });

          // CHANGE TO GAME ID 1 AND TRY TO CREATE AN ACCOUNT AND LOGIN
          const KitsuneConfig = {
            gameID: 1,
            apiKey: API_TOKEN,
            serverData: configData
          };

          // Initialize the kitsune.js API.
          let deviceID = localStorage.getItem('deviceID');
          deviceID = window.Kitsune.init(
            KitsuneConfig.apiKey, KitsuneConfig.gameID, deviceID, KitsuneConfig.serverData
          );

          localStorage.setItem(DEVICE_ID, deviceID);
          localStorage.setItem(HOST, configData.main);

          resolve(configData);
        })
        .catch((error) => {
          dispatch({ type: InitKitsuneAPIError });
          reject(error);
        });
    })
  )
);
