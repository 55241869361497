import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { SAVE_ACCOUNT_BUTTON, LOGIN_BUTTON } from '../enums/element-id';

const LeaveGameWarning = ({ message }) => {
  // Present the user with a confirmation dialog when attempting to navigate away from the UI.
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Setting the returnValue property on the event will cause the browser to show a prompt to
      // confirm whether the user wants to navigate away from the page if they try to
      // refresh the browser. Navigating back or forward in the browser will not trigger
      // this prompt. The message is being set explicitly here for the sake of consistency,
      // but most modern browsers will display a default message.
      event.returnValue = message; // eslint-disable-line
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // The React Router Prompt component is used to show a prompt to confirm navigation
  // when the back or forward browser buttons are used.
  // return (<Prompt message={message} />);
  return (
    <Prompt message={() => {
      const activeElementId = document.activeElement.id;

      // Prevent the prompt from appearing if the save account or login button is clicked.
      if (activeElementId !== SAVE_ACCOUNT_BUTTON && activeElementId !== LOGIN_BUTTON) {
        return message;
      }

      return false;
    }}
    />
  );
};

LeaveGameWarning.defaultProps = {
  message: 'Are you sure you want to leave the game?'
};

LeaveGameWarning.propTypes = {
  message: PropTypes.string
};

export default LeaveGameWarning;
