import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withStyles, TextField } from '@material-ui/core';
import Hashes from 'jshashes';
import {
  FORM_WRAPPER, FORM, CONTENT_HEADER
} from '../styles/global';
import {
  login as loginAction,
  getPlayerProfile as getPlayerProfileAction
} from '../actions/user';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { ENTER } from '../enums/keyboard';
import { API_TOKEN } from '../enums/kitsune';
import CustomButton from '../components/CustomButton';
import GlobalPrgressSpinner from '../components/GlobalProgressSpinner';
import { getServiceError } from '../lib/http';
import { hideLoginDialog as hideLoginDialogAction } from '../actions/login-dialog';
import { getErrorMessage, getSuccessMessage } from '../lib/string';
import { LOGIN_BUTTON } from '../enums/element-id';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const styles = (theme) => ({
  wrapper: {
    ...FORM_WRAPPER(theme),
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  form: {
    ...FORM(theme)
  },
  link: {
    marginTop: 25,
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  loginDialog: state.loginDialog,
  platform: state.platform
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(loginAction(email, password)),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  getPlayerProfile: (userID) => dispatch(getPlayerProfileAction(userID)),
  hideLoginDialog: () => dispatch(hideLoginDialogAction()),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const Login = ({
  classes, className, login,
  showGlobalSnackbar, getPlayerProfile, user,
  loginDialog, hideLoginDialog, showFormHeader,
  platform, sendTrackingEvent
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const location = useLocation();

  const handleDiscourseRedirect = (userID, userEmail) => {
    // Get the profile so we can pass the user's name to Discourse.
    getPlayerProfile(userID)
      .then((profile) => {
        const SHA256 = new Hashes.SHA256();
        const discourseSignature = decodeURIComponent(params.get('sig'));
        const discourseToken = decodeURIComponent(params.get('sso'));
        const base64DecodedDiscourseToken = atob(discourseToken);
        const discourseNonce = base64DecodedDiscourseToken.split('&')[0].split('=')[1];
        const discourseReturnURL = decodeURIComponent(base64DecodedDiscourseToken.split('&')[1].split('=')[1]);
        const sha256HashedToken = SHA256.hex_hmac(API_TOKEN, discourseToken);

        if (discourseSignature === sha256HashedToken) {
          const unsignedPayload = `nonce=${discourseNonce}&name=${encodeURIComponent(profile.name)}&username=${encodeURIComponent(profile.name)}&email=${encodeURIComponent(userEmail)}&external_id=${encodeURIComponent(profile.userID)}&require_activation=false`;
          const base64EncodedPayload = btoa(unsignedPayload);
          const urlEncodedPayload = encodeURIComponent(base64EncodedPayload);
          const signedPayload = SHA256.hex_hmac(API_TOKEN, urlEncodedPayload);

          window.location.href = `${discourseReturnURL}?sso=${urlEncodedPayload}&sig=${signedPayload}`;
        }
      });
  };

  const handleLogin = () => {
    setHasEmailError(!email);
    setHasPasswordError(!password);

    if (!email || !password) {
      showGlobalSnackbar(getErrorMessage('All fields are required.'));
      return;
    }

    sendTrackingEvent(TrackingEvent.LOGIN_CLICK, location.pathname);

    login(email, password)
      .then(() => {
        hideLoginDialog();
        showGlobalSnackbar(getSuccessMessage('You have successfully logged in.'));

        // If a guest user successfully logs in with an existing account instead of registering,
        // refresh the browser to allow the newly logged in user to join the game.
        if ((platform.isActive || platform.isPokerActive) && user.isGuest) {
          window.location.reload();
        }
      })
      .catch((error) => {
        setPassword('');
        showGlobalSnackbar(getServiceError(error));
      });
  };

  const handleKeyup = (event) => {
    const key = event.keyCode;

    if (key === ENTER) {
      handleLogin();
    }
  };

  const handleForgotPasswordClick = () => {
    if (loginDialog.show) {
      hideLoginDialog();
    }

    history.push('/forgot-password');
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keyup', handleKeyup);
    };
  });

  useEffect(() => {
    // Handle automatic redirects if the user if a returning user is authenticated.
    if (user.isAuthenticated && user.userID) {
      // Handle SSO logins coming from the Discourse forum. For more on Discourse SSO:
      // https://meta.discourse.org/t/official-single-sign-on-for-discourse-sso/13045
      if (window.location.pathname === '/discourse/login') {
        handleDiscourseRedirect(user.userID, user.email);
      }
    }
  }, [user.isAuthenticated, user.userID, user.email]);

  return (
    <div className={classNames(classes.wrapper, className)}>
      {showFormHeader && (
        <p className={classes.header}>Login to Your LGN Account</p>
      )}
      <form className={classes.form}>
        <TextField
          className={classes.input}
          InputProps={{
            className: hasEmailError && 'has-error'
          }}
          label="Email"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          variant="filled"
        />
        <TextField
          className={classes.input}
          InputProps={{
            className: hasPasswordError && 'has-error'
          }}
          label="Password"
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          value={password}
          variant="filled"
        />
        <CustomButton
          id={LOGIN_BUTTON}
          onClick={handleLogin}
        >
          Login
        </CustomButton>
        <Link // eslint-disable-line
          className={classes.link}
          onClick={handleForgotPasswordClick}
        >
          Forgot your password?
        </Link>
      </form>
      {user.isAuthenticating && (
        <GlobalPrgressSpinner />
      )}
    </div>
  );
};

Login.defaultProps = {
  className: '',
  showFormHeader: true
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  getPlayerProfile: PropTypes.func.isRequired,
  hideLoginDialog: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loginDialog: PropTypes.shape({
    show: PropTypes.bool.isRequired
  }).isRequired,
  platform: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    isPokerActive: PropTypes.bool.isRequired
  }).isRequired,
  showFormHeader: PropTypes.bool,
  showGlobalSnackbar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    isGuest: PropTypes.bool.isRequired,
    userID: PropTypes.number
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    Login
  )
);
