import { SetHasLoadedLGNVideoLibrary } from '../enums/action-type';

export default (state = false, action) => {
  switch (action.type) {
    case SetHasLoadedLGNVideoLibrary: {
      return action.hasLoaded;
    }
    default:
      return state;
  }
};
