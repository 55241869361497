import { uniqBy } from 'lodash';
import {
  SetEnableVideoOnJoinGame, SetEnableAudioOnJoinGame, SetVideoEnabled,
  SetAudioEnabled, SetVideoInputDevice, SetVideoInputDevices,
  SetAudioInputDevice, SetAudioInputDevices, SetAudioOutputDevice,
  SetAudioOutputDevices, ShowSettingsDialog, SetHasSeenPlatformSettings,
  SetShowSettingsOnLoad, SetShowSettingsOnLoadComplete, SetIsLoadingDevices
} from '../enums/action-type';
import {
  ENABLE_VIDEO_ON_JOIN_GAME, ENABLE_AUDIO_ON_JOIN_GAME, VIDEO_INPUT_DEVICE,
  AUDIO_INPUT_DEVICE, AUDIO_OUTPUT_DEVICE, HAS_SEEN_PLATFORM_SETTINGS,
  SHOW_SETTINGS_ON_LOAD
} from '../enums/local-storage';

export const setEnableVideoOnJoinGame = (enableVideoOnJoinGame) => {
  localStorage.setItem(ENABLE_VIDEO_ON_JOIN_GAME, enableVideoOnJoinGame);
  return { type: SetEnableVideoOnJoinGame, enableVideoOnJoinGame };
};

export const setEnableAudioOnJoinGame = (enableAudioOnJoinGame) => {
  localStorage.setItem(ENABLE_AUDIO_ON_JOIN_GAME, enableAudioOnJoinGame);
  return { type: SetEnableAudioOnJoinGame, enableAudioOnJoinGame };
};

export const setVideoEnabled = (enableVideo) => {
  window.LGNVideo.enableVideo(enableVideo);
  return { type: SetVideoEnabled, isVideoEnabled: enableVideo };
};

export const setAudioEnabled = (enableAudio) => {
  window.LGNVideo.enableAudio(enableAudio);
  return { type: SetAudioEnabled, isAudioEnabled: enableAudio };
};

const getUniqueDevicesByType = (devices, deviceType) => {
  // Use only unique devices in case the list returned by LGNVideo contains duplicates.
  const inputDeviceList = devices.filter((device) => device.kind === deviceType);
  const uniqueDeviceList = uniqBy(inputDeviceList, (d) => d.deviceId);

  // We don't need the toJSON function, and it's causing a conflict with the
  // Redux dev tool, so delete it from each object.
  uniqueDeviceList.forEach(device => delete device.toJSON); // eslint-disable-line

  return uniqueDeviceList;
};

export const setVideoInputDevice = (deviceId) => {
  localStorage.setItem(VIDEO_INPUT_DEVICE, deviceId);
  return { type: SetVideoInputDevice, deviceId };
};

export const setVideoInputDevices = (devices) => {
  const uniqueVideoInputDevices = getUniqueDevicesByType(devices, 'videoinput');
  return { type: SetVideoInputDevices, devices: uniqueVideoInputDevices };
};

export const setAudioInputDevice = (deviceId) => {
  localStorage.setItem(AUDIO_INPUT_DEVICE, deviceId);
  return { type: SetAudioInputDevice, deviceId };
};

export const setAudioInputDevices = (devices) => {
  const uniqueAudioInputDevices = getUniqueDevicesByType(devices, 'audioinput');
  return { type: SetAudioInputDevices, devices: uniqueAudioInputDevices };
};

export const setAudioOutputDevice = (deviceId) => {
  localStorage.setItem(AUDIO_OUTPUT_DEVICE, deviceId);
  return { type: SetAudioOutputDevice, deviceId };
};

export const setAudioOutputDevices = (devices) => {
  const uniqueAudioOutputDevices = getUniqueDevicesByType(devices, 'audiooutput');
  return { type: SetAudioOutputDevices, devices: uniqueAudioOutputDevices };
};

export const showSettingsDialog = (showDialog) => ({ type: ShowSettingsDialog, showDialog });

export const setHasSeenPlatformSettings = (hasSeenPlatformSettings) => {
  localStorage.setItem(HAS_SEEN_PLATFORM_SETTINGS, hasSeenPlatformSettings);
  return { type: SetHasSeenPlatformSettings, hasSeenPlatformSettings };
};

export const setShowSettingsOnLoad = (showSettingsOnLoad) => {
  localStorage.setItem(SHOW_SETTINGS_ON_LOAD, showSettingsOnLoad);
  return { type: SetShowSettingsOnLoad, showSettingsOnLoad };
};

export const setShowSettingsOnLoadComplete = (showSettingsOnLoadComplete) => (
  { type: SetShowSettingsOnLoadComplete, showSettingsOnLoadComplete }
);

export const setIsLoadingDevices = (isLoadingDevices) => (
  { type: SetIsLoadingDevices, isLoadingDevices }
);
