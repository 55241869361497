import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const AgeVerificationCheckbox = ({ onChange, hasError, checked }) => (
  <FormControlLabel
    control={(
      <Checkbox
        checked={checked}
        className={hasError && 'has-error'}
        onChange={(event) => onChange(event.target.checked)}
      />
    )}
    label="I am at least 18 years old"
  />
);

AgeVerificationCheckbox.defaultProps = {
  hasError: false
};

AgeVerificationCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default AgeVerificationCheckbox;
