import Hashes from 'jshashes';
import {
  UpdateLocalUser, Register, RegisterComplete,
  RegisterError, Login, LoginComplete,
  LoginError, SetNewPassword, SetNewPasswordComplete,
  SetNewPasswordError, SendPasswordResetEmail, SendPasswordResetEmailComplete,
  SendPasswordResetEmailError, Logout, LogoutComplete, LogoutError,
  GetPlayerProfile, GetPlayerProfileComplete, GetPlayerProfileError,
  CreateGuestAccount, CreateGuestAccountComplete, CreateGuestAccountError,
  LoginGuest, LoginGuestComplete, LoginGuestError,
  SaveGuestAccount, SaveGuestAccountComplete, SaveGuestAccountError
} from '../enums/action-type';
import { customFetch } from '../lib/http';
import { getSHA256Hash } from '../lib/string';
import {
  REFERRING_NETWORK, SESSION_ID, USER_ID,
  PASSWORD, GUEST_USER_ID, GUEST_SESSION_ID
} from '../enums/local-storage';

export const updateLocalUser = (user) => (
  { type: UpdateLocalUser, payload: user }
);

export const getPlayerProfile = (userID) => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      const { user } = getState();

      dispatch({ type: GetPlayerProfile });

      window.Kitsune.getPlayerProfile(userID)
        .then((json) => {
          dispatch({ type: GetPlayerProfileComplete });

          dispatch(updateLocalUser({
            ...user,
            username: json.name,
            email: json.email
          }));

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: GetPlayerProfileError });
          reject(error);
        });
    })
  )
);

export const login = (email = '', password = '') => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch({ type: Login });

      const userData = {
        username: '',
        email
      };
      let loginMethod;

      if (email && password) {
        loginMethod = () => window.Kitsune.login(email, password);
      } else {
        // TODO: use the commented code once we get sessionID working with cookies.
        // loginMethod = () => window.Kitsune.loginWithSessionID('');
        loginMethod = () => window.Kitsune.loginWithSessionID(localStorage.getItem(SESSION_ID));
      }

      loginMethod()
        .then((json) => {
          if (json.errors) {
            dispatch({ type: LoginError });
            reject(json);
          } else {
            dispatch({ type: LoginComplete });

            if (!json.sessionID) {
              dispatch({ type: LoginError });
              reject(json);
            } else {
              const { userID, sessionID } = json;
              const passwordHash = getSHA256Hash(password);

              userData.userID = userID;
              userData.sessionID = sessionID;
              userData.isGuest = false;

              localStorage.setItem(SESSION_ID, sessionID);
              localStorage.setItem(USER_ID, userID);
              localStorage.setItem(PASSWORD, passwordHash);

              dispatch(updateLocalUser(userData));
              dispatch(getPlayerProfile(userID));

              resolve(userData);
            }
          }
        })
        .catch((error) => {
          dispatch({ type: LoginError });
          reject(error);
        });
    })
  )
);

export const register = (username, email, password) => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch({ type: Register });

      const referringNetwork = localStorage.getItem(REFERRING_NETWORK) || '';

      window.Kitsune.createAccount(username, email, password, referringNetwork)
        .then((json) => {
          dispatch({ type: RegisterComplete });

          dispatch(updateLocalUser({
            username,
            email,
            userID: json.userID
          }));

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: RegisterError });
          reject(error);
        });
    })
  )
);

export const logout = () => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch({ type: Logout });

      window.Kitsune.logout()
        .then((json) => {
          localStorage.removeItem(SESSION_ID);
          localStorage.removeItem(USER_ID);
          localStorage.removeItem(PASSWORD);

          dispatch({ type: LogoutComplete });

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: LogoutError });
          reject(error);
        });
    })
  )
);

export const sendPasswordResetEmail = (email, game) => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      dispatch({ type: SendPasswordResetEmail });

      const queryString = `?email=${email}&game_id=${game.id}`;

      customFetch(`${getState().host}forgot${queryString}`, {
        method: 'POST'
      })
        .then((response) => {
          dispatch({ type: SendPasswordResetEmailComplete });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: SendPasswordResetEmailError });
          reject(error);
        });
    })
  )
);

export const setNewPassword = (token, newPassword) => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      dispatch({ type: SetNewPassword });

      const SHA1 = new Hashes.SHA1();
      const queryString = `?token=${token}&password=${SHA1.hex(newPassword)}`;

      customFetch(`${getState().host}forgotReset${queryString}`, {
        method: 'POST'
      })
        .then((response) => {
          dispatch({ type: SetNewPasswordComplete });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: SetNewPasswordError });
          reject(error);
        });
    })
  )
);

export const createGuestAccount = () => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch({ type: CreateGuestAccount });

      const referringNetwork = localStorage.getItem(REFERRING_NETWORK) || '';

      window.Kitsune.createGuestAccount(referringNetwork)
        .then((json) => {
          dispatch({ type: CreateGuestAccountComplete });

          const { userID } = json;

          const guestUserData = {
            userID,
            isGuest: true
          };

          localStorage.setItem(GUEST_USER_ID, userID);

          dispatch(updateLocalUser(guestUserData));

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: CreateGuestAccountError });
          reject(error);
        });
    })
  )
);

export const loginGuest = (userId) => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      dispatch({ type: LoginGuest });

      window.Kitsune.loginGuest(userId)
        .then((json) => {
          dispatch({ type: LoginGuestComplete });

          const { userID, sessionID } = json;

          const guestUserData = {
            userID: parseInt(userID, 10),
            sessionID,
            isGuest: true
          };

          localStorage.setItem(GUEST_USER_ID, userID);
          localStorage.setItem(GUEST_SESSION_ID, sessionID);

          dispatch(updateLocalUser(guestUserData));
          dispatch(getPlayerProfile(userID));

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: LoginGuestError });
          reject(error);
        });
    })
  )
);

export const saveGuestAccount = (username, email, password) => (
  (dispatch, getState) => (
    new Promise((resolve, reject) => {
      dispatch({ type: SaveGuestAccount });

      window.Kitsune.saveGuestAccount(username, email, password)
        .then((json) => {
          dispatch({ type: SaveGuestAccountComplete });

          const { user } = getState();
          const passwordHash = getSHA256Hash(password);
          localStorage.setItem(PASSWORD, passwordHash);
          localStorage.setItem(USER_ID, user.userID);

          const userData = {
            isGuest: false
          };

          dispatch(updateLocalUser(userData));

          resolve(json);
        })
        .catch((error) => {
          dispatch({ type: SaveGuestAccountError });
          reject(error);
        });
    })
  )
);
