import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT,
  ACTION_BUTTON, CONTENT_SUB_HEADER
} from '../styles/global';
import CustomButton from './CustomButton';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme),
    fontSize: 60,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 50
    }
  },
  subheader: {
    ...CONTENT_SUB_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  viewCatalogButton: {
    ...ACTION_BUTTON(theme)
  }
});

const FourZeroFour = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>404</p>
    <p className={classes.subheader}>
      That page can&apos;t be found.
    </p>
    <p className={classes.text}>
      How about checking out our game catalog?
    </p>
    <CustomButton
      className={classes.viewCatalogButton}
      isInternalLinkButton
      linkPath="/games"
    >
      View Game Catalog
    </CustomButton>
  </div>
);

FourZeroFour.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  FourZeroFour
);
