import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INNER_CONTENT_WRAPPER
} from '../styles/global';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'initial'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme)
  }
});

const ZoomUserGuideUninstalling = ({ classes }) => (
  <section
    className={classes.innerWrapper}
    id="uninstalling"
  >
    <p className={classes.header}>Uninstalling LGN Games for Zoom</p>
    <p className={classes.text}>
      1. Login to your Zoom Account and navigate to the&nbsp;
      <a
        href="https://marketplace.zoom.us/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Zoom App Marketplace
      </a>
      .
    </p>
    <p className={classes.text}>
      2. Click <b>Manage &gt; Installed Apps</b> or search for the desired LGN game app.
    </p>
    <p className={classes.text}>
      3. Click the app icon.
    </p>
    <p className={classes.text}>
      4. Click <b>Uninstall</b>.
    </p>
  </section>
);

ZoomUserGuideUninstalling.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideUninstalling
);
