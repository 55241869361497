import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab, TextField,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import CustomButton from './CustomButton';
import PlayerOptionsTab from '../enums/player-options-tab';
import { getSuccessMessage } from '../lib/string';
import PlayerReportCategory from '../enums/player-report-category';
import { MEDIA_PLATFORM_REPORT_INPUT } from '../enums/element-id';

const styles = () => ({
  dialogPaper: {
    width: 350,
    height: 450
  },
  content: {
    width: '100%'
  },
  formControl: {
    marginTop: 25,
    width: '100%'
  },
  select: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  input: {
    width: '100%'
  },
  button: {
    width: 150
  }
});

const MediaPlatformPlayerOptionsDialog = ({
  classes, open, playerProfile, onClose, showGlobalSnackbar, blockLists
}) => {
  const [selectedTab, setSelectedTab] = useState(PlayerOptionsTab.BLOCK);
  const [reportCategory, setReportCategory] = useState(PlayerReportCategory.PERSONAL_REQUEST.value);
  const [reportMessage, setReportMessage] = useState('');
  const [isPlayerBlocked, setIsPlayerBlocked] = useState(null);

  const buttonClicked = (message) => {
    onClose();
    showGlobalSnackbar(getSuccessMessage(message));
  };

  const blockPlayer = () => {
    window.Kitsune.blockPlayer(playerProfile.userID, true);
    window.LGNVideo.blockRemotePlayer(playerProfile.userID, true);
    buttonClicked(`You have blocked ${playerProfile.name}`);
  };

  const unblockPlayer = () => {
    window.Kitsune.blockPlayer(playerProfile.userID, false);
    window.LGNVideo.blockRemotePlayer(playerProfile.userID, false);
    buttonClicked(`You have unblocked ${playerProfile.name}`);
  };

  const reportPlayer = () => {
    window.Kitsune.reportPlayer(playerProfile.userID, reportMessage, reportCategory);
    buttonClicked(`You have reported ${playerProfile.name}.`);
  };

  useEffect(() => {
    if (playerProfile && blockLists && blockLists.blockedByMe) {
      const parsedPlayerId = parseInt(playerProfile.userID, 10);
      const isBlocked = blockLists.blockedByMe.includes(parsedPlayerId);
      setIsPlayerBlocked(isBlocked);
    }
  }, [blockLists, playerProfile]);

  const getBlockPlayerButton = () => {
    if (isPlayerBlocked) {
      return (
        <CustomButton
          className={classes.button}
          onClick={unblockPlayer}
        >
          unblock
        </CustomButton>
      );
    }

    return (
      <CustomButton
        className={classes.button}
        onClick={blockPlayer}
      >
        block
      </CustomButton>
    );
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogContent>
        <DialogTitle disableTypography>player options</DialogTitle>
        <Tabs
          onChange={(event, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
        >
          <Tab
            label="block"
            value={PlayerOptionsTab.BLOCK}
          />
          <Tab
            label="report"
            value={PlayerOptionsTab.REPORT}
          />
        </Tabs>
        {selectedTab === PlayerOptionsTab.BLOCK && (
          <div className={classes.content}>
            {isPlayerBlocked ? (
              <p>You have blocked this player.</p>
            ) : (
              <>
                <p>
                  Blocking this player removes the ability for you and the
                  blocked player to see or hear each other.
                </p>
                <p>
                  You can unblock this player later by returning to this dialog.
                </p>
              </>
            )}
          </div>
        )}
        {selectedTab === PlayerOptionsTab.REPORT && (
          <div className={classes.content}>
            <FormControl
              className={classes.formControl}
              variant="filled"
            >
              <InputLabel id="player-report-category-label">Report Category</InputLabel>
              <Select
                className={classes.select}
                labelId="player-report-category-label"
                onChange={(event) => setReportCategory(event.target.value)}
                value={reportCategory}
              >
                {Object.values(PlayerReportCategory).map((category) => (
                  <MenuItem value={category.value}>
                    {category.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className={classes.textField}
              id={MEDIA_PLATFORM_REPORT_INPUT}
              InputProps={{
                className: classes.input
              }}
              label="Why are you reporting this player?"
              multiline
              onChange={(event) => setReportMessage(event.target.value)}
              rows={4}
              value={reportMessage}
              variant="filled"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          className={classes.button}
          onClick={onClose}
        >
          cancel
        </CustomButton>
        {selectedTab === PlayerOptionsTab.BLOCK ? (
          getBlockPlayerButton()
        ) : (
          <CustomButton
            className={classes.button}
            disabled={!reportMessage}
            onClick={reportPlayer}
          >
            report
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

MediaPlatformPlayerOptionsDialog.propTypes = {
  blockLists: PropTypes.shape({
    blockedByMe: PropTypes.arrayOf(
      PropTypes.number
    ),
    blockingMe: PropTypes.arrayOf(
      PropTypes.number
    )
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  playerProfile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    userID: PropTypes.number.isRequired
  }).isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  MediaPlatformPlayerOptionsDialog
);
