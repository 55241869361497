import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT, CONTENT_ROW
} from '../styles/global';
import { isMacOS, isMobileDevice } from '../lib/browser';
import CustomButton from './CustomButton';
import LGNPokerLogo from './LGNPokerLogo';
import useMobileRedirect from '../hooks/useMobileRedirect';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  row: {
    ...CONTENT_ROW(theme)
  }
});

const download = () => {
  const cachebuster = Math.round(new Date().getTime() / 1000);
  const iframe = document.createElement('iframe');

  iframe.src = `https://livegamenight.s3.amazonaws.com/downloads/LivePokerNightSetup_win.exe?cb=${cachebuster}`;
  iframe.setAttribute('style', 'display:none;');

  document.body.appendChild(iframe);
};

const Download = ({ classes }) => {
  useMobileRedirect();

  const history = useHistory();

  useEffect(() => {
    if (!isMobileDevice()) {
      if (isMacOS()) {
        history.push('/mac-deprecation');
      } else {
        download();
      }
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <LGNPokerLogo />
      <p className={classes.header}>Downloading LGN Poker</p>
      <p className={classNames(classes.text, classes.row)}>
        If your download doesn&apos;t start automatically, click the button below
      </p>
      <div className={classes.row}>
        <CustomButton onClick={download}>
          download
        </CustomButton>
      </div>
    </div>
  );
};

Download.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Download
);
