const initialState = {
  mediaPath: 'https://cdn-ssl.kitsuneapi.com/fx/lgn/shared/portal/media/'
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
