import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  HOME_SUMMARY, HOME_SUMMARY_HEADER, HOME_SUMMARY_TEXT,
  HOME_SUMMARY_WRAPPER
} from '../styles/global';
import AnnouncementBanner from '../components/AnnouncementBanner';
import HomeGameSummary from '../components/HomeGameSummary';
import { getGames as getGamesAction } from '../actions/game';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import { isFutureFlagEnabled, isFutureFlaggedGame } from '../lib/env';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  gamesWrapper: {
    padding: '35px 0',
    width: '100%',
    maxWidth: 1100,
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0 0 0'
    }
  },
  game: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      paddingBottom: 20
    }
  },
  categoryLabel: {
    fontSize: 24,
    marginBottom: 15,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5
    }
  },
  lastGame: {
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 5
    }
  },
  summaryWrapper: {
    ...HOME_SUMMARY_WRAPPER(theme),
    backgroundColor: 'rgba(15, 15, 20, 1)',
  },
  summary: {
    ...HOME_SUMMARY(theme)
  },
  summaryHeader: {
    ...HOME_SUMMARY_HEADER(theme),
    color: 'rgba(166, 27, 9, 1)'
  },
  summaryText: {
    ...HOME_SUMMARY_TEXT(theme)
  },
  moreGamesBanner: {
    backgroundColor: '#18181b',
    width: '100%',
    margin: 0,
    padding: '35px 0',
    borderRadius: 0
  }
});

const mapStateToProps = (state) => ({
  games: state.games
});

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(getGamesAction())
});

const Home = ({
  classes, games, getGames
}) => {
  useEffect(() => {
    if (!games.length) {
      getGames();
    }
  }, []);

  if (games.length === 0) {
    return (<GlobalProgressSpinner message="Loading the LGN game catalog..." />);
  }

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta
          content="Learn more about the exciting live gaming experiences offered by Live Game Night!"
          name="description"
        />
        <title>Live Game Night - Game Catalog</title>
        <link
          href="https://play.livegamenight.com/games"
          rel="canonical"
        />
      </Helmet>
      <div className={classes.gamesWrapper}>
        <div className={classes.categoryLabel}>Featured Games</div>
        {games.map((game) => {
          if (isFutureFlagEnabled() || !isFutureFlaggedGame(game)) {
            return (
              <HomeGameSummary
                className={classes.game}
                game={game}
              />
            );
          }

          return (<></>);
        })}
      </div>
      <AnnouncementBanner
        className={classes.moreGamesBanner}
        text="More games coming soon!"
      />
      <div className={classes.summaryWrapper}>
        <div className={classes.summary}>
          <h1 className={classes.summaryHeader}>We Put the Video in Games</h1>
          <p className={classes.summaryText}>
            Live Game Night is a place where friends can stay connected
            and see each other LIVE while enjoying a variety of popular
            and exciting games. Every Live Game Night experience includes
            live video, meaning that you don&apos;t just play games with the
            people you care about--you share a real experience!
          </p>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  getGames: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    Home
  )
);
