import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import {
  setVideoEnabled as setVideoEnabledAction,
  setAudioEnabled as setAudioEnabledAction
} from '../actions/settings';
import CustomImage from './CustomImage';

const styles = () => ({
  wrapper: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    padding: '0 8px',
    boxSizing: 'border-box'
  },
  iconButton: {
    borderRadius: 3,
    width: 40,
    height: 40,
    marginRight: 8,
    '&:hover': {
      opacity: 0.9
    }
  },
  activeButton: {
    backgroundColor: '#33496e',
    border: 'solid 1px rgba(51, 73, 110, 0.2)',
    '&:hover': {
      backgroundColor: '#4b5f7f',
    }
  },
  disabledButton: {
    backgroundColor: '#45454b',
    border: 'solid 1px rgba(216, 216, 216, 0.2)',
    '&:hover': {
      backgroundColor: '#6a6a6f',
    }
  }
});

const mapStateToProps = (state) => ({
  settings: state.settings,
  platform: state.platform
});

const mapDispatchToProps = (dispatch) => ({
  setVideoEnabled: (enabled) => dispatch(setVideoEnabledAction(enabled)),
  setAudioEnabled: (enabled) => dispatch(setAudioEnabledAction(enabled))
});

const MediaPlatformVideoControls = ({
  classes, className, settings, setVideoEnabled, setAudioEnabled, platform
}) => (
  <div className={classNames(classes.wrapper, className)}>
    <IconButton
      className={classNames(
        classes.iconButton,
        settings.isAudioEnabled ? classes.activeButton : classes.disabledButton
      )}
      disabled={!platform.hasMediaPermissions}
      onClick={() => setAudioEnabled(!settings.isAudioEnabled)}
    >
      {settings.isAudioEnabled ? (
        <Tooltip title="Click to disable audio">
          <CustomImage src="/icons/mic_on.svg" />
        </Tooltip>
      ) : (
        <Tooltip title="Click to enable audio">
          <CustomImage src="/icons/mic_off.svg" />
        </Tooltip>
      )}
    </IconButton>
    <IconButton
      className={classNames(
        classes.iconButton,
        settings.isVideoEnabled ? classes.activeButton : classes.disabledButton
      )}
      disabled={!platform.hasMediaPermissions}
      onClick={() => setVideoEnabled(!settings.isVideoEnabled)}
    >
      {settings.isVideoEnabled ? (
        <Tooltip title="Click to disable video">
          <CustomImage src="/icons/video_on.svg" />
        </Tooltip>
      ) : (
        <Tooltip title="Click to enable video">
          <CustomImage src="/icons/video_off.svg" />
        </Tooltip>
      )}
    </IconButton>
  </div>
);

MediaPlatformVideoControls.defaultProps = {
  className: ''
};

MediaPlatformVideoControls.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  platform: PropTypes.shape({
    hasMediaPermissions: PropTypes.bool.isRequired
  }).isRequired,
  setAudioEnabled: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    isAudioEnabled: PropTypes.bool.isRequired,
    isVideoEnabled: PropTypes.bool.isRequired
  }).isRequired,
  setVideoEnabled: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    MediaPlatformVideoControls
  )
);
