import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { CONTENT_TEXT, FOCUSED_SECTION } from '../styles/global';

const styles = (theme) => ({
  text: {
    ...CONTENT_TEXT(theme)
  },
  tableOfContents: {
    ...FOCUSED_SECTION(theme)
  },
  tableOfContentsHeader: {
    fontSize: 24
  },
  tableOfContentsEntry: {
    textAlign: 'left'
  }
});

const ZoomUserGuideTableOfContents = ({ classes }) => (
  <div className={classes.tableOfContents}>
    <p className={classNames(classes.text, classes.tableOfContentsHeader)}>
      This user guide covers the following topics
    </p>
    <ul>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#installing">Installing LGN Games for Zoom</a>
      </li>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#using">Using LGN Games for Zoom</a>
      </li>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#uninstalling">Uninstalling LGN Games for Zoom</a>
      </li>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#troubleshooting">Troubleshooting Common Issues</a>
      </li>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#faq">Frequently Asked Questions</a>
      </li>
      <li className={classNames(classes.text, classes.tableOfContentsEntry)}>
        <a href="#support">Getting Support</a>
      </li>
    </ul>
  </div>
);

ZoomUserGuideTableOfContents.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideTableOfContents
);
