import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  useLocation, useHistory, useParams
} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, HEADER_HEIGHT, CHAT_EXPANDED_WIDTH,
  MEDIA_PLATFORM_CONSUMER_TOOLBAR_HEIGHT, CHAT_COLLAPSED_WIDTH
} from '../styles/global';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import {
  setVideoEnabled as setVideoEnabledAction,
  setAudioEnabled as setAudioEnabledAction,
  showSettingsDialog as showSettingsDialogAction,
  setShowSettingsOnLoadComplete as setShowSettingsOnLoadCompleteAction
} from '../actions/settings';
import { setHasLoadedLGNVideoLibrary as setHasLoadedLGNVideoLibraryAction } from '../actions/has-loaded-lgn-video-library';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { customFetch, getServiceError } from '../lib/http';
import { getAgoraToken, getGameCodeAsInt } from '../lib/platform';
import { getCdnPath } from '../lib/env';
import LoginRequired from './LoginRequired';
import LeaveGameWarning from './LeaveGameWarning';
import useMobileRedirect from '../hooks/useMobileRedirect';
import useGames from '../hooks/useGames';
import VideoState from '../enums/video-state';
import useDevices from '../hooks/useDevices';
import useLogin from '../hooks/useLogin';
import MediaPlatformGameInfo from '../components/MediaPlatformGameInfo';
import MediaPlatformChat from '../components/MediaPlatformChat';
import MediaPlatformMetaData from '../components/MediaPlatformMetaData';
import MediaPlatformVideos from '../components/MediaPlatformVideos';
import MediaPlatformGame from '../components/MediaPlatformGame';
import MediaPlatformPlayerOptionsDialog from '../components/MediaPlatformPlayerOptionsDialog';
import useUnsupportedBrowser from '../hooks/useUnsupportedBrowser';
import { MEDIA_PLATFORM_NO_MEDIA_VIDEO_PLACEHOLDER } from '../enums/element-id';
import { setIsMediaPlatformActive as setIsMediaPlatformActiveAction } from '../actions/platform';
import Route from '../enums/route';
import { getErrorMessage } from '../lib/string';

const AGORA_APP_ID = '8dc7e05012244e848b67e8671748f3d8';
const TOOLBAR_HEIGHT = MEDIA_PLATFORM_CONSUMER_TOOLBAR_HEIGHT;

// LGNVideo event handlers that simply log to the console for debugging purposes.
const onNetworkQuality = () => console.log('onNetworkQuality called');
const onConnectionStateChanged = () => console.log('onConnectionStateChanged called');

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    boxSizing: 'border-box',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  innerWrapper: {
    display: 'flex'
  },
  videos: {
    flex: 1
  },
  gameAndChatWrapper: {
    display: 'flex'
  },
  disabledVideo: {
    '&:after': {
      content: '""',
      display: 'block',
      backgroundColor: 'black',
      backgroundImage: 'url(https://livegamenight-shared.s3.us-west-2.amazonaws.com/portal/media/video-disabled.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 999,
      boxSizing: 'border-box'
    }
  },
  noMediaVideoPlaceholder: {
    // Display uses the !important directive because the video styles, which are also
    // applied to this element and use inline-block, are assigned to the style attribute
    // and therefore take priority for styling when not overriden with !important.
    display: 'inline-flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& div:first-child': {
      fontSize: 15,
      textAlign: 'center',
      marginBottom: 10
    },
    [theme.breakpoints.down('sm')]: {
      // Override the attached onmouseenter handler and hide the info bar so it doesn't
      // cover the link in the noMediaVideoPlaceholder.
      '& $infoBar': {
        display: 'none !important'
      },
      '& div:first-child': {
        fontSize: 12,
        marginBottom: 5
      }
    }
  },
  infoBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 30,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 9999,
    display: 'none',
    alignItems: 'center',
    paddingLeft: 5,
    fontSize: 14,
    boxSizing: 'border-box'
  },
  infoBarUsername: {
    marginRight: 5,
    cursor: 'default'
  },
  infoBarMicStatusWrapper: {
    flex: 1
  },
  infoBarMicStatus: {
    width: 15
  },
  infoBarPlayerOptionsWrapper: {
    height: '100%',
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  infoBarPlayerOptions: {
    height: 15,
    transform: 'rotate(90deg)'
  },
  learnMoreLink: {
    fontSize: 14
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  host: state.host,
  settings: state.settings,
  games: state.games,
  config: state.config,
  hasLoadedLGNVideoLibrary: state.hasLoadedLGNVideoLibrary,
  platform: state.platform
});

const mapDispatchToProps = (dispatch) => ({
  setVideoEnabled: (enabled) => dispatch(setVideoEnabledAction(enabled)),
  setAudioEnabled: (enabled) => dispatch(setAudioEnabledAction(enabled)),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  showSettingsDialog: (showDialog) => dispatch(showSettingsDialogAction(showDialog)),
  setHasLoadedLGNVideoLibrary: (hasLoaded) => dispatch(setHasLoadedLGNVideoLibraryAction(hasLoaded)), // eslint-disable-line
  setShowSettingsOnLoadComplete: (complete) => dispatch(setShowSettingsOnLoadCompleteAction(complete)), // eslint-disable-line
  setIsMediaPlatformActive: (isActive) => dispatch(setIsMediaPlatformActiveAction(isActive))
});

const getInnerWrapperDimensions = () => {
  const SIXTEEN_NINE_ASPECT_RATIO_DIVISOR = 1.777777777777778;
  // Determine the dimensions based on the width of the viewport.
  let width = window.innerWidth;
  let height = width / SIXTEEN_NINE_ASPECT_RATIO_DIVISOR;

  // If the calculated height overflows the available viewport,
  // recalculate based on the height of the viewport.
  if (height > (window.innerHeight - HEADER_HEIGHT - TOOLBAR_HEIGHT)) {
    height = (window.innerHeight - HEADER_HEIGHT) - TOOLBAR_HEIGHT;
    width = height * SIXTEEN_NINE_ASPECT_RATIO_DIVISOR;
  }

  const dimensions = {
    width,
    height
  };

  return dimensions;
};

const getIframeDimensions = (isChatExpanded = false) => {
  const FOUR_THREE_ASPECT_RATIO_DIVISOR = 1.333333333333333;
  const innerWrapperDimensions = getInnerWrapperDimensions();
  let { height } = innerWrapperDimensions;
  let width = height * FOUR_THREE_ASPECT_RATIO_DIVISOR;

  // If chat is expanded, subtract the width of the open chat bar from the width of
  // the iframe size, minus the width of the collpased chat bar space that was acting
  // as a layout placeholder before expansion.
  if (isChatExpanded) {
    width -= (CHAT_EXPANDED_WIDTH - CHAT_COLLAPSED_WIDTH);
    height = width / FOUR_THREE_ASPECT_RATIO_DIVISOR;
  }

  const dimensions = {
    width,
    height
  };

  return dimensions;
};

const MediaPlatform = ({
  classes, user, host,
  settings, setVideoEnabled, setAudioEnabled,
  showGlobalSnackbar, showSettingsDialog, games,
  config, hasLoadedLGNVideoLibrary, setHasLoadedLGNVideoLibrary,
  setShowSettingsOnLoadComplete, setIsMediaPlatformActive, platform
}) => {
  const [innerWrapperWidth, setInnerWrapperWidth] = useState(getInnerWrapperDimensions().width);
  const [innerWrapperHeight, setInnerWrapperHeight] = useState(window.innerHeight - HEADER_HEIGHT); // eslint-disable-line
  const [iframeWidth, setIframeWidth] = useState(getIframeDimensions().width);
  const [iframeHeight, setIframeHeight] = useState(getIframeDimensions().height);
  const [hasAttemptedGameVersionFetch, setHasAttemptedGameVersionFetch] = useState(false);
  const [game, setGame] = useState(null);
  const [gameVersion, setGameVersion] = useState('');
  const [gameUrlSearchParams, setGameUrlSearchParams] = useState('');
  const [gameCode, setGameCode] = useState('');
  const [channel, setChannel] = useState('');
  const [role, setRole] = useState('');
  const [currentUserVideoElement, setCurrentUserVideoElement] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [agoraToken, setAgoraToken] = useState(null);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [audioMuteStateChangedDetails, setAudioMuteStateChangedDetails] = useState(null);
  const [showPlayerOptions, setShowPlayerOptions] = useState(false);
  const [selectedPalyerOptionsProfile, setSelectedPlayerOptionsProfile] = useState(null);
  const [blockLists, setBlockLists] = useState(null);
  const [isLeaveGameWarningAvailable, setIsLeaveGameWarningAvailable] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const reactRouterParams = useParams();
  const wrapperRef = useRef(null);
  const videosRef = useRef(null);

  useUnsupportedBrowser();
  useGames();
  useMobileRedirect(reactRouterParams.slug);
  useLogin();
  useDevices();

  const getUserProfiles = () => {
    const promises = [];

    window.Kitsune.getPlayersInRoom()
      .then((playerIds) => {
        const playerIdsClone = cloneDeep(playerIds);
        playerIdsClone.unshift(user.userID);

        for (let i = 0; i < playerIdsClone.length; i += 1) {
          const userId = playerIdsClone[i];
          promises.push(window.Kitsune.getPlayerProfile(userId));
        }

        Promise.all(promises)
          .then((allPlayerProfiles) => setPlayerProfiles(allPlayerProfiles));
      });
  };

  /* eslint-disable */ 
  // Disable eslint to allow for assignment to container parameter.
  const applyVideoContainerStyles = (container) => {
    container.style.display = 'inline-block';
    container.style.position = 'relative';
    container.style.transition = 'opacity 1s';
    container.style.left = 0;
    container.style.top = 0;
    container.style.width = '100%';
    container.style.height = `${(videosRef.current.getClientRects()[0].width / 16) * 9}px`;
    container.style.opacity = '1';
    container.style.zIndex = 1;
    container.style.flexShrink = 0;
    container.style.marginBottom = '10px';
    container.style.boxSizing = 'border-box';
    container.style.backgroundColor = 'black';
  };
  /* eslint-enable */

  const cleanupNoMediaVideoPlaceholder = () => {
    const existingPlaceholder = document.getElementById(MEDIA_PLATFORM_NO_MEDIA_VIDEO_PLACEHOLDER);

    if (existingPlaceholder) {
      existingPlaceholder.remove();
    }
  };

  const getNoMediaVideoPlaceholder = () => {
    cleanupNoMediaVideoPlaceholder();

    const placeholder = document.createElement('div');
    const div = document.createElement('div');
    const a = document.createElement('a');

    placeholder.id = MEDIA_PLATFORM_NO_MEDIA_VIDEO_PLACEHOLDER;
    div.innerText = 'Browser Video Disabled';
    applyVideoContainerStyles(placeholder);

    a.href = Route.MEDIA_PERMISSIONS_HELP;
    a.setAttribute('target', '_blank');
    a.innerText = 'Learn More';
    a.classList.add(classes.learnMoreLink);

    placeholder.classList.add(classes.noMediaVideoPlaceholder);
    placeholder.appendChild(div);
    placeholder.appendChild(a);

    return placeholder;
  };

  const handleBuildVideoList = () => {
    const videoContainers = document.querySelectorAll('[id^=video_container_]');
    let hasCurrentUserVideoContainer = false;

    if (videosRef && videosRef.current) {
      for (let i = 0; i < videoContainers.length; i += 1) {
        const container = videoContainers[i];

        // Set the video container styles.
        applyVideoContainerStyles(container);

        // Attached the finished container to the videos element.
        videosRef.current.appendChild(container);

        // Ensure the the current user's video is always on top and set current user video ref.
        if (container.id === `video_container_${user.userID}`) {
          videosRef.current.insertBefore(container, videosRef.current.firstChild);
          setCurrentUserVideoElement(container);
        }

        if (container.id.includes(user.userID)) {
          hasCurrentUserVideoContainer = true;
        }
      }

      // This really needs to use the platform.hasMediaPermissions state, but until this
      // component is updated so the video containers are tracked in state that's populated in
      // a useEffect hook that can be updated when the hasMediaPermissions value changes,
      // we don't have access to the latest hasMediaPermissions value in this function.
      // Until then, take the lack of a current user video element to mean video is disabled.
      if (!hasCurrentUserVideoContainer) {
        const noMediaVideoPlaceholder = getNoMediaVideoPlaceholder();
        videosRef.current.insertBefore(noMediaVideoPlaceholder, videosRef.current.firstChild);
      } else {
        cleanupNoMediaVideoPlaceholder();
      }
    }
  };

  const getVideoContainerElements = () => {
    const videoContainerElements = document.querySelectorAll('[id^=video_container]');
    return videoContainerElements;
  };

  const getUserVideoElement = (userId) => {
    const userVideoElement = document.getElementById(`video_container_${userId}`);
    return userVideoElement;
  };

  const toggleVideoDisabledClass = (userVideoElement, showDisabled) => {
    if (userVideoElement) {
      userVideoElement.classList[showDisabled ? 'add' : 'remove'](classes.disabledVideo);
    }
  };

  const buildVideoListAndGetUserProfiles = () => {
    handleBuildVideoList();
    getUserProfiles();
  };

  const onJoinChannelSuccess = () => {
    buildVideoListAndGetUserProfiles();
  };

  const onJoinChannelFailed = () => {
    buildVideoListAndGetUserProfiles();
  };

  const onUserJoined = ([userId]) => {
    const isMuted = window.LGNVideo.isVideoMuted(userId);
    const userVideoElement = getUserVideoElement(userId);

    buildVideoListAndGetUserProfiles();

    toggleVideoDisabledClass(userVideoElement, isMuted);
  };

  const onUserOffline = () => {
    buildVideoListAndGetUserProfiles();
  };

  const onRemoteVideoStateChanged = ([userId, videoState/* ,reasonChanged */]) => {
    const updatedUserVideoElement = getUserVideoElement(userId);
    const isVideoDisabled = videoState === VideoState.STOPPED;
    toggleVideoDisabledClass(updatedUserVideoElement, isVideoDisabled);
  };

  const onRemoteAudioStateChanged = ([userId, state]) => {
    setAudioMuteStateChangedDetails({
      userId,
      state
    });
  };

  const onVolumeIndication = () => {};

  const setLGNVideoMediaDevices = () => {
    const { videoInputDevice, audioInputDevice } = settings;

    window.LGNVideo.setVideoCollectionDevice(videoInputDevice);
    window.LGNVideo.setAudioInputDevice(audioInputDevice);
  };

  const handleLoadVideo = () => {
    window.LGNVideo.init({ gl: false, GL: false }, () => {
      window.LGNVideo.createEngine(AGORA_APP_ID);
      window.LGNVideo.registerCallbacks(
        onJoinChannelSuccess,
        onJoinChannelFailed,
        onUserJoined,
        onUserOffline,
        onNetworkQuality,
        onConnectionStateChanged,
        onRemoteVideoStateChanged,
        onRemoteAudioStateChanged,
        onVolumeIndication
      );

      setLGNVideoMediaDevices();
      window.LGNVideo.joinChannel(agoraToken, channel, user.userID);
      setHasLoadedLGNVideoLibrary(true);
    });
  };

  const handleFullscreenClick = () => {
    if (!document.fullScreen) {
      wrapperRef.current.requestFullscreen();
    }
  };

  const setVideoElementHeight = () => {
    const videoContainers = document.querySelectorAll('[id^=video_container_]');

    if (videosRef && videosRef.current) {
      const videosElementClientRects = videosRef.current.getClientRects();

      if (videosElementClientRects && videosElementClientRects.length) {
        const height = `${(videosElementClientRects[0].width / 16) * 9}px`;

        for (let i = 0; i < videoContainers.length; i += 1) {
          const container = videoContainers[i];
          container.style.height = height;
        }
      }
    }
  };

  const isLoading = () => (
    !hasAttemptedGameVersionFetch
    || !gameUrlSearchParams
    || !game
    || user.isAuthenticating
  );

  const handleChatOpenStateChange = () => {
    const { width, height } = getIframeDimensions(!isChatExpanded);

    setIframeWidth(width);
    setIframeHeight(height);

    setIsChatExpanded(!isChatExpanded);
  };

  const refreshBlockLists = () => {
    window.Kitsune.getBlocks()
      .then((lists) => setBlockLists(lists));
  };

  const handlePlayerOptionsClick = (playerProfile) => {
    setSelectedPlayerOptionsProfile(playerProfile);
    setShowPlayerOptions(true);
  };

  const handlePlayerOptionsClose = () => {
    setShowPlayerOptions(false);
    refreshBlockLists();
  };

  // Update the iframeWidth and iframeHeight state when the window is resized.
  useEffect(() => {
    const handleResize = () => {
      const innerWrapperDimensions = getInnerWrapperDimensions();
      const iframeDimensions = getIframeDimensions(isChatExpanded);

      setInnerWrapperWidth(innerWrapperDimensions.width);
      setInnerWrapperHeight(window.innerHeight - HEADER_HEIGHT);
      setIframeWidth(iframeDimensions.width);
      setIframeHeight(iframeDimensions.height);
      setVideoElementHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isChatExpanded]);

  // Send the user to the join UI if they try to enter the game UI directly without
  // channel or role search params.
  useEffect(() => {
    const codeParam = params.get('code');
    const roleParam = params.get('role');

    if (!codeParam || !roleParam) {
      history.push(`${location.pathname}/join`);
    } else if (game) {
      const channelName = `${game.slug}_${codeParam}`.toUpperCase();
      setGameCode(codeParam);
      setChannel(channelName);
      setRole(roleParam);
    }
  }, [game]);

  // Fetch the current game version so it can be appended to the iframe search params.
  // If the current version can't be fetched, set the hasAttemptedGameVersionFetch state
  // to true to bypass the version requirement.
  useEffect(() => {
    const cacheBuster = Math.random().toString().substring(2);

    if (game) {
      customFetch(`${host}kitsune/gameClientVersion?game_id=${game.id}&cb=${cacheBuster}`)
        .then((json) => {
          const { version } = json;

          if (version) {
            // Append the current game version ID to the query string as a cache buster.
            setGameVersion(version);
          }
        })
        .finally(() => setHasAttemptedGameVersionFetch(true));
    }
  }, [game]);

  // Set the iframe search params.
  useEffect(() => {
    let searchParams = '';

    if (user.sessionID && channel && role && user.username) {
      searchParams = `sessionId=${user.sessionID}&meetingUUID=${channel}&role=${role}&screenName=${user.username}&lgn=true&context=lgn`;

      if (gameVersion) {
        searchParams = `${searchParams}&v=${gameVersion}`;
      }
    }

    setGameUrlSearchParams(searchParams);
  }, [user.sessionID, user.username, location, channel, gameVersion, role]);

  // Find the current game and set the game state.
  // If the game referenced in URL slug segment doesn't exist, redirect to the invalid game UI.
  useEffect(() => {
    if (games.length) {
      const currentGame = games.find((g) => g.slug === reactRouterParams.slug);

      if (currentGame) {
        setGame(currentGame);
      } else {
        history.push('/invalid-game');
      }
    }
  }, [games]);

  // Get an Agora token to use with the LGNVideo library initialization.
  useEffect(() => {
    if (channel && game && user.isAuthenticated && user.userID) {
      getAgoraToken(host, channel, user.userID, game.id)
        .then((response) => {
          if (response && response.token) {
            setAgoraToken(response.token);
          } else {
            throw new Error('Failure to receive the Agora access token from the server');
          }
        })
        .catch((error) => showGlobalSnackbar(getServiceError(error)));
    }
  }, [channel, game, user.isAuthenticated, user.userID]);

  // Initialize the LGNVideo library if an Agora token exists. If the library has
  // already been initialized when a media device has changed, simply inform LGN video
  // about the updated media devices.
  useEffect(() => {
    if (agoraToken) {
      handleLoadVideo();
    }
  }, [agoraToken]);

  // Set the media input devices once the lgn video library has loaded, and anytime a
  // device is changed by the user in settings.
  useEffect(() => {
    if (hasLoadedLGNVideoLibrary) {
      setLGNVideoMediaDevices();
    }
  }, [hasLoadedLGNVideoLibrary, settings.videoInputDevice, settings.audioInputDevice]);

  useEffect(() => {
    // If the user is seeing the settings dialog for the first time, or has requested that the
    // settings dialog be shown each time a game loads, then the toggling of video will be handled
    // directly from the media settings dialog; otherwise, it should be handled here.
    if (
      hasLoadedLGNVideoLibrary
      && settings.hasSeenPlatformSettings
      && (!settings.showSettingsOnLoad || settings.showSettingsOnLoadComplete)
    ) {
      // If media permissions haven't been granted, automatically set video and audio to disabled;
      // otherwise, use the "join game" settings to determine whether they should be enabled.
      if (!platform.hasMediaPermissions) {
        setVideoEnabled(false);
        setAudioEnabled(false);
      } else {
        setVideoEnabled(settings.enableVideoOnJoinGame);
        setAudioEnabled(settings.enableAudioOnJoinGame);
      }
    }
  }, [hasLoadedLGNVideoLibrary, settings.hasSeenPlatformSettings, platform.hasMediaPermissions]);

  // Handle displaying or removing a video disabled state element to the user's video container
  // when the UI loads or when video is enabled or disabled.
  useEffect(() => {
    if (currentUserVideoElement) {
      toggleVideoDisabledClass(currentUserVideoElement, !settings.isVideoEnabled);
    }
  }, [settings.isVideoEnabled, currentUserVideoElement]);

  // Track whether or not the browser is in fullscreen mode in local state.
  useEffect(() => {
    setIsFullscreen(!!(document.fullscreenElement));
  }, [document.fullscreenElement]);

  // Show the settings dialog automatically if we don't have a record
  // of it having been previously shown, or if the user has requested to see
  // the dialog on every game load.
  useEffect(() => {
    if (
      user.isAuthenticated
      && (
        !settings.hasSeenPlatformSettings
        || (settings.showSettingsOnLoad && !settings.showSettingsOnLoadComplete)
      )
    ) {
      showSettingsDialog(true);
    }
  }, [
    user.isAuthenticated,
    settings.hasSeenPlatformSettings,
    settings.showSettingsOnLoad,
    settings.showSettingsOnLoadComplete
  ]);

  // Register a callback that will leave the video channel when the component unmounts.
  useEffect(() => () => {
    window.LGNVideo.leaveChannel();
  }, []);

  // Set the show settings on load complete state to false when the user leaves a game
  // so that it'll show when another game is loaded, if necessary.
  useEffect(() => () => {
    setShowSettingsOnLoadComplete(false);
  }, []);

  // Attach the player info bar to each video container once.
  useEffect(() => {
    if (playerProfiles.length) {
      const videoContainers = getVideoContainerElements();

      videoContainers.forEach((videoContainer) => {
        let userId = parseInt(videoContainer.id.split('_')[2], 10);

        // If no ID was present on the video container, then we're dealing with
        // the video_container_no_media element, so we know to use the current user's ID.
        if (!userId) {
          userId = user.userID;
        }

        const userProfile = playerProfiles.find((p) => parseInt(p.userID, 10) === userId);
        const existingPlayerInfoBar = document.getElementById(`player_info_bar_${userId}`);
        const isMuted = userId === user.userID
          ? !settings.isAudioEnabled : window.LGNVideo.isAudioMuted(userId);

        // Avoid inserting a duplicate info bar.
        if (existingPlayerInfoBar) {
          existingPlayerInfoBar.remove();
        }

        if (userProfile) {
          const wrapper = document.createElement('div');
          const username = document.createElement('p');
          const micStatusWrapper = document.createElement('div');
          const micStatus = document.createElement('img');
          const playerOptionsWrapper = document.createElement('div');
          const playerOptions = document.createElement('img');

          wrapper.id = `player_info_bar_${userId}`;
          wrapper.classList.add(classes.infoBar);

          username.innerText = userProfile.name;
          username.classList.add(classes.infoBarUsername);

          micStatusWrapper.classList.add(classes.infoBarMicStatusWrapper);

          micStatus.src = `${config.mediaPath}/icons/${isMuted ? 'mic_off.svg' : 'mic_on.svg'}`;
          micStatus.classList.add(classes.infoBarMicStatus);

          playerOptionsWrapper.classList.add(classes.infoBarPlayerOptionsWrapper);

          playerOptions.src = `${config.mediaPath}/icons/overflow_menu.svg`;
          playerOptions.classList.add(classes.infoBarPlayerOptions);

          wrapper.appendChild(username);

          micStatusWrapper.appendChild(micStatus);
          wrapper.appendChild(micStatusWrapper);

          // Don't show the player options for the current user.
          if (userId !== user.userID) {
            playerOptionsWrapper.appendChild(playerOptions);
            wrapper.appendChild(playerOptionsWrapper);
          }

          playerOptionsWrapper.addEventListener('click', () => handlePlayerOptionsClick(userProfile));
          videoContainer.addEventListener('mouseover', () => { wrapper.style.display = 'flex'; });
          videoContainer.addEventListener('mouseout', () => { wrapper.style.display = 'none'; });

          videoContainer.appendChild(wrapper);
        }
      });
    }
  }, [playerProfiles, settings.isAudioEnabled, audioMuteStateChangedDetails, user]);

  // Retrieve the block lists for this user.
  useEffect(() => {
    if (user.isAuthenticated) {
      refreshBlockLists();
    }
  }, [user.isAuthenticated]);

  // Block videos of players in either of this user's block lists.
  useEffect(() => {
    if (playerProfiles && playerProfiles.length && blockLists) {
      const { blockedByMe, blockingMe } = blockLists;

      playerProfiles.forEach((playerProfile) => {
        const parsedPlayerId = parseInt(playerProfile.userID, 10);

        if (blockedByMe.includes(parsedPlayerId) || blockingMe.includes(parsedPlayerId)) {
          window.LGNVideo.blockRemotePlayer(parsedPlayerId, true);
        }
      });
    }
  }, [playerProfiles, blockLists]);

  // Join the game session once the user has logged in and the session has been validated.
  // Redirect invalid sessions back to the join screen. This requires holding off on rendering
  // the LeaveGameWarning component so it doesn't display on the redirect back to the join UI.
  useEffect(() => {
    if (user.isAuthenticated && game && gameCode) {
      window.Kitsune.joinGameSession(gameCode)
        .then(() => setIsLeaveGameWarningAvailable(true))
        .catch(() => {
          showGlobalSnackbar(getErrorMessage('That game code isn\'t currently valid. Please try another code or start a new game.'));
          history.push(`/games/${game.slug}/join`);
        });
    }
  }, [user.isAuthenticated, game, gameCode]);

  // Subscribe to the Kitsune block event.
  useEffect(() => {
    window.Kitsune.subscribe(window.Kitsune.PlayerEvent.PLAYER_BLOCKED, (err, blockInfo) => {
      const { userID, blocked } = blockInfo;
      window.LGNVideo.blockRemotePlayer(userID, blocked);
      refreshBlockLists();
    });
  }, []);

  // Set the LGN Video Library loaded status to false when leaving the media platform,
  // as the library will need to be reloaded when a new game starts.
  useEffect(() => () => {
    setHasLoadedLGNVideoLibrary(false);
  }, []);

  // Register the media platform as being active with global state.
  useEffect(() => {
    setIsMediaPlatformActive(true);

    // Register the media platform as inactive with global state when unmounting.
    return () => {
      setIsMediaPlatformActive(false);
    };
  }, []);

  if (!user.isAuthenticated) {
    return (<LoginRequired game={game} />);
  }

  if (isLoading()) {
    return (<GlobalProgressSpinner />);
  }

  return (
    <div
      ref={wrapperRef}
      className={classes.wrapper}
      style={{
        justifyContent: isFullscreen && 'center'
      }}
    >
      <MediaPlatformMetaData game={game} location={location} />
      <div
        className={classes.innerWrapper}
        style={{ height: innerWrapperHeight, width: innerWrapperWidth }}
      >
        <MediaPlatformVideos ref={videosRef} className={classes.videos} />
        <div className={classes.gameAndChatWrapper}>
          <MediaPlatformGame
            game={game}
            gameCode={gameCode}
            iframeHeight={iframeHeight}
            iframeSrc={`${getCdnPath()}${game.clientPath}?${gameUrlSearchParams}`}
            iframeWidth={iframeWidth}
            isFullscreen={isFullscreen}
            onFullscreenClick={handleFullscreenClick}
          />
          <MediaPlatformChat
            game={game}
            isExpanded={isChatExpanded}
            onOpenStateChange={handleChatOpenStateChange}
            playerProfiles={playerProfiles}
            showGlobalSnackbar={showGlobalSnackbar}
          />
        </div>
      </div>
      {!isFullscreen && (
        <MediaPlatformGameInfo game={game} style={{ width: innerWrapperWidth }} />
      )}
      {isLeaveGameWarningAvailable && (<LeaveGameWarning />)}
      {showPlayerOptions && (
        <MediaPlatformPlayerOptionsDialog
          blockLists={blockLists}
          onClose={handlePlayerOptionsClose}
          open={showPlayerOptions}
          playerProfile={selectedPalyerOptionsProfile}
          showGlobalSnackbar={showGlobalSnackbar}
        />
      )}
    </div>
  );
};

MediaPlatform.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.shape({
    mediaPath: PropTypes.string.isRequired
  }).isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  hasLoadedLGNVideoLibrary: PropTypes.bool.isRequired,
  host: PropTypes.string.isRequired,
  platform: PropTypes.shape({
    hasMediaPermissions: PropTypes.bool.isRequired
  }).isRequired,
  setAudioEnabled: PropTypes.func.isRequired,
  setHasLoadedLGNVideoLibrary: PropTypes.func.isRequired,
  setIsMediaPlatformActive: PropTypes.func.isRequired,
  setShowSettingsOnLoadComplete: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    audioInputDevice: PropTypes.string.isRequired,
    audioInputDevices: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string.isRequired
      })
    ).isRequired,
    enableAudioOnJoinGame: PropTypes.bool.isRequired,
    enableVideoOnJoinGame: PropTypes.bool.isRequired,
    hasSeenPlatformSettings: PropTypes.bool.isRequired,
    isAudioEnabled: PropTypes.bool.isRequired,
    isVideoEnabled: PropTypes.bool.isRequired,
    showDialog: PropTypes.bool.isRequired,
    showSettingsOnLoad: PropTypes.bool.isRequired,
    showSettingsOnLoadComplete: PropTypes.bool.isRequired,
    videoInputDevice: PropTypes.string.isRequired,
    videoInputDevices: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  setVideoEnabled: PropTypes.func.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired,
  showSettingsDialog: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    sessionID: PropTypes.string,
    userID: PropTypes.number,
    username: PropTypes.string
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    MediaPlatform
  )
);
