import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import MediaPlatformVideoControls from '../containers/MediaPlatformVideoControls';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  videos: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1,
    position: 'relative',
    backgroundColor: '#1f1f23',
    padding: '0 10px'
  },
  controls: {
    diplay: 'flex',
    alignItems: 'center',
    height: 60
  }
};

const MediaPlatformVideos = forwardRef(({ classes, className }, ref) => (
  <div className={classNames(classes.wrapper, className)}>
    <div
      ref={ref}
      className={classes.videos}
    />
    <MediaPlatformVideoControls className={classes.controls} />
  </div>
));

MediaPlatformVideos.defaultProps = {
  className: ''
};

MediaPlatformVideos.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default
withStyles(styles)(
  MediaPlatformVideos
);
