import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import { HEADER_HEIGHT } from '../styles/global';
import PokerBanner from '../components/PokerBanner';
import { getGames as getGamesAction } from '../actions/game';
import FullscreenButton from '../components/FullscreenButton';
import { customFetch } from '../lib/http';
import LeaveGameWarning from './LeaveGameWarning';
import LoginRequired from './LoginRequired';
import { getCdnPath } from '../lib/env';
import useMobileRedirect from '../hooks/useMobileRedirect';
import useLogin from '../hooks/useLogin';
import useUnsupportedBrowser from '../hooks/useUnsupportedBrowser';
import { setIsPokerActive as setIsPokerActiveAction } from '../actions/platform';
import useDevices from '../hooks/useDevices';
import ClientMessageType from '../enums/client-message-type';
import ClientPayloadType from '../enums/client-payload-type';
import { getMediaDeviceName } from '../lib/settings';
import MediaDeviceType from '../enums/media-device-type';
import {
  showSettingsDialog as showSettingsDialogAction,
  setShowSettingsOnLoadComplete as setShowSettingsOnLoadCompleteAction
} from '../actions/settings';

const TOOLBAR_HEIGHT = 70;

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  iframe: {
    border: 0,
    boxSizing: 'border-box'
  },
  bannerWrapper: {
    display: 'flex',
    width: '100%',
    height: 180,
    justifyContent: 'center',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%',
    height: TOOLBAR_HEIGHT,
    boxSizing: 'border-box',
    borderTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullscreenButton: {
    width: 295
  }
});

const mapStateToProps = (state) => ({
  games: state.games,
  host: state.host,
  user: state.user,
  settings: state.settings
});

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(getGamesAction()),
  setIsPokerActive: (isActive) => dispatch(setIsPokerActiveAction(isActive)),
  showSettingsDialog: (showDialog) => dispatch(showSettingsDialogAction(showDialog)),
  setShowSettingsOnLoadComplete: (complete) => dispatch(setShowSettingsOnLoadCompleteAction(complete)), // eslint-disable-line
});

const getIframeDimensions = () => {
  const SIXTEEN_NINE_ASPECT_RATIO_DIVISOR = 1.777777777777778;
  // Determine the dimensions based on the width of the viewport.
  let width = window.innerWidth - TOOLBAR_HEIGHT;
  let height = width / SIXTEEN_NINE_ASPECT_RATIO_DIVISOR;

  // If the calculated height overflows the available viewport,
  // recalculate based on the height of the viewport.
  if (height > (window.innerHeight - HEADER_HEIGHT)) {
    height = (window.innerHeight - HEADER_HEIGHT) - TOOLBAR_HEIGHT;
    width = height * SIXTEEN_NINE_ASPECT_RATIO_DIVISOR;
  }

  const dimensions = {
    width,
    height
  };

  return dimensions;
};

const Game = ({
  classes, games, getGames,
  host, user, setIsPokerActive,
  settings, showSettingsDialog, setShowSettingsOnLoadComplete
}) => {
  useUnsupportedBrowser();
  useMobileRedirect();
  useLogin();
  useDevices();

  const params = new URLSearchParams(window.location.search);
  const [iframeWidth, setIframeWidth] = useState(getIframeDimensions().width);
  const [iframeHeight, setIframeHeight] = useState(getIframeDimensions().height);
  const [iframeElement, setIframeElement] = useState(null);
  const [game, setGame] = useState(null);
  const [gameParams, setGameParams] = useState(params);
  const [gameVersion, setGameVersion] = useState('');
  const [proceedWithUnversionedLoad, setProceedWithUnversionedLoad] = useState(false);
  // Tracking the number of times the client requests AV input will allow for triggering
  // the message post call n number of times in the useEffect hook rather than the single
  // time that would be afforded by a boolean "hasRequestedVideo" piece of state.
  const [videoInputRequestCount, setVideoInputRequestCount] = useState(0);
  const [audioInputRequestCount, setAudioInputRequestCount] = useState(0);
  const location = useLocation();

  const iframeRef = useCallback((node) => {
    if (node) {
      setIframeElement(node);
    }
  }, []);

  const getCurrentGame = () => {
    // Find the game identified by the URL's "slug" parameter.
    const pathSegments = location.pathname.split('/');
    const gameNameFromPath = pathSegments[pathSegments.length - 1];
    return games.find((g) => g.slug === gameNameFromPath);
  };

  useEffect(() => {
    if (!games.length) {
      getGames();
    } else {
      const currentGame = getCurrentGame();
      setGame(currentGame);
    }
  }, [games]);

  useEffect(() => {
    // Update the iframeWidth and iframeHeight state when the window is resized.
    const handleResize = () => {
      const dimensions = getIframeDimensions();
      setIframeWidth(dimensions.width);
      setIframeHeight(dimensions.height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // The iframe-hosted game has trouble accessing the clipboard, so listen
    // for message events from the iframe and perform copy/paste commands as a proxy.
    const handleMessage = (message) => {
      if (iframeElement) {
        if (message.data.type === 'paste-from-clipboard') {
          navigator.clipboard.readText()
            .then((text) => {
              iframeElement.contentWindow.postMessage({
                type: 'paste-from-clipboard',
                toPaste: text
              }, '*');
            });
        } else if (message.data.type === 'copy-to-clipboard') {
          const copiedText = (message.data && message.data.toCopy) ? message.data.toCopy : '';
          navigator.clipboard.writeText(copiedText);
        } else if (message.data.messageType === 'valueRequested') {
          if (message.data.payload.name === 'videoInput') {
            setVideoInputRequestCount(videoInputRequestCount + 1);
          } else if (message.data.payload.name === 'audioInput') {
            setAudioInputRequestCount(audioInputRequestCount + 1);
          }
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeElement]);

  // Inform the iframe about video input settings on load and each time video input changes.
  useEffect(() => {
    const { videoInputDevices, videoInputDevice } = settings;

    if (
      iframeElement
      && videoInputDevices
      && videoInputDevices.length
      && videoInputDevice
      && videoInputRequestCount > 0
    ) {
      const deviceName = getMediaDeviceName(
        videoInputDevices,
        MediaDeviceType.VIDEO_INPUT,
        videoInputDevice
      );

      iframeElement.contentWindow.postMessage({
        messageType: ClientMessageType.VALUE_CHANGED,
        version: '1.0.0',
        payload: {
          name: ClientPayloadType.VIDEO_INPUT,
          value: deviceName
        }
      }, '*');
    }
  }, [
    iframeElement,
    settings.videoInputDevices,
    settings.videoInputDevice,
    videoInputRequestCount
  ]);

  // Inform the iframe about audio input settings on load and each time audio input changes.
  useEffect(() => {
    const { audioInputDevices, audioInputDevice } = settings;

    if (
      iframeElement
      && audioInputDevices
      && audioInputDevices.length
      && audioInputDevice
      && audioInputRequestCount > 0
    ) {
      const deviceName = getMediaDeviceName(
        audioInputDevices,
        MediaDeviceType.AUDIO_INPUT,
        audioInputDevice
      );

      iframeElement.contentWindow.postMessage({
        messageType: ClientMessageType.VALUE_CHANGED,
        version: '1.0.0',
        payload: {
          name: ClientPayloadType.AUDIO_INPUT,
          value: deviceName
        }
      }, '*');
    }
  }, [
    iframeElement,
    settings.audioInputDevices,
    settings.audioInputDevice,
    audioInputRequestCount
  ]);

  useEffect(() => {
    setProceedWithUnversionedLoad(false);

    if (game) {
      const cacheBuster = Math.random().toString().substring(2);

      customFetch(`${host}kitsune/gameClientVersion?game_id=${game.id}&cb=${cacheBuster}`)
        .then((json) => {
          const { version } = json;

          if (version) {
            // Append the current game version ID to the query string as a cache buster.
            setGameVersion(version);
          } else {
            throw new Error('The server returned an empty version value.');
          }
        })
        // If the server returns an error instead of the game version, tell the UI
        // to go ahead and render without worrying about a version value in the iframe URL.
        .catch(() => setProceedWithUnversionedLoad(true));
    }
  }, [game]);

  // Set the query params that will be passed to the iframe.
  useEffect(() => {
    if (gameVersion) {
      params.append('v', gameVersion);
    }

    if (user.sessionID) {
      params.append('sessionId', user.sessionID);
    }

    params.append('context', 'lgn_poker');

    setGameParams(params);
  }, [user.sessionID, gameVersion]);

  // Register poker as being active with global state.
  useEffect(() => {
    setIsPokerActive(true);

    // Register poker as inactive with global state when unmounting.
    return () => {
      setIsPokerActive(false);
    };
  }, []);

  // Show the settings dialog automatically if we don't have a record
  // of it having been previously shown, or if the user has requested to see
  // the dialog on every game load.
  useEffect(() => {
    if (
      user.isAuthenticated
      && (
        !settings.hasSeenPlatformSettings
        || (settings.showSettingsOnLoad && !settings.showSettingsOnLoadComplete)
      )
    ) {
      showSettingsDialog(true);
    }
  }, [
    user.isAuthenticated,
    settings.hasSeenPlatformSettings,
    settings.showSettingsOnLoad,
    settings.showSettingsOnLoadComplete
  ]);

  // Set the show settings on load complete state to false when the user leaves a game
  // so that it'll show when another game is loaded, if necessary.
  useEffect(() => () => {
    setShowSettingsOnLoadComplete(false);
  }, []);

  // Let Kitsune know if a user is active by sending a message any time they
  // move the mouse outside of the game iframe.
  useEffect(() => {
    const handleMouseOver = () => {
      if (window.Kitsune && window.Kitsune.sendNotAFK) {
        window.Kitsune.sendNotAFK();
      }
    };

    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  const handleFullscreenClick = () => {
    if (iframeElement && !document.fullScreen) {
      iframeElement.requestFullscreen();
    }
  };

  const isUIReadyToLoad = () => {
    if (!game) {
      return false;
    }

    // If the gameParams state doesn't include a version value and an error hasn't
    // occurred on the server resulting in setProceedWithUnversionedLoad being set to
    // true, then wait for the version param to populate.
    if (!gameParams.toString().includes('v=') && !proceedWithUnversionedLoad) {
      return false;
    }

    if (!gameParams.toString().includes('sessionId=')) {
      return false;
    }

    return true;
  };

  if (!user.isAuthenticated) {
    return (<LoginRequired game={game} />);
  }

  if (!isUIReadyToLoad()) {
    return (<GlobalProgressSpinner />);
  }

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta
          content="Play LGN Poker and enjoy live, virtual poker with friends!"
          name="description"
        />
        <title>LGN Poker - Play</title>
        <link
          href="https://play.livegamenight.com/games/poker"
          rel="canonical"
        />
      </Helmet>
      <iframe
        ref={iframeRef}
        allow="camera *; microphone *; fullscreen; autoplay;"
        className={classes.iframe}
        height={iframeHeight}
        src={`${getCdnPath()}${game.clientPath}?${gameParams.toString()}`}
        title={game.name}
        width={iframeWidth}
      />
      <div className={classes.toolbar}>
        <FullscreenButton
          className={classes.button}
          onClick={handleFullscreenClick}
          text="fullscreen"
        />
      </div>
      <PokerBanner className={classes.bannerWrapper} />
      <LeaveGameWarning />
    </div>
  );
};

Game.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  getGames: PropTypes.func.isRequired,
  host: PropTypes.string.isRequired,
  setIsPokerActive: PropTypes.func.isRequired,
  setShowSettingsOnLoadComplete: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    audioInputDevice: PropTypes.string,
    audioInputDevices: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
    hasSeenPlatformSettings: PropTypes.bool.isRequired,
    showSettingsOnLoad: PropTypes.bool.isRequired,
    showSettingsOnLoadComplete: PropTypes.bool.isRequired,
    videoInputDevice: PropTypes.string,
    videoInputDevices: PropTypes.arrayOf(
      PropTypes.object
    ).isRequired,
  }).isRequired,
  showSettingsDialog: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    sessionID: PropTypes.string.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    Game
  )
);
