import React from 'react';
import CustomButton from './CustomButton';

const ReturnToCatalogButton = () => (
  <CustomButton
    isInternalLinkButton
    to="/games"
  >
    game catalog
  </CustomButton>
);

ReturnToCatalogButton.defaultProps = {
  className: ''
};

export default ReturnToCatalogButton;
