import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import useGames from '../hooks/useGames';
import useLogin from '../hooks/useLogin';
import LoginRequired from './LoginRequired';

const mapStateToProps = (state) => ({
  user: state.user,
  games: state.games,
  platform: state.platform
});

// This component is basically just a shell to help getting into a poker
// game function more like getting into other media platform games.
// It'll handle the login requirement for Poker, and displaying the
// login required UI if a user is unauthenticated.
const JoinPoker = ({ user, games, platform }) => {
  const [game, setGame] = useState(null);
  const history = useHistory();

  useGames();
  useLogin();

  useEffect(() => {
    // If the back button is clicked while in the poker game UI, the platform.isPokerActive
    // state will still be true when this code is run again. In those cases, send the
    // user back to the game catalog so they don't get caught in a redirect loop between the
    // dummy JoinPoker UI and the Poker game UI. It's never good to rely on timing to
    // make a decision like this, but it has worked 100% of the time in my testing, and
    // I've exhausted every other idea I currently have for getting users who click the
    // back button out of the JoinPoker => Poker Game UI redirect loop.
    // Worst case scenario, isPokerActive is false when this code is run, and the user
    // is sent back to the game UI, which is the current behavior.
    if (platform.isPokerActive) {
      history.push('/games');
    } else if (user.isAuthenticated) {
      history.push('/games/poker');
    }
  }, [user.isAuthenticated]);

  useEffect(() => {
    if (games.length) {
      const poker = games.find((g) => g.slug === 'poker');
      setGame(poker);
    }
  }, [games]);

  if (!user.isAuthenticated && game) {
    return (<LoginRequired game={game} />);
  }

  return (<div />);
};

JoinPoker.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  platform: PropTypes.shape({
    isPokerActive: PropTypes.bool.isRequired
  }).isRequired,
  user: {
    isAuthenticated: PropTypes.bool.isRequired
  }.isRequired
};

export default
connect(mapStateToProps)(
  JoinPoker
);
