import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CONTENT_WRAPPER, CONTENT_HEADER } from '../styles/global';
import LGNPokerLogo from './LGNPokerLogo';
import ClientSelectBrowserButton from './ClientSelectBrowserButton';
import ClientSelectNativeButton from './ClientSelectNativeButton';
import useMobileRedirect from '../hooks/useMobileRedirect';
import { isMacOS } from '../lib/browser';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    alignItems: 'center',
    flexDirection: 'column'
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  buttonsWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  playOnComputerButton: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 25,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 25
    }
  }
});

const PokerClientSelect = ({ classes }) => {
  useMobileRedirect();

  const searchParams = new URLSearchParams(window.location.search);
  const roomID = searchParams.get('id');
  const history = useHistory();

  if (isMacOS()) {
    history.push(`/games/poker?id=${roomID}`);
  }

  return (
    <div className={classes.wrapper}>
      <LGNPokerLogo />
      <div className={classes.detailsWrapper}>
        {roomID && (
          <p className={classes.header}>
            {`Game ID: ${roomID}`}
          </p>
        )}
      </div>
      <div className={classes.buttonsWrapper}>
        <ClientSelectBrowserButton />
        <ClientSelectNativeButton className={classes.playOnComputerButton} />
      </div>
    </div>
  );
};

PokerClientSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  PokerClientSelect
);
