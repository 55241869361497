import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CONTENT_WRAPPER, CONTENT_HEADER } from '../styles/global';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  }
});

const EmailVerification = ({ classes }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const successParamValue = searchParams.get('success');
  let text;

  if (successParamValue && successParamValue.toLowerCase() === 'true') {
    text = 'Thank you for verifying your email address!';
  } else if (successParamValue && successParamValue.toLowerCase() === 'false') {
    text = 'The verification token for this email address has expired or already been used.';
  } else {
    text = 'The verification service could not be run on this email address.';
  }

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>{text}</p>
    </div>
  );
};

EmailVerification.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  EmailVerification
);
