import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import marked from 'marked';
import { CONTENT_WRAPPER } from '../styles/global';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  }
});

const ReleaseNotes = ({ classes, markdownFileName }) => {
  const releaseNotesRef = useCallback((node) => {
    if (node) {
      fetch(`${process.env.PUBLIC_URL}/markdown/${markdownFileName}`)
        .then((response) => response.ok && response.text())
        .then((markdown) => {
          if (!markdown) {
            node.innerHTML = '<h1>No current release notes.</h1>'; // eslint-disable-line
            return;
          }

          node.innerHTML = marked(markdown); // eslint-disable-line
        });
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <div ref={releaseNotesRef} />
    </div>
  );
};

ReleaseNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  markdownFileName: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  ReleaseNotes
);
