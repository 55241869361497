import Hashes from 'jshashes';

export const getSanitizedFAQQuestionText = (questionText) => (
  questionText
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '-')
    .toLowerCase()
);

export const getRandomHexColorString = () => (
  `#${(0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)}`
);

export const getSuccessMessage = (message) => {
  if (message) {
    return `✅ ${message}`;
  }

  return '';
};

export const getErrorMessage = (message) => {
  if (message) {
    return `⚠️ ${message}`;
  }

  return '';
};

export const getSHA256Hash = (str) => (new Hashes.SHA256().hex(str));

export const isImage = (filePath) => {
  const filePathSegements = filePath.split('.');
  const fileExtension = filePathSegements[filePathSegements.length - 1];

  if (
    fileExtension === 'png'
    || fileExtension === 'jpg'
    || fileExtension === 'jpeg'
    || fileExtension === 'gif'
  ) {
    return true;
  }

  return false;
};
