import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_ROW, CONTENT_TEXT, ACTION_BUTTON
} from '../styles/global';
import CustomImage from '../containers/CustomImage';
import CustomButton from './CustomButton';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme),
    marginTop: 20
  },
  logo: {
    marginBottom: 25
  },
  row: {
    ...CONTENT_ROW(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  desktopButtons: {
    ...ACTION_BUTTON(theme)
  }
});

const ZoomWelcome = ({ classes }) => (
  <div className={classes.wrapper}>
    <Helmet>
      <meta
        content="noindex"
        name="robots"
      />
    </Helmet>
    <CustomImage
      className={classes.logo}
      src="/logo.png"
    />
    <p className={classes.header}>Welcome to Live Game Night!</p>
    <p className={classes.text}>
      You&apos;re probably used to playing LGN games on Zoom,
      but did you know you can also play on web?
    </p>
    <p className={classes.text}>
      Check out our game catalog to get started!
    </p>
    <CustomButton
      isInternalLinkButton
      linkPath="/games"
    >
      game catalog
    </CustomButton>
  </div>
);

ZoomWelcome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomWelcome
);
