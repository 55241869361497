import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT } from '../styles/global';
import LGNPokerLogo from './LGNPokerLogo';
import ClientSelectBrowserButton from './ClientSelectBrowserButton';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  button: {
    marginTop: 25
  }
});

const MacDeprecation = ({ classes }) => (
  <div className={classes.wrapper}>
    <LGNPokerLogo />
    <p className={classes.header}>MacOS Game Client Discontinuation Notice</p>
    <p className={classes.text}>
      The MacOS client is no longer available for download.
      Please use the LGN Poker web client instead for an even better experience!
    </p>
    <ClientSelectBrowserButton className={classes.button} />
  </div>
);

MacDeprecation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  MacDeprecation
);
