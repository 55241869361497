import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles, Menu, MenuItem } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = {
  wrapper: {
    cursor: 'pointer',
    width: 38
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  menuPaper: {
    borderRadius: 0
  },
  menuList: {
    width: 350,
    border: 'solid 1px rgba(255, 255, 255, 0.2)',
    backgroundColor: 'rgba(48, 48, 53, 1)',
  },
  menuItem: {
    padding: '10px 22px'
  },
  link: {
    color: '#d9d9d9',
    fontSize: 20,
    fontWeight: 100,
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  uppercaseLink: {
    textTransform: 'uppercase'
  }
};

const TopNavigation = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const authorizedMenuItems = [
      <MenuItem
        key="faq"
        className={classes.menuItem}
        onClick={handleClose}
      >
        <Link
          className={classNames(classes.link, classes.uppercaseLink)}
          target="blank"
          to="/faq"
        >
          faq
        </Link>
      </MenuItem>,
      <MenuItem
        key="discord"
        className={classes.menuItem}
        onClick={handleClose}
      >
        <a
          className={classes.link}
          href="https://discord.gg/cKGTqqd97r"
          target="blank"
        >
          discord
        </a>
      </MenuItem>,
      <MenuItem
        key="twitter"
        className={classes.menuItem}
        onClick={handleClose}
      >
        <a
          className={classes.link}
          href="https://twitter.com/LiveGameNight"
          target="blank"
        >
          twitter
        </a>
      </MenuItem>,
      <MenuItem
        key="facebook"
        className={classes.menuItem}
        onClick={handleClose}
      >
        <a
          className={classes.link}
          href="https://www.facebook.com/OfficialLiveGameNight"
          target="blank"
        >
          facebook
        </a>
      </MenuItem>,
      <MenuItem
        key="instagram"
        className={classes.menuItem}
        onClick={handleClose}
      >
        <a
          className={classes.link}
          href="https://www.instagram.com/livegamenight"
          target="blank"
        >
          instagram
        </a>
      </MenuItem>
    ];

    setMenuItems(authorizedMenuItems);
  }, []);

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.imageWrapper}
        onClick={handleClick}
      >
        <CustomImage
          alt="secondary header menu"
          src="/icons/overflow_menu.svg"
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        classes={{
          list: classes.menuList,
          paper: classes.menuPaper
        }}
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

TopNavigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  TopNavigation
);
