import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lgnLogo: {
    width: 250,
    height: 90,
    marginRight: 25,
    [theme.breakpoints.down('xs')]: {
      width: 130,
      height: 47,
      marginRight: 18
    }
  },
  zoomLogo: {
    borderLeft: 'solid 1px rgba(247, 248, 250, 1)',
    width: 350,
    height: 130,
    paddingLeft: 15,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 200,
      height: 70,
      paddingLeft: 8
    }
  },
});

const LGNZoomLogoTieUp = ({ classes }) => (
  <div className={classes.wrapper}>
    <CustomImage
      alt="LGN Logo"
      className={classes.lgnLogo}
      src="/logo.png"
    />
    <CustomImage
      alt="Zoom Logo"
      className={classes.zoomLogo}
      src="/zoom-logo.png"
    />
  </div>
);

LGNZoomLogoTieUp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  LGNZoomLogoTieUp
);
