import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 35,
    marginRight: 5
  },
  discordIcon: {
    borderRadius: 5,
    marginRight: 7
  }
});

const SocialLinks = ({ classes }) => (
  <div className={classes.wrapper}>
    <a
      className={classes.link}
      href="https://discord.gg/cKGTqqd97r"
      target="blank"
    >
      <CustomImage
        className={classNames(classes.icon, classes.discordIcon)}
        src="/discord.png"
      />
    </a>
    <a
      className={classes.link}
      href="https://twitter.com/LiveGameNight"
      target="blank"
    >
      <CustomImage
        className={classes.icon}
        src="/twitter.png"
      />
    </a>
    <a
      className={classes.link}
      href="https://www.facebook.com/OfficialLiveGameNight"
      target="blank"
    >
      <CustomImage
        className={classes.icon}
        src="/facebook.png"
      />
    </a>
    <a
      className={classes.link}
      href="https://www.facebook.com/OfficialLiveGameNight"
      target="blank"
    >
      <CustomImage
        className={classes.icon}
        src="/instagram.png"
      />
    </a>
  </div>
);

SocialLinks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  SocialLinks
);
