import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { TEXT_COLOR } from '../styles/palette';

const styles = (theme) => ({
  bulletsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '35px 0'
    }
  },
  bullet: {
    marginRight: 25,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0 0 0'
    }
  },
  bulletNumber: {
    display: 'inline-flex',
    marginRight: 12,
    height: 46,
    width: 46,
    borderRadius: 46,
    fontSize: 28,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 38,
      width: 38,
      fontSize: 24
    }
  },
  bulletText: {
    fontSize: 28,
    fontWeight: 'bold',
    color: TEXT_COLOR,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24
    }
  },
  darkBulletText: {
    color: 'black'
  }
});

const GetStartedBullets = ({
  classes, game, useSolidColor, useDarkNumbers, useBackgroundStyle, className
}) => {
  const getPrimaryColor = () => {
    if (game.slug === 'poker') {
      return '#0a4ca7';
    }

    return game.colors[0];
  };

  return (
    <div
      className={classNames(classes.bulletsWrapper, className)}
      style={{ backgroundColor: useBackgroundStyle && game.colors[1] }}
    >
      <div className={classes.bullet}>
        <span
          className={classNames(classes.bulletNumber, useDarkNumbers && classes.darkBulletText)}
          style={{ backgroundColor: getPrimaryColor() }}
        >
          1
        </span>
        <span
          className={classes.bulletText}
          style={{ color: useSolidColor && getPrimaryColor() }}
        >
          Host a Room
        </span>
      </div>
      <div className={classes.bullet}>
        <span
          className={classNames(classes.bulletNumber, useDarkNumbers && classes.darkBulletText)}
          style={{
            backgroundColor: getPrimaryColor(),
            color: useDarkNumbers && classes.darkBulletText
          }}
        >
          2
        </span>
        <span
          className={classes.bulletText}
          style={{ color: useSolidColor && getPrimaryColor() }}
        >
          Invite Your Buddies
        </span>
      </div>
      <div className={classes.bullet}>
        <span
          className={classNames(classes.bulletNumber, useDarkNumbers && classes.darkBulletText)}
          style={{
            backgroundColor: getPrimaryColor(),
            color: useDarkNumbers && classes.darkBulletText
          }}
        >
          3
        </span>
        <span
          className={classes.bulletText}
          style={{ color: useSolidColor && getPrimaryColor() }}
        >
          Play live!
        </span>
      </div>
    </div>
  );
};

GetStartedBullets.defaultProps = {
  className: '',
  useBackgroundStyle: true,
  useDarkNumbers: false,
  useSolidColor: false
};

GetStartedBullets.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  game: PropTypes.shape({
    colors: PropTypes.arrayOf(
      PropTypes.string.isRequired
    ).isRequired,
    media: {
      logo: PropTypes.string.isRequired,
      videos: PropTypes.arrayOf(
        PropTypes.string.isRequired
      ).isRequired
    },
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired,
  useBackgroundStyle: PropTypes.bool,
  useDarkNumbers: PropTypes.bool,
  useSolidColor: PropTypes.bool
};

export default
withStyles(styles)(
  GetStartedBullets
);
