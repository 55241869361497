import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles, TextField } from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import {
  FORM_WRAPPER, FORM, CONTENT_HEADER, CONTENT_TEXT
} from '../styles/global';
import { sendPasswordResetEmail as sendPasswordResetEmailAction } from '../actions/user';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getServiceError } from '../lib/http';
import CustomButton from '../components/CustomButton';
import { getGames as getGamesAction } from '../actions/game';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import { getErrorMessage } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...FORM_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  form: {
    ...FORM(theme)
  },
  button: {
    width: 290
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  games: state.games
});

const mapDispatchToProps = (dispatch) => ({
  sendPasswordResetEmail: (email, game) => dispatch(sendPasswordResetEmailAction(email, game)),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  getGames: () => dispatch(getGamesAction())
});

const ForgotPassword = ({
  classes, games, sendPasswordResetEmail, showGlobalSnackbar, getGames
}) => {
  const [email, setEmail] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  // TODO: Remove the || 7 once game_id starts getting passed in the query string.
  const gameID = searchParams.get('game_id') || 7;
  let game;

  useEffect(() => {
    if (!games.length) {
      getGames();
    }
  });

  const handleClick = () => {
    if (!email || !EmailValidator.validate(email)) {
      showGlobalSnackbar(getErrorMessage('Please enter a valid email address.'));
      return;
    }

    if (!gameID) {
      // If not a specific game, spoof a game object to pass the LGN Portal "game id."
      game = { id: 1 };
    } else {
      game = games.find((g) => g.id === parseInt(gameID, 10));
    }

    sendPasswordResetEmail(email, game)
      .then(() => history.push('/forgot-password/confirmation'))
      .catch((error) => showGlobalSnackbar(getServiceError(error)));
  };

  if (!games.length) {
    return (<GlobalProgressSpinner />);
  }

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>Forgot your password?</p>
      <p className={classes.text}>
        Enter your email address below to receive an email with a link to reset your password.
      </p>
      <form
        className={classes.form}
        onSubmit={(e) => { e.preventDefault(); }}
      >
        <TextField
          autoFocus
          label="Email Address"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          variant="filled"
        />
        <CustomButton
          className={classes.button}
          onClick={handleClick}
          type="submit"
        >
          Send Reset Email
        </CustomButton>
      </form>
    </div>
  );
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  getGames: PropTypes.func.isRequired,
  sendPasswordResetEmail: PropTypes.func.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    ForgotPassword
  )
);
