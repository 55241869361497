import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { HEADER_HEIGHT } from '../styles/global';

const styles = () => ({
  wrapper: {
    display: 'flex',
    height: HEADER_HEIGHT - 25,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 40px',
    color: '#d9d9d9',
    height: '100%',
    fontSize: 20,
    textTransform: 'capitalize',
    borderLeft: 'solid 1px rgba(255, 255, 255, 0.2)',
    borderRight: 'solid 1px rgba(255, 255, 255, 0.2)',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:last-child': {
      marginRight: 10
    }
  }
});

const PrimaryNavigation = ({ classes }) => (
  <div className={classes.wrapper}>
    <Link
      className={classes.link}
      to="/games"
    >
      games
    </Link>
    <Link
      className={classes.link}
      target="_blank"
      to="/faq"
    >
      Help
    </Link>
  </div>
);

PrimaryNavigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  PrimaryNavigation
);
