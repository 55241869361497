import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT,
  CONTENT_ROW
} from '../styles/global';
import BrowserButton from './BrowserButton';
import Browser from '../enums/browser';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  linkButton: {
    width: 180,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15
    }
  },
  row: {
    ...CONTENT_ROW(theme),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
});

const Unsupported = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>We&apos;re sorry, but this browser is not supported.</p>
    <p className={classes.text}>
      To play on the web, please download one of the following modern browsers
    </p>
    <div className={classes.row}>
      <BrowserButton
        browser={Browser.EDGE}
        className={classes.linkButton}
        linkPath="https://www.microsoft.com/en-us/edge?form=MA13DE&OCID=MA13DE"
      />
      <BrowserButton
        browser={Browser.FIREFOX}
        className={classes.linkButton}
        linkPath="https://www.mozilla.org/firefox/new/"
      />
      <BrowserButton
        browser={Browser.CHROME}
        className={classes.linkButton}
        linkPath="https://www.google.com/chrome/"
      />
    </div>
  </div>
);

Unsupported.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Unsupported
);
