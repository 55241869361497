import {
  UpdateLocalUser, Login, LoginComplete,
  LoginError, LogoutComplete, LoginGuest,
  LoginGuestComplete
} from '../enums/action-type';

const initialState = {
  username: '',
  email: '',
  userID: null,
  isAuthenticated: false,
  isAuthenticating: false,
  sessionID: '',
  isGuest: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Login:
    case LoginGuest: {
      return {
        ...state,
        isAuthenticating: true
      };
    }
    case UpdateLocalUser: {
      return {
        ...state,
        ...action.payload
      };
    }
    case LoginComplete:
    case LoginGuestComplete: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false
      };
    }
    case LoginError: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false
      };
    }
    case LogoutComplete: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
