import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  setVideoInputDevices, setAudioOutputDevices, setAudioInputDevices,
  setVideoInputDevice, setAudioOutputDevice, setAudioInputDevice,
  setIsLoadingDevices
} from '../actions/settings';
import { setHasMediaPermissions, setIsCheckingMediaPermissions } from '../actions/platform';
import {
  VIDEO_INPUT_DEVICE, AUDIO_OUTPUT_DEVICE, AUDIO_INPUT_DEVICE
} from '../enums/local-storage';
import { sendTrackingEvent } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const useDevices = () => {
  const settings = useSelector((state) => state.settings);
  const games = useSelector((state) => state.games);
  const user = useSelector((state) => state.user);
  const platform = useSelector((state) => state.platform);
  const dispatch = useDispatch();
  const location = useLocation();

  const isStoredDeviceAvailable = (devices, storedDeviceId) => {
    if (devices.length && storedDeviceId) {
      const device = devices.find((d) => d.deviceId === storedDeviceId);
      return !!(device);
    }

    return false;
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(setIsCheckingMediaPermissions(true));

      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then(() => {
          // Only track MEDIA_PERMISSIONS_ALLOWED if they were previously not allowed
          // to avoid duplicate tracking, as this block is hit every time a component
          // that implements useDevices is loaded if permissions have already been granted.
          if (!platform.hasMediaPermissions) {
            dispatch(sendTrackingEvent(TrackingEvent.MEDIA_PERMISSIONS_ALLOWED, location.pathname));
          }

          dispatch(setHasMediaPermissions(true));
        })
        .catch(() => {
          dispatch(setHasMediaPermissions(false));
          dispatch(sendTrackingEvent(TrackingEvent.MEDIA_PERMISSIONS_DENIED, location.pathname));
        })
        .finally(() => dispatch(setIsCheckingMediaPermissions(false)));
    }
  }, [games.length, user.isAuthenticated]);

  // Determine whether the user's lists of available devices has been captured.
  // If not, query the devices and use the results to popuplate the appropriate
  // global "settings" state's video input, audio input, and audio output device lists.
  useEffect(() => {
    if (
      platform.hasMediaPermissions
      && (
        !settings.videoInputDevices.length
        || !settings.audioInputDevices.length
        || !settings.audioOutputDevices.length
      )
    ) {
      dispatch(setIsLoadingDevices(true));

      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          dispatch(setVideoInputDevices(devices));
          dispatch(setAudioInputDevices(devices));
          dispatch(setAudioOutputDevices(devices));
        })
        .finally(() => dispatch(setIsLoadingDevices(false)));
    }
  }, [platform.hasMediaPermissions]);

  // Set the currently selected user devices. If a device ID was previously stored in
  // the browser's local storage, use that device. If the previously stored device no longer
  // exists or was never stored, use the first available device.
  useEffect(() => {
    if (platform.hasMediaPermissions) {
      // Video input devices.
      if (settings.videoInputDevices.length) {
        const storedVideoInputDeviceId = localStorage.getItem(VIDEO_INPUT_DEVICE);
        const deviceId = isStoredDeviceAvailable(settings.videoInputDevices, storedVideoInputDeviceId) // eslint-disable-line
          ? storedVideoInputDeviceId
          : settings.videoInputDevices[0].deviceId;

        dispatch(setVideoInputDevice(deviceId));
      }

      // Audio input devices.
      if (settings.audioInputDevices.length) {
        const storedAudioInputDeviceId = localStorage.getItem(AUDIO_INPUT_DEVICE);

        const deviceId = isStoredDeviceAvailable(settings.audioInputDevices, storedAudioInputDeviceId) // eslint-disable-line
          ? storedAudioInputDeviceId
          : settings.audioInputDevices[0].deviceId;

        dispatch(setAudioInputDevice(deviceId));
      }

      // Audio output devices.
      if (settings.audioOutputDevices.length) {
        const storedAudioOutputDeviceId = localStorage.getItem(AUDIO_OUTPUT_DEVICE);

        const deviceId = isStoredDeviceAvailable(settings.audioOutputDevices, storedAudioOutputDeviceId) // eslint-disable-line
          ? storedAudioOutputDeviceId
          : settings.audioOutputDevices[0].deviceId;

        dispatch(setAudioOutputDevice(deviceId));
      }
    }
  }, [
    settings.videoInputDevices.length,
    settings.audioInputDevices.length,
    settings.audioOutputDevices.length,
    platform.hasMediaPermissions
  ]);
};

export default useDevices;
