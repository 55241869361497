import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_SUB_HEADER, CONTENT_TEXT
} from '../styles/global';
import LGNPokerLogo from './LGNPokerLogo';
import WindowsButton from './WindowsButton';
import AppStoreButton from './AppStoreButton';
import PlayStoreButton from './PlayStoreButton';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  subheader: {
    ...CONTENT_SUB_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  button: {
    width: 300,
    marginBottom: 25,
    '&:last-child': {
      marginBottom: 0
    }
  }
});

const Downloads = ({ classes }) => (
  <div className={classes.wrapper}>
    <LGNPokerLogo />
    <p className={classes.header}>App Downloads</p>
    <p className={classes.text}>
      Installable versions of LGN Poker are available for Windows desktop and mobile.
    </p>
    <p className={classes.subheader}>Desktop</p>
    <WindowsButton imageClassName={classes.button} />
    <p className={classes.subheader}>Mobile</p>
    <AppStoreButton className={classes.button} />
    <PlayStoreButton className={classes.button} />
  </div>
);

Downloads.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Downloads
);
