import { GetGamesComplete } from '../enums/action-type';

export default function (state = [], action) {
  switch (action.type) {
    case GetGamesComplete:
      return action.games;
    default:
      return state;
  }
}
