import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INNER_CONTENT_WRAPPER, FOCUSED_SECTION
} from '../styles/global';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'initial'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme)
  },
  problemWrapper: {
    ...FOCUSED_SECTION(theme),
    marginBottom: 25,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 18
    }
  },
  label: {
    fontSize: 24,
    marginBottom: 8,
    opacity: 0.8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  problemLabel: {
    color: 'red',
  },
  solutionLabel: {
    color: 'green'
  }
});

const ZoomUserGuideTroubleshooting = ({ classes }) => (
  <section
    className={classes.innerWrapper}
    id="troubleshooting"
  >
    <p className={classes.header}>Troubleshooting Common Issues</p>
    <div className={classes.problemWrapper}>
      <p className={classNames(classes.text, classes.label, classes.problemLabel)}>Problem</p>
      <p className={classes.text}>
        The LGN game tells you that you need to be in a meeting to play.
      </p>
      <p className={classNames(classes.text, classes.label, classes.solutionLabel)}>Solution</p>
      <p className={classes.text}>
        Zoom has two &quot;Apps&quot; tabs. The first is available in the
        Zoom app before starting a meeting, the second is available in the
        meeting window after starting a meeting. Be sure to open your selected LGN game
        from the Apps tab <i>within</i> the meeting window.
      </p>
    </div>
    <div className={classes.problemWrapper}>
      <p className={classNames(classes.text, classes.label, classes.problemLabel)}>Problem</p>
      <p className={classes.text}>
        Players hear an echo during gameplay.
      </p>
      <p className={classNames(classes.text, classes.label, classes.solutionLabel)}>Solution</p>
      <p className={classes.text}>
        Feedback loops can occur when a player&apos;s microphone picks up the
        sound from the player&apos;s device speakers. Using headphones is a good
        way to ensure audio quality and prevent echos.
      </p>
    </div>
    <div className={classes.problemWrapper}>
      <p className={classNames(classes.text, classes.label, classes.problemLabel)}>Problem</p>
      <p className={classes.text}>
        &quot;Logging in...&quot; appears on the screen, but the game never loads.
      </p>
      <p className={classNames(classes.text, classes.label, classes.solutionLabel)}>Solution</p>
      <p className={classes.text}>
        The LGN game might be blocked by your organization&apos;s firewall.
        Please contact your system administrator about allowing access to play.livegamenight.com.
      </p>
    </div>
    <div className={classes.problemWrapper}>
      <p className={classNames(classes.text, classes.label, classes.problemLabel)}>Problem</p>
      <p className={classes.text}>
        I&apos;m on a VPN and having trouble playing LGN games.
      </p>
      <p className={classNames(classes.text, classes.label, classes.solutionLabel)}>Solution</p>
      <p className={classes.text}>
        Certain firewalls and VPNs may prevent LGN games from operating properly.
        If you encounter problems, please ensure you have disabled firewalls or VPNs
        prior to running any LGN game.
      </p>
    </div>
  </section>
);

ZoomUserGuideTroubleshooting.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideTroubleshooting
);
