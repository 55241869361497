import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, TextField } from '@material-ui/core';
import {
  FORM_WRAPPER, FORM, CONTENT_HEADER
} from '../styles/global';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import {
  register as registerAction,
  login as loginAction,
  logout as logoutAction,
  createGuestAccount as createGuestAccountAction,
  loginGuest as loginGuestAction,
  saveGuestAccount as saveGuestAccountAction
} from '../actions/user';
import { ENTER } from '../enums/keyboard';
import CustomButton from '../components/CustomButton';
import { getServiceError } from '../lib/http';
import GlobalPrgressSpinner from '../components/GlobalProgressSpinner';
import { hideLoginDialog as hideLoginDialogAction } from '../actions/login-dialog';
import { getErrorMessage, getSuccessMessage } from '../lib/string';
import AgeVerificationCheckbox from '../components/AgeVerificationCheckbox';
import { REGISTER_BUTTON } from '../enums/element-id';
import { GUEST_SESSION_ID, GUEST_USER_ID, SESSION_ID } from '../enums/local-storage';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const styles = (theme) => ({
  wrapper: {
    ...FORM_WRAPPER(theme),
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  form: {
    ...FORM(theme)
  },
  link: {
    marginTop: 25
  }
});

const mapStateToProps = (state) => ({
  user: state.user,
  loginDialog: state.loginDialog,
  platform: state.platform
});

const mapDispatchToProps = (dispatch) => ({
  register: (username, email, password) => dispatch(registerAction(username, email, password)),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  login: (email, password) => dispatch(loginAction(email, password)),
  logout: () => dispatch(logoutAction()),
  hideLoginDialog: () => dispatch(hideLoginDialogAction()),
  createGuestAccount: () => dispatch(createGuestAccountAction()),
  loginGuest: (userId) => dispatch(loginGuestAction(userId)),
  saveGuestAccount: (username, email, password) => dispatch(saveGuestAccountAction(username, email, password)), // eslint-disable-line
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const Register = ({
  classes, className, showGlobalSnackbar,
  register, login, logout,
  showFormHeader, hideLoginDialog, user,
  saveGuestAccount, sendTrackingEvent
}) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [ageVerified, setAgeVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [hasAgeError, setHasAgeError] = useState(false);
  const [hasConfirmPasswordError, setHasConfirmPasswordError] = useState(false);
  const location = useLocation();

  const logoutOrContinue = () => {
    if (user.isGuest) {
      return Promise.resolve();
    }

    return logout();
  };

  const handleRegister = () => {
    setHasUsernameError(!username);
    setHasEmailError(!email);
    setHasPasswordError(!password);
    setHasConfirmPasswordError(!confirmPassword);
    setHasAgeError(!ageVerified);

    if (!username || !email || !password || !confirmPassword) {
      showGlobalSnackbar(getErrorMessage('All fields are required.'));
      return;
    }

    if (password !== confirmPassword) {
      showGlobalSnackbar(getErrorMessage('Passwords don\'t match.'));

      setHasPasswordError(true);
      setHasConfirmPasswordError(true);

      return;
    }

    if (!ageVerified) {
      showGlobalSnackbar(getErrorMessage('Age verification incomplete.'));

      setHasAgeError(true);

      return;
    }

    sendTrackingEvent(TrackingEvent.REGISTER_CLICK, location.pathname);

    setIsLoading(true);

    logoutOrContinue()
      .then(() => {
        if (user.isGuest) {
          return saveGuestAccount(username, email, password);
        }

        return register(username, email, password);
      })
      .then(() => {
        // Guests don't need to be logged in again after having their account converted
        // to a registered user.
        if (!user.isGuest) {
          return login(email, password);
        }

        // Convert the guest's session ID to the newly registered user's session ID
        // in local storage.
        const storedGuestSessionId = localStorage.getItem(GUEST_SESSION_ID);
        localStorage.setItem(SESSION_ID, storedGuestSessionId);

        // If the newly registered user was a guest, clear the guest's ID from local
        // storage so a new guest can be registered in the future.
        localStorage.removeItem(GUEST_SESSION_ID);
        localStorage.removeItem(GUEST_USER_ID);

        return Promise.resolve();
      })
      .then(() => {
        hideLoginDialog();
        showGlobalSnackbar(getSuccessMessage('Account registration successful!'));
      })
      .catch((error) => {
        sendTrackingEvent(TrackingEvent.REGISTER_ERROR, error.message);
        showGlobalSnackbar(getServiceError(error));

        if (error && error.message && error.message.includes('email')) {
          setHasEmailError(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleKeyup = (event) => {
    const key = event.keyCode;

    if (key === ENTER) {
      handleRegister();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keyup', handleKeyup);
    };
  });

  return (
    <div className={classNames(classes.wrapper, className)}>
      {showFormHeader && (
        <p className={classes.header}>Create Your LGN Account</p>
      )}
      <form className={classes.form}>
        <TextField
          className={classes.input}
          InputProps={{
            className: hasUsernameError && 'has-error'
          }}
          label="Username"
          onChange={(event) => setUsername(event.target.value)}
          value={username}
          variant="filled"
        />
        <TextField
          className={classes.input}
          InputProps={{
            className: hasEmailError && 'has-error'
          }}
          label="Email"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          variant="filled"
        />
        <TextField
          className={classes.input}
          InputProps={{
            className: hasPasswordError && 'has-error'
          }}
          label="Password"
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          value={password}
          variant="filled"
        />
        <TextField
          className={classes.input}
          InputProps={{
            className: hasConfirmPasswordError && 'has-error'
          }}
          label="Confirm Password"
          onChange={(event) => setConfirmPassword(event.target.value)}
          type="password"
          value={confirmPassword}
          variant="filled"
        />
        <AgeVerificationCheckbox
          checked={ageVerified}
          hasError={hasAgeError}
          onChange={setAgeVerified}
        />
        <CustomButton
          id={REGISTER_BUTTON}
          onClick={() => handleRegister()}
        >
          Register
        </CustomButton>
      </form>
      {isLoading && (
        <GlobalPrgressSpinner />
      )}
    </div>
  );
};

Register.defaultProps = {
  className: '',
  showFormHeader: true
};

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  hideLoginDialog: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loginDialog: PropTypes.shape({
    show: PropTypes.bool.isRequired
  }).isRequired,
  logout: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  saveGuestAccount: PropTypes.func.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
  showFormHeader: PropTypes.bool,
  showGlobalSnackbar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isGuest: PropTypes.bool.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    Register
  )
);
