import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Header from '../components/Header';
import Routes from '../components/Routes';
import Footer from './Footer';
import GlobalSnackbar from './GlobalSnackbar';
import {
  login as loginAction,
  loginGuest as loginGuestAction,
  logout as logoutAction
} from '../actions/user';
import { getHost as getHostAction } from '../actions/host';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { initKitsuneAPI as initKitsuneAPIAction } from '../actions/kitsune';
import { showSettingsDialog as showSettingsDialogAction } from '../actions/settings';
import {
  hideLoginDialog as hideLoginDialogAction,
  showLoginDialog as showLoginDialogAction
} from '../actions/login-dialog';
import CookieBanner from '../components/CookieBanner';
import {
  ALLOW_COOKIES, REFERRING_NETWORK, SESSION_ID,
  USER_ID, DEVICE_ID, GUEST_USER_ID,
  GUEST_SESSION_ID
} from '../enums/local-storage';
import { LGN_PASSCODE } from '../enums/cookie';
import MediaPlatformSettings from './MediaPlatformSettings';
import LoginDialog from './LoginDialog';
import { getErrorMessage } from '../lib/string';
import PasscodeModal from '../components/PasscodeModal';
import { isQatest, isPlaytest, shouldSkipLogin } from '../lib/env';
import ReauthDialog from '../components/ReauthDialog';
import Route from '../enums/route';
import LoginDialogTab from '../enums/login-dialog-tab';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    backgroundColor: '#000',
    flex: 1
  }
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  loginDialog: state.loginDialog,
  user: state.user,
  host: state.host
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(loginAction(username, password)),
  getHost: () => dispatch(getHostAction()),
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message)),
  initKitsuneAPI: () => dispatch(initKitsuneAPIAction()),
  showSettingsDialog: (showDialog) => dispatch(showSettingsDialogAction(showDialog)),
  hideLoginDialog: () => dispatch(hideLoginDialogAction()),
  loginGuest: (userId) => dispatch(loginGuestAction(userId)),
  logout: () => dispatch(logoutAction()),
  showLoginDialog: (tab) => dispatch(showLoginDialogAction(tab)),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const App = ({
  classes, login, getHost,
  showGlobalSnackbar, initKitsuneAPI, settings,
  showSettingsDialog, hideLoginDialog, loginDialog,
  loginGuest, logout, user,
  showLoginDialog, sendTrackingEvent, host
}) => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [showPasscodeModal, setShowPasscodeModal] = useState(false);
  const [showReauthDialog, setShowReauthDialog] = useState(false);
  const [disconnectReason, setDisconnectReason] = useState('');
  const location = useLocation();

  const trackUserNetwork = () => {
    const localStorageNetwork = localStorage.getItem(REFERRING_NETWORK);

    // Don't overwrite the existing network value if it exists.
    if (localStorageNetwork) return;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const network = params.get('network');

    if (network) {
      localStorage.setItem(REFERRING_NETWORK, network);
    }
  };

  const handleAutoLogin = () => {
    // Track the network used, if any, to deliver the user to the portal.
    trackUserNetwork();

    // We eventually need a better way to handle this, but for now skip logging
    // in if the user is on a UI that is opened in a new tab.
    // (Opening a new tab breaks the web socket that is tracking the user session if the
    // user returns to the original opener page, causing the UI to hang.)
    if (!shouldSkipLogin()) {
      const sessionId = localStorage.getItem(SESSION_ID);
      const guestSessionId = localStorage.getItem(GUEST_SESSION_ID);
      const userId = localStorage.getItem(USER_ID);
      const guestUserId = localStorage.getItem(GUEST_USER_ID);
      const deviceId = localStorage.getItem(DEVICE_ID);

      if (deviceId && (sessionId || guestSessionId) && (userId || guestUserId)) {
        if (userId) {
          return login();
        }

        return loginGuest(guestUserId);
      }

      // If the deviceId, sessionsId, or userId are missing in local storage, call
      // logout to clear all stored user related values to avoid login attempts with
      // insufficient user info.
      return logout();
    }

    return Promise.resolve();
  };

  const handleReauth = () => (
    handleAutoLogin()
      .catch((error) => showGlobalSnackbar(getErrorMessage(error.message)))
      .finally(() => setShowReauthDialog(false))
  );

  const showHeader = () => {
    const { pathname } = window.location;
    return pathname !== Route.POKER_AFFILIATE;
  };

  const showFooter = () => {
    const { pathname } = window.location;
    return pathname !== Route.POKER_AFFILIATE;
  };

  useEffect(() => {
    getHost()
      .then(() => initKitsuneAPI())
      .then(() => {
        setIsAppInitialized(true);
        return handleAutoLogin();
      })
      .catch((error) => {
        // Handle clearing stale accounts on qatest; otherwise, just show the error dialog.
        if (isQatest()) {
          logout();
          showLoginDialog(LoginDialogTab.GUEST);
        } else {
          showGlobalSnackbar(getErrorMessage(error.message));
        }
      });
  }, []);

  useEffect(() => {
    const allowCookiesLocalStorage = localStorage.getItem(ALLOW_COOKIES);
    const { pathname } = location;

    if (
      !allowCookiesLocalStorage
      && pathname !== Route.FORGOT_PASSWORD
      && pathname !== Route.FORGOT_PASSWORD_CONFIRMATION
      && pathname !== Route.PASSWORD_RESET
      && pathname !== Route.PASSWORD_RESET_CONFIRMATION
    ) {
      setShowCookieBanner(true);
    }
  }, [location.pathname]);

  // Require a passcode if the portal is being accessed on qatest or playtest
  // and no LGN_PASSCODE cookie is present.
  useEffect(() => {
    if (isQatest() || isPlaytest()) {
      if (!document.cookie.includes(LGN_PASSCODE)) {
        setShowPasscodeModal(true);
      }
    }
  }, []);

  // Subscribe to the Kistune DISCONNECTED event to be notified when a player is
  // disconnected so we can give them the option of logging back in.
  useEffect(() => {
    if (isAppInitialized) {
      window.Kitsune.subscribe(window.Kitsune.ConnectionEvent.DISCONNECTED, (err, info) => {
        if (info) {
          setDisconnectReason(info.message);
          setShowReauthDialog(true);
        }
      });
    }
  }, [isAppInitialized]);

  // Track individual page loads.
  useEffect(() => {
    if (host) {
      sendTrackingEvent(TrackingEvent.PAGE_LOAD, location.pathname);
    }
  }, [location, host]);

  const handlePasscodeSuccess = () => {
    setShowPasscodeModal(false);
  };

  if (showPasscodeModal) {
    return (<PasscodeModal onSuccess={handlePasscodeSuccess} />);
  }

  return (
    <div className={classes.wrapper}>
      {showHeader() && <Header />}
      {(isAppInitialized && !user.isAuthenticating) ? (
        <>
          <Routes className={classes.content} />
          {showFooter() && <Footer />}
        </>
      ) : (
        <GlobalProgressSpinner />
      )}
      <GlobalSnackbar />
      {showCookieBanner && (
        <CookieBanner onClick={() => setShowCookieBanner(false)} />
      )}
      {settings.showDialog && (
        <MediaPlatformSettings
          onClose={() => showSettingsDialog(false)}
          open={settings.showDialog}
        />
      )}
      {loginDialog.show && (
        <LoginDialog
          onCancel={hideLoginDialog}
          open={loginDialog.show}
        />
      )}
      {showReauthDialog && (
        <ReauthDialog
          disconnectReason={disconnectReason}
          onAccept={handleReauth}
          open={showReauthDialog}
        />
      )}
    </div>
  );
};

App.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired
  }).isRequired,
  getHost: PropTypes.func.isRequired,
  hideLoginDialog: PropTypes.func.isRequired,
  host: PropTypes.string.isRequired,
  initKitsuneAPI: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loginDialog: PropTypes.shape({
    show: PropTypes.bool.isRequired
  }).isRequired,
  loginGuest: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    showDialog: PropTypes.bool.isRequired
  }).isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired,
  showLoginDialog: PropTypes.func.isRequired,
  showSettingsDialog: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticating: PropTypes.bool.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    App
  )
);
