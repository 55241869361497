// Global Snackbar
export const ShowGlobalSnackbar = 'SHOW_GLOBAL_SNACKBAR';
export const HideGlobalSnackbar = 'HIDE_GLOBAL_SNACKBAR';

// User
export const UpdateLocalUser = 'UPDATE_LOCAL_USER';
export const Register = 'REGISTER';
export const RegisterComplete = 'REGISTER_COMPLETE';
export const RegisterError = 'REGISTER_ERROR';
export const Login = 'LOGIN';
export const LoginComplete = 'LOGIN_COMPLETE';
export const LoginError = 'LOGIN_ERROR';
export const SetNewPassword = 'SET_NEW_PASSWORD';
export const SetNewPasswordComplete = 'SET_NEW_PASSWORD_COMPLETE';
export const SetNewPasswordError = 'SET_NEW_PASSWORD_ERROR';
export const SendPasswordResetEmail = 'SEND_PASSWORD_RESET_EMAIL';
export const SendPasswordResetEmailComplete = 'SEND_PASSWORD_RESET_EMAIL_COMPLETE';
export const SendPasswordResetEmailError = 'SEND_PASSWORD_RESET_EMAIL_ERROR';
export const Logout = 'LOGOUT';
export const LogoutComplete = 'LOGOUT_COMPLETE';
export const LogoutError = 'LOGOUT_ERROR';
export const GetPlayerProfile = 'GET_PLAYER_PROFILE';
export const GetPlayerProfileComplete = 'GET_PLAYER_PROFILE_COMPLETE';
export const GetPlayerProfileError = 'GET_PLAYER_PROFILE_ERROR';
export const CreateGuestAccount = 'CREATE_GUEST_ACCOUNT';
export const CreateGuestAccountComplete = 'CREATE_GUEST_ACCOUNT_COMPLETE';
export const CreateGuestAccountError = 'CREATE_GUEST_ACCOUNT_ERROR';
export const LoginGuest = 'LOGIN_GUEST';
export const LoginGuestComplete = 'LOGIN_GUEST_COMPLETE';
export const LoginGuestError = 'LOGIN_GUEST_ERROR';
export const SaveGuestAccount = 'SAVE_GUEST_ACCOUNT';
export const SaveGuestAccountComplete = 'SAVE_GUEST_ACCOUNT_COMPLETE';
export const SaveGuestAccountError = 'SAVE_GUEST_ACCOUNT_ERROR';

// Payment
export const InitiatePayment = 'INITIATE_PAYMENT';
export const InitiatePaymentComplete = 'INITIATE_PAYMENT_COMPLETE';
export const InitiatePaymentError = 'INITIATE_PAYMENT_ERROR';
export const CompleteCreditCardPayment = 'COMPLETE_CREDIT_CARD_PAYMENT';
export const CompleteCreditCardPaymentComplete = 'COMPLETE_CREDIT_CARD_PAYMENT_COMPLETE';
export const CompleteCreditCardPaymentError = 'COMPLETE_CREDIT_CARD_PAYMENT_ERROR';
export const PurchaseProduct = 'PURCHASE_PRODUCT';
export const PurchaseProductComplete = 'PURCHASE_PRODUCT_COMPLETE';
export const PurchaseProductError = 'PURCHASE_PRODUCT_ERROR';

// Host
export const GetHost = 'GET_HOST';
export const GetHostComplete = 'GET_HOST_COMPLETE';
export const GetHostError = 'GET_HOST_ERROR';

// Kitsune
export const InitKitsuneAPI = 'INIT_KITSUNE_API';
export const InitKitsuneAPIComplete = 'INIT_KITSUNE_API_COMPLETE';
export const InitKitsuneAPIError = 'INIT_KITSUNE_API_ERROR';

// Products
export const GetProducts = 'GET_PRODUCTS';
export const GetProductsComplete = 'GET_PRODUCTS_COMPLETE';
export const GetProductsError = 'GET_PRODUCTS_ERROR';

// Games
export const GetGames = 'GET_GAMES';
export const GetGamesComplete = 'GET_GAMES_COMPLETE';
export const GetGamesError = 'GET_GAMES_ERROR';

// Settings
export const SetEnableVideoOnJoinGame = 'SET_ENABLE_VIDEO_ON_JOIN_GAME';
export const SetEnableAudioOnJoinGame = 'SET_ENABLE_AUDIO_ON_JOIN_GAME';
export const SetVideoEnabled = 'SET_VIDEO_ENABLED';
export const SetAudioEnabled = 'SET_AUDIO_ENABLED';
export const SetVideoInputDevice = 'SET_VIDEO_INPUT_DEVICE';
export const SetVideoInputDevices = 'SET_VIDEO_INPUT_DEVICES';
export const SetAudioInputDevice = 'SET_AUDIO_INPUT_DEVICE';
export const SetAudioInputDevices = 'SET_AUDIO_INPUT_DEVICES';
export const SetAudioOutputDevice = 'SET_AUDIO_OUTPUT_DEVICE';
export const SetAudioOutputDevices = 'SET_AUDIO_OUTPUT_DEVICES';
export const ShowSettingsDialog = 'SHOW_SETTINGS_DIALOG';
export const SetHasSeenPlatformSettings = 'SET_HAS_SEEN_PLATFORM_SETTINGS';
export const SetShowSettingsOnLoad = 'SET_SHOW_SETTINGS_ON_LOAD';
export const SetShowSettingsOnLoadComplete = 'SET_SHOW_SETTINGS_ON_LOAD_COMPLETE';
export const SetIsLoadingDevices = 'SET_IS_LOADING_DEVICES';

// Login Dialog
export const ShowLoginDialog = 'SHOW_LOGIN_DIALOG';
export const HideLoginDialog = 'HIDE_LOGIN_DIALOG';
export const SetLoginDialogTab = 'SET_LOGIN_DIALOG_TAB';

// Config
export const GetClientConfig = 'GET_CLIENT_CONFIG';
export const GetClientConfigSuccess = 'GET_CLIENT_CONFIG_SUCCESS';
export const GetClientConfigError = 'GET_CLIENT_CONFIG_ERROR';

// Permissions Dialog
export const ShowPermissionsDialog = 'SHOW_PERMISSIONS_DIALOG';
export const HidePermissionsDialog = 'HIDE_PERMISSIONS_DIALOG';

// LGN Video Library
export const SetHasLoadedLGNVideoLibrary = 'SET_HAS_LOADED_LGN_VIDEO_LIBRARY';

// Media Platform
export const SetIsMediaPlatformActive = 'SET_IS_MEDIA_PLATFORM_ACTIVE';
export const SetHasMediaPermissions = 'SET_HAS_MEDIA_PERMISSIONS';
export const SetIsPokerActive = 'SET_IS_POKER_ACTIVE';
export const SetIsCheckingMediaPermissions = 'SET_IS_CHECKING_MEDIA_PERMISSIONS';

// Tracking Events
export const SendTrackingEvent = 'SEND_TRACKING_EVENT';
export const SendTrackingEventComplete = 'SEND_TRACKING_EVENT_COMPLETE';
export const SendTrackingEventError = 'SEND_TRACKING_EVENT_ERROR';
