import { cloneDeep } from 'lodash';
import {
  SetEnableVideoOnJoinGame, SetEnableAudioOnJoinGame, SetVideoEnabled,
  SetAudioEnabled, SetVideoInputDevice, SetVideoInputDevices,
  SetAudioInputDevice, SetAudioInputDevices, SetAudioOutputDevice,
  SetAudioOutputDevices, ShowSettingsDialog, SetHasSeenPlatformSettings,
  SetShowSettingsOnLoad, SetShowSettingsOnLoadComplete, SetIsLoadingDevices
} from '../enums/action-type';
import {
  ENABLE_VIDEO_ON_JOIN_GAME, ENABLE_AUDIO_ON_JOIN_GAME, HAS_SEEN_PLATFORM_SETTINGS,
  SHOW_SETTINGS_ON_LOAD
} from '../enums/local-storage';

const storedEnableVideoOnJoinGame = localStorage.getItem(ENABLE_VIDEO_ON_JOIN_GAME);
const storedEnableAudioOnJoinGame = localStorage.getItem(ENABLE_AUDIO_ON_JOIN_GAME);
const storedHasSeenPlatformSettings = localStorage.getItem(HAS_SEEN_PLATFORM_SETTINGS);
const storedShowSettingsOnLoad = localStorage.getItem(SHOW_SETTINGS_ON_LOAD);

const enableVideoOnJoinGame = storedEnableVideoOnJoinGame === 'true' || !storedEnableVideoOnJoinGame;
const enableAudioOnJoinGame = storedEnableAudioOnJoinGame === 'true' || !storedEnableAudioOnJoinGame;
const hasSeenPlatformSettings = storedHasSeenPlatformSettings === 'true' || !storedHasSeenPlatformSettings;
const showSettingsOnLoad = storedShowSettingsOnLoad === 'true' || !storedShowSettingsOnLoad;

const initialState = {
  enableVideoOnJoinGame,
  enableAudioOnJoinGame,
  isVideoEnabled: false,
  isAudioEnabled: false,
  videoInputDevices: [],
  videoInputDevice: '',
  audioInputDevices: [],
  audioInputDevice: '',
  audioOutputDevices: [],
  audioOutputDevice: '',
  showDialog: false,
  hasSeenPlatformSettings,
  showSettingsOnLoad,
  // This piece of state will be used to track when a user has been shown the settings
  // dialog after loading a game when the showSettingsOnLoad state is true.
  showSettingsOnLoadComplete: false,
  isLoadingDevices: false
};

export default (state = initialState, action) => {
  const stateClone = cloneDeep(state);

  switch (action.type) {
    case SetEnableVideoOnJoinGame: {
      stateClone.enableVideoOnJoinGame = action.enableVideoOnJoinGame;
      return stateClone;
    }
    case SetEnableAudioOnJoinGame: {
      stateClone.enableAudioOnJoinGame = action.enableAudioOnJoinGame;
      return stateClone;
    }
    case SetVideoEnabled: {
      stateClone.isVideoEnabled = action.isVideoEnabled;
      return stateClone;
    }
    case SetAudioEnabled: {
      stateClone.isAudioEnabled = action.isAudioEnabled;
      return stateClone;
    }
    case SetVideoInputDevice: {
      stateClone.videoInputDevice = action.deviceId;
      return stateClone;
    }
    case SetVideoInputDevices: {
      stateClone.videoInputDevices = action.devices;
      return stateClone;
    }
    case SetAudioInputDevice: {
      stateClone.audioInputDevice = action.deviceId;
      return stateClone;
    }
    case SetAudioInputDevices: {
      stateClone.audioInputDevices = action.devices;
      return stateClone;
    }
    case SetAudioOutputDevice: {
      stateClone.audioOutputDevice = action.deviceId;
      return stateClone;
    }
    case SetAudioOutputDevices: {
      stateClone.audioOutputDevices = action.devices;
      return stateClone;
    }
    case ShowSettingsDialog: {
      stateClone.showDialog = action.showDialog;
      return stateClone;
    }
    case SetHasSeenPlatformSettings: {
      stateClone.hasSeenPlatformSettings = action.hasSeenPlatformSettings;
      return stateClone;
    }
    case SetShowSettingsOnLoad: {
      stateClone.showSettingsOnLoad = action.showSettingsOnLoad;
      return stateClone;
    }
    case SetShowSettingsOnLoadComplete: {
      stateClone.showSettingsOnLoadComplete = action.showSettingsOnLoadComplete;
      return stateClone;
    }
    case SetIsLoadingDevices: {
      stateClone.isLoadingDevices = action.isLoadingDevices;
      return stateClone;
    }
    default:
      return state;
  }
};
