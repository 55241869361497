import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import LGNZoomLogoTieUp from './LGNZoomLogoTieUp';
import CustomButton from './CustomButton';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT, INLINE_IMAGE
} from '../styles/global';
import useMobileRedirect from '../hooks/useMobileRedirect';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  image: {
    ...INLINE_IMAGE(theme)
  }
});

const ZoomWindowsUpdate = ({ classes }) => {
  useMobileRedirect();

  return (
    <div className={classes.wrapper}>
      <LGNZoomLogoTieUp />
      <p className={classes.header}>Microsoft Update Required</p>
      <p className={classes.text}>
        LGN Poker requires an update from Microsoft to function properly on Zoom.
        Please click the button below to download and install the Microsoft
        Edge Webview2 Runtime.
      </p>
      <p className={classes.text}>
        You will need to fully quit and relaunch Zoom after the installation is complete.
      </p>
      <CustomImage
        alt="zoom quit menu"
        className={classes.image}
        src="/zoom-quit-menu.png"
      />
      <CustomButton
        isExternalLinkButton
        linkPath="https://go.microsoft.com/fwlink/p/?LinkId=2124703"
      >
        Get Microsoft Update
      </CustomButton>
    </div>
  );
};

ZoomWindowsUpdate.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomWindowsUpdate
);
