import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INLINE_IMAGE, INNER_CONTENT_WRAPPER
} from '../styles/global';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'initial'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme)
  },
  image: {
    ...INLINE_IMAGE(theme)
  },
  marketplaceLogo: {
    width: 50
  },
  zoomInstallButton: {
    width: 180
  }
});

const ZoomUserGuideInstalling = ({ classes }) => (
  <section
    className={classes.innerWrapper}
    id="installing"
  >
    <p className={classes.header}>Installing LGN Games for Zoom</p>
    <p className={classes.text}>
      1. Open the&nbsp;
      <a
        href="https://marketplace.zoom.us/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Zoom App Marketplace
      </a>
      &nbsp;and search for any LGN Game. For this example, we&apos;ll use LGN Poker.
    </p>
    <CustomImage
      alt="zoom marketplace search UI"
      className={classes.image}
      src="/zoom-user-guide/marketplace-search.png"
    />
    <p className={classes.text}>
      2. Click the icon for the desired LGN game once it appears in the search results.
    </p>
    <CustomImage
      alt="lgn poker for zoom logo"
      className={classNames(classes.image, classes.marketplaceLogo)}
      src="/zoom-user-guide/marketplace-lgn-poker-logo.png"
    />
    <p className={classes.text}>
      3. On the next screen, click the install button to install the LGN game in the
      Apps tab of your Zoom client.
    </p>
    <CustomImage
      alt="zoom install button"
      className={classNames(classes.image, classes.zoomInstallButton)}
      src="/zoom-user-guide/zoom-install-button.png"
    />
    <p className={classes.text}>
      4. Open your Zoom client, then click on the Apps tab.
      You should see your selected LGN game listed under &quot;My Apps&quot;.
    </p>
    <CustomImage
      alt="zoom apps tab"
      className={classNames(classes.image)}
      src="/zoom-user-guide/zoom-apps-screen.png"
    />
  </section>
);

ZoomUserGuideInstalling.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideInstalling
);
