export const ALLOW_COOKIES = 'cookies';
export const HAS_SEEN_PLATFORM_SETTINGS = 'platform-settings';
export const ENABLE_VIDEO_ON_JOIN_GAME = 'default-video';
export const ENABLE_AUDIO_ON_JOIN_GAME = 'default-audio';
export const VIDEO_INPUT_DEVICE = 'video-input';
export const AUDIO_INPUT_DEVICE = 'audio-input';
export const AUDIO_OUTPUT_DEVICE = 'audio-output';
export const REFERRING_NETWORK = 'referring-network';
export const SHOW_SETTINGS_ON_LOAD = 'settings-on-load';
export const SESSION_ID = 's';
export const GUEST_SESSION_ID = 'guest-s';
export const PASSWORD = 'p';
export const USER_ID = 'uid';
export const GUEST_USER_ID = 'guest-uid';
export const DEVICE_ID = 'deviceID';
export const HOST = 'host';
