import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, TextField } from '@material-ui/core';
import { CONTENT_WRAPPER, CONTENT_HEADER } from '../styles/global';
import CustomButton from '../components/CustomButton';
import { showGlobalSnackbar as showGlobalSnackbarAction } from '../actions/global-snackbar';
import { getSuccessMessage, getErrorMessage } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  textField: {
    marginBottom: 25,
    maxWidth: 700
  },
  input: {
    width: '100%'
  }
});

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => ({
  showGlobalSnackbar: (message) => dispatch(showGlobalSnackbarAction(message))
});

const Feedback = ({ classes, showGlobalSnackbar }) => {
  const [feedback, setFeedback] = useState('');

  const handleClick = () => {
    if (feedback) {
      window.Kitsune.sendFeedback(feedback)
        .then(() => {
          setFeedback('');
          showGlobalSnackbar(getSuccessMessage('Feedback received - thanks for helping make Live Game Night even better!'));
        })
        .catch((error) => showGlobalSnackbar(getErrorMessage(error.message)));
    } else {
      showGlobalSnackbar(getErrorMessage('Please enter feedback text.'));
    }
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>We&apos;d love to hear from you</p>
      <TextField
        className={classes.textField}
        fullWidth
        InputProps={{
          className: classes.input
        }}
        label="What would you like to let us know?"
        multiline
        onChange={(event) => setFeedback(event.target.value)}
        rows={7}
        value={feedback}
        variant="filled"
      />
      <CustomButton onClick={handleClick}>
        submit
      </CustomButton>
    </div>
  );
};

Feedback.propTypes = {
  classes: PropTypes.object.isRequired,
  showGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    Feedback
  )
);
