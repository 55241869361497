import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import FAQData from '../data/faq';
import { CONTENT_WRAPPER } from '../styles/global';
import CustomButton from './CustomButton';
import { getSanitizedFAQQuestionText } from '../lib/string';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 25
    }
  },
  breadcrumbs: {
    fontSize: 20,
    color: 'rgba(172, 172, 200, 1)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  breadcrumbFaqText: {
    marginRight: 8
  },
  breadcrumbQuestion: {
    marginLeft: 8,
    color: '#0a4ca7'
  },
  innerWrapper: {
    maxWidth: 700,
    color: 'rgba(172, 172, 200, 1)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 400
    }
  },
  answer: {
    maxWidth: 700,
    fontSize: 18,
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '35px 0'
  },
  image: {
    width: '100%',
    maxWidth: 600,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 400
    }
  },
  helpButton: {
    marginTop: 25
  }
});

const FAQAnswer = ({ classes }) => {
  const params = useParams();
  // The :question param in an answer's URI is generated by lowercasing and replacing the spaces in
  // the question text with hyphens, so recreate that format when searching the FAQ data on the
  // :question param. React Router strips the ? off the end of the :question text for safety
  // reasons, so only ensure that the start of the :question param matches the actual FAQ
  // question text.
  const questionData = FAQData.find((datum) => {
    const sanitizedQuestionText = getSanitizedFAQQuestionText(datum.question);
    return params.question === sanitizedQuestionText;
  });
  const { question } = questionData;
  let { answer } = questionData;

  // Inject images, if necessary.
  if (questionData.images.length) {
    questionData.images.forEach((image, index) => {
      answer = answer.replace(`%image${index}%`, `<div class=${classes.imageWrapper}><img class=${classes.image} src="${image}" /></div>`);
    });
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.breadcrumbs}>
          <Link className={classes.breadcrumbFaqText} to="/faq">FAQ</Link>
          <ArrowForwardIcon />
          <span className={classes.breadcrumbQuestion}>{question}</span>
        </div>
        <div
          className={classes.answer}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
      <CustomButton
        className={classes.helpButton}
        isExternalLinkButton
        linkPath="https://livegamenight.zendesk.com/hc/en-us/requests/new"
      >
        still need help?
      </CustomButton>
    </div>
  );
};

FAQAnswer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  FAQAnswer
);
