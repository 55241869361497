import { combineReducers } from 'redux';
import globalSnackbar from './global-snackbar';
import user from './user';
import host from './host';
import games from './game';
import products from './product';
import settings from './settings';
import config from './config';
import loginDialog from './login-dialog';
import hasLoadedLGNVideoLibrary from './has-loaded-lgn-video-library';
import platform from './platform';

export default combineReducers({
  globalSnackbar,
  user,
  host,
  games,
  products,
  settings,
  config,
  loginDialog,
  hasLoadedLGNVideoLibrary,
  platform
});
