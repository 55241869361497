import Hashes from 'jshashes';
import { API_TOKEN } from '../enums/kitsune';
import { getErrorMessage } from './string';

export const getSignatureAndTimestamp = (gameID) => {
  const timestamp = Date.now();
  const apiKey = API_TOKEN;
  const SHA256 = new Hashes.SHA256();
  const signature = SHA256.hex(timestamp + gameID + apiKey);

  return {
    timestamp,
    signature
  };
};

/**
 * Wrapper for window.fetch. Uses the same API as the native fetch implementation.
 * @param {string} resource
 * @param {Object} init
 * @returns {Promise}
 */
export const customFetch = (resource, init) => (
  new Promise((resolve, reject) => {
    let httpStatus;

    fetch(resource, init)
      .then((response) => {
        // Track the status property returned by the fetch response.
        httpStatus = response.status;
        return response.json();
      })
      .then((json) => {
        // fetch does not pass exectution off to the catch block for responses
        // other than 500, so we need to check the status here to determine if
        // a non-500 error has occurred.
        if (httpStatus === 200) {
          resolve(json);
        } else {
          reject(json);
        }
      })
      .catch((error) => reject(error));
  })
);

/**
 * Gets a friendly error message while accounting for various server error response formats.
 * @param {string|Object} error - The error string or JSON object returned by the server.
 * @returns {string} - A user readable representation of the server error.
 */
export const getServiceError = (error) => {
  let errorMessage = '';

  if (error.message) {
    // For responses that include error info in a string shaped like a JSON object.
    if (error.message.startsWith('{"email":')) {
      errorMessage = JSON.parse(error.message).email;
    } else if (error.message.startsWith('{"email_exists":')) {
      errorMessage = JSON.parse(error.message).email_exists;
    } else if (error.message.startsWith('{"errors":')) {
      errorMessage = JSON.parse(error.message).errors;
    } else if (error.message.startsWith('{"result":')) {
      const parsedError = JSON.parse(error.message);
      const errorProperty = Object.keys(parsedError)[1];

      if (parsedError[errorProperty]) {
        errorMessage = parsedError[errorProperty];
      } else {
        errorMessage = JSON.stringify(error);
      }
    } else {
      errorMessage = error.message;
    }
  } else if (error.name) {
    errorMessage = error.name;
  } else if (error.errors) {
    errorMessage = error.errors;
  } else if (error.error) {
    errorMessage = error.error;
  } else if (error.email) {
    errorMessage = error.email;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    errorMessage = JSON.stringify(error);
  }

  // Prepend the error prefix to the message.
  errorMessage = getErrorMessage(errorMessage);

  // Add a period to the end of the message if the response didn't include one.
  if (errorMessage[errorMessage.length - 1] !== '.') {
    errorMessage = `${errorMessage}.`;
  }

  return errorMessage;
};
