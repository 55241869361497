import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CustomImage from '../containers/CustomImage';

const AppStoreButton = ({ className, imageClassName }) => (
  <a
    className={classNames(
      className,
      'cta-button'
    )}
    href="https://apps.apple.com/us/app/lgn-poker/id1512241117"
    target="blank"
  >
    <CustomImage
      alt="app store"
      className={imageClassName || ''}
      src="/app-store.png"
    />
  </a>
);

AppStoreButton.defaultProps = {
  className: '',
  imageClassName: ''
};

AppStoreButton.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string
};

export default AppStoreButton;
