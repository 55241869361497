import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { hideLoginDialog } from '../actions/login-dialog';
import { isWebGL2Supported, getCurrentBrowser } from '../lib/browser';

const useUnsupportedBrowser = () => {
  const loginDialog = useSelector((state) => state.loginDialog);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Handle situations where the user's device is unsupported.
    const browser = getCurrentBrowser();
    const hasWebGL2Support = isWebGL2Supported();
    const isIe = browser.name === 'IE';
    const isSafari = browser.name === 'Safari';

    if (isIe || isSafari || !hasWebGL2Support) {
      // Neither IE nor Safari are supported, so they should both redirect to the unsupported UI.
      if (isIe || isSafari) {
        history.push('/unsupported');
      } else {
        history.push('/unsupported/webgl');
      }

      // Hide the dialog if it's triggered in an unsupported browser.
      if (loginDialog.show) {
        dispatch(hideLoginDialog());
      }
    }
  }, []);
};

export default useUnsupportedBrowser;
