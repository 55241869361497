import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  banner: {
    width: 800,
    height: 90,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

const PokerBanner = ({ classes, className }) => (
  <div className={className}>
    <CustomImage
      alt="mobile and desktop games"
      className={classes.banner}
      src="/other-clients-banner.jpg"
      useMap="#bannermap"
    />
    <map name="bannermap">
      <area alt="app store" coords="555,6,665,45" href="https://apps.apple.com/us/app/lgn-poker/id1512241117" shape="rect" target="_blank" />
      <area alt="play store" coords="551,47,668,85" href="https://play.google.com/store/apps/details?id=com.lgn.livepokernight" shape="rect" target="_blank" />
      <area alt="windows" coords="672,46,793,87" href={`${window.location.origin}/download`} shape="rect" target="_blank" />
    </map>
  </div>
);

PokerBanner.defaultProps = {
  className: ''
};

PokerBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default
withStyles(styles)(
  PokerBanner
);
