import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, withRouter, Switch, Redirect
} from 'react-router-dom';
import Game from '../containers/Game';
import Register from '../containers/Register';
import Login from '../containers/Login';
import FourZeroFour from './FourZeroFour';
import Unsupported from './Unsupported';
import Mobile from './Mobile';
import Download from './Download';
import ForgotPassword from '../containers/ForgotPassword';
import ResetPassword from '../containers/ResetPassword';
import Account from '../containers/Account';
import GameHome from './GameHome';
import PokerClientSelect from './PokerClientSelect';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import EmailVerification from './EmailVerification';
import Home from '../containers/Home';
import FAQ from './FAQ';
import FAQAnswer from './FAQAnswer';
import Feedback from '../containers/Feedback';
import ReleaseNotes from './ReleaseNotes';
import PokerMaintenance from './PokerMaintenance';
import RegisterSuccess from './RegisterSuccess';
import Error from './Error';
import ZoomWelcome from './ZoomWelcome';
import ZoomUserGuide from './ZoomUserGuide';
import Support from './Support';
import MacDeprecation from './MacDeprecation';
import MediaPlatform from '../containers/MediaPlatform';
import ZoomHome from './ZoomHome';
import ZoomWindowsUpdate from './ZoomWindowsUpdate';
import JoinGame from '../containers/JoinGame';
import PasswordResetConfirmation from './PasswordResetConfirmation';
import RouteEnum from '../enums/route';
import UnsupportedWebGL from './UnsupportedWebGL';
import Downloads from './Downloads';
import MediaPermissionsHelp from './MediaPermissionsHelp';
import JoinPoker from '../containers/JoinPoker';
import PokerAffiliate from '../containers/PokerAffiliate';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';

const Routes = ({ className }) => {
  const { pathname, search } = window.location;

  if (pathname === '' || pathname === '/') {
    return (<Redirect to={`/games${search}`} />);
  }

  return (
    <div className={className}>
      <Switch>
        {/* Any /games path with a hard-coded slug segment needs to come before /games/:slug */}
        <Route
          exact
          path={RouteEnum.GAME}
        >
          <Game />
        </Route>
        <Route
          exact
          path={RouteEnum.MEDIA_PLATFORM}
        >
          <MediaPlatform />
        </Route>
        {/*
          Join poker has to come befor the join game route because the
          poker route would match the join game route.
        */}
        <Route
          exact
          path={RouteEnum.JOIN_POKER}
        >
          <JoinPoker />
        </Route>
        <Route
          exact
          path={RouteEnum.JOIN_GAME}
        >
          <JoinGame />
        </Route>
        <Route
          exact
          path={RouteEnum.UNSUPPORTED}
        >
          <Unsupported />
        </Route>
        <Route
          exact
          path={RouteEnum.UNSUPPORTED_WEB_GL}
        >
          <UnsupportedWebGL />
        </Route>
        <Route
          exact
          path={RouteEnum.MOBILE}
        >
          <Mobile />
        </Route>
        <Route
          exact
          path={RouteEnum.DOWNLOAD}
        >
          <Download />
        </Route>
        <Route
          exact
          path={RouteEnum.FORGOT_PASSWORD}
        >
          <ForgotPassword />
        </Route>
        <Route
          exact
          path={RouteEnum.PASSWORD_RESET}
        >
          <ResetPassword />
        </Route>
        <Route
          exact
          path={RouteEnum.PASSWORD_RESET_CONFIRMATION}
        >
          <PasswordResetConfirmation />
        </Route>
        <Route
          exact
          path={RouteEnum.REGISTER}
        >
          <Register />
        </Route>
        <Route
          exact
          path={[RouteEnum.LOGIN, RouteEnum.DISCOURSE_LOGIN]}
        >
          <Login />
        </Route>
        <Route
          exact
          path={RouteEnum.ACCOUNT}
        >
          <Account />
        </Route>
        <Route
          exact
          path={RouteEnum.ZOOM_HOME}
        >
          <ZoomHome />
        </Route>
        <Route
          exact
          path={RouteEnum.GAME_HOME}
        >
          <GameHome />
        </Route>
        <Route
          exact
          path={RouteEnum.POKER_CLIENT_SELECT}
        >
          <PokerClientSelect />
        </Route>
        <Route
          exact
          path={RouteEnum.PRIVACY_POLICY}
        >
          <PrivacyPolicy />
        </Route>
        <Route
          exact
          path={RouteEnum.TERMS_OF_USE}
        >
          <TermsOfUse />
        </Route>
        <Route
          exact
          path={RouteEnum.EMAIL_VERIFICATION}
        >
          <EmailVerification />
        </Route>
        <Route
          exact
          path={[RouteEnum.HOME, RouteEnum.GAMES]}
        >
          <Home />
        </Route>
        <Route
          exact
          path={RouteEnum.FAQ}
        >
          <FAQ />
        </Route>
        <Route
          exact
          path={RouteEnum.FAQ_ANSWER}
        >
          <FAQAnswer />
        </Route>
        <Route
          exact
          path={RouteEnum.FEEDBACK}
        >
          <Feedback />
        </Route>
        <Route
          exact
          path={RouteEnum.RELEASES}
        >
          <ReleaseNotes markdownFileName="release-notes.md" />
        </Route>
        <Route
          exact
          path={RouteEnum.POKER_MAINTENANCE}
        >
          <PokerMaintenance />
        </Route>
        <Route
          exact
          path={RouteEnum.REGISTER_SUCCESS}
        >
          <RegisterSuccess />
        </Route>
        <Route
          exact
          path={RouteEnum.ERROR}
        >
          <Error />
        </Route>
        <Route
          exact
          path={RouteEnum.ZOOM_WELCOME}
        >
          <ZoomWelcome />
        </Route>
        <Route
          exact
          path={RouteEnum.ZOOM_GUIDE}
        >
          <ZoomUserGuide />
        </Route>
        <Route
          exact
          path={RouteEnum.SUPPORT}
        >
          <Support />
        </Route>
        <Route
          exact
          path={RouteEnum.MAC_DEPRECATION}
        >
          <MacDeprecation />
        </Route>
        <Route
          exact
          path={RouteEnum.ZOOM_WINDOWS_UPDATE}
        >
          <ZoomWindowsUpdate />
        </Route>
        <Route
          exact
          path={RouteEnum.DOWNLOADS}
        >
          <Downloads />
        </Route>
        <Route
          exact
          path={RouteEnum.MEDIA_PERMISSIONS_HELP}
        >
          <MediaPermissionsHelp />
        </Route>
        <Route
          exact
          path={RouteEnum.POKER_AFFILIATE}
        >
          <PokerAffiliate />
        </Route>
        <Route
          exact
          path={RouteEnum.FORGOT_PASSWORD_CONFIRMATION}
        >
          <ForgotPasswordConfirmation />
        </Route>
        <Route>
          <FourZeroFour />
        </Route>
      </Switch>
    </div>
  );
};

Routes.defaultProps = {
  className: ''
};

Routes.propTypes = {
  className: PropTypes.string
};

export default
withRouter(
  Routes
);
