export default {
  GAME: '/games/poker',
  MEDIA_PLATFORM: '/games/:slug',
  JOIN_GAME: '/games/:slug/join',
  UNSUPPORTED: '/unsupported',
  MOBILE: '/mobile',
  DOWNLOAD: '/download',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_CONFIRMATION: '/password-reset/confirmation',
  REGISTER: '/register',
  LOGIN: '/login',
  DISCOURSE_LOGIN: '/discourse/login',
  ACCOUNT: '/account',
  ZOOM_HOME: '/zoom/home',
  GAME_HOME: '/:slug/home',
  POKER_CLIENT_SELECT: '/poker',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',
  EMAIL_VERIFICATION: '/email-verification',
  HOME: '/home',
  GAMES: '/games',
  FAQ: '/faq',
  FAQ_ANSWER: '/faq/:question',
  FEEDBACK: '/feedback',
  RELEASES: '/releases',
  POKER_MAINTENANCE: '/poker/maintenance',
  REGISTER_SUCCESS: '/register/success',
  ERROR: '/error',
  ZOOM_WELCOME: '/zoom/welcome',
  ZOOM_GUIDE: '/zoom/guide',
  SUPPORT: '/support',
  MAC_DEPRECATION: '/mac-deprecation',
  ZOOM_WINDOWS_UPDATE: '/zoom/windows/update',
  UNSUPPORTED_WEB_GL: '/unsupported/webgl',
  DOWNLOADS: '/downloads',
  MEDIA_PERMISSIONS_HELP: '/media-permissions-help',
  JOIN_POKER: '/games/poker/join',
  POKER_AFFILIATE: '/poker/affiliate',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password/confirmation'
};
