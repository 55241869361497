import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withStyles, Dialog, DialogContent, Tabs, Tab
} from '@material-ui/core';
import PlayAsGuest from './PlayAsGuest';
import Login from './Login';
import Register from './Register';
import {
  setLoginDialogTab as setLoginDialogTabAction,
  hideLoginDialog as hideLoginDialogAction
} from '../actions/login-dialog';
import LoginDialogTab from '../enums/login-dialog-tab';
import DialogTitleWithLogo from '../components/DialogTitleWithLogo';
import TrackingEvent from '../enums/tracking-event';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import useUnsupportedBrowser from '../hooks/useUnsupportedBrowser';

const styles = () => ({
  dialogPaper: {
    padding: 0,
    width: 500
  },
  formWrapper: {
    padding: 35
  },
  cancel: {
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 35,
    textTransform: 'uppercase'
  },
  form: {
    backgroundColor: 'rgba(38, 38, 43, 1)',
    padding: 0,
    height: 480,
    justifyContent: 'flex-start'
  },
});

const mapStateToProps = (state) => ({
  loginDialog: state.loginDialog
});

const mapDispatchToProps = (dispatch) => ({
  setLoginDialogTab: (tab) => dispatch(setLoginDialogTabAction(tab)),
  hideLoginDialog: () => dispatch(hideLoginDialogAction()),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const getHeaderText = (tab) => {
  if (tab === LoginDialogTab.GUEST) {
    return 'Live Game Night Guest Play';
  }

  if (tab === LoginDialogTab.LOGIN) {
    return 'Log In to Live Game Night';
  }
  return 'Join Live Game Night';
};

// Make sure the login dialog closes if the user clicks the browser's back button.
const LoginDialog = ({
  classes, open, onCancel,
  loginDialog, setLoginDialogTab, hideLoginDialog,
  sendTrackingEvent
}) => {
  useUnsupportedBrowser();

  const handleCancelClick = () => {
    sendTrackingEvent(TrackingEvent.LOGIN_DIALOG_CLOSE, loginDialog.tab);
    onCancel();
  };

  useEffect(() => {
    sendTrackingEvent(TrackingEvent.LOGIN_DIALOG_OPEN, loginDialog.tab);
  }, []);

  useEffect(() => {
    const handlePopState = () => hideLoginDialog();

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  });

  return (
    <Dialog
      open={open}
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogContent>
        <DialogTitleWithLogo text={getHeaderText(loginDialog.tab)} />
        <Tabs
          onChange={(event, newValue) => setLoginDialogTab(newValue)}
          value={loginDialog.tab}
        >
          <Tab
            label="guest"
            value={LoginDialogTab.GUEST}
          />
          <Tab
            label="log in"
            value={LoginDialogTab.LOGIN}
          />
          <Tab
            className={classes.tab}
            label="sign up"
            value={LoginDialogTab.REGISTER}
          />
        </Tabs>
        <div className={classes.formWrapper}>
          {loginDialog.tab === LoginDialogTab.GUEST && (
            <PlayAsGuest className={classes.form} />
          )}
          {loginDialog.tab === LoginDialogTab.LOGIN && (
            <Login
              className={classes.form}
              showFormHeader={false}
            />
          )}
          {loginDialog.tab === LoginDialogTab.REGISTER && (
            <Register
              className={classes.form}
              showFormHeader={false}
            />
          )}
        </div>
      </DialogContent>
      <p
        className={classes.cancel}
        onClick={handleCancelClick}
      >
        cancel
      </p>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  hideLoginDialog: PropTypes.func.isRequired,
  loginDialog: PropTypes.shape({
    tab: PropTypes.string.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sendTrackingEvent: PropTypes.func.isRequired,
  setLoginDialogTab: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    LoginDialog
  )
);
