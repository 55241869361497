import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INNER_CONTENT_WRAPPER
} from '../styles/global';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'initial'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme)
  }
});

const ZoomUserGuideSupport = ({ classes }) => (
  <section
    className={classes.innerWrapper}
    id="support"
  >
    <p className={classes.header}>Getting Support</p>
    <p className={classes.text}>
      For support, please email us at&nbsp;
      <a
        href="mailto:support@livegamenight.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        support@livegamenight.com
      </a>,
      or submit a&nbsp;
      <a
        href="https://livegamenight.zendesk.com/hc/en-us/requests/new"
        rel="noopener noreferrer"
        target="_blank"
      >
        support ticket
      </a>.
    </p>
  </section>
);

ZoomUserGuideSupport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideSupport
);
