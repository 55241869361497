import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_SUB_HEADER, CONTENT_TEXT, INLINE_IMAGE, INNER_CONTENT_WRAPPER
} from '../styles/global';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  header: {
    ...CONTENT_SUB_HEADER(theme),
    color: '#cdcdcd'
  },
  text: {
    ...CONTENT_TEXT(theme),
    alignSelf: 'flex-start',
    textAlign: 'left'
  },
  innerWrapper: {
    ...INNER_CONTENT_WRAPPER(theme),
  },
  image: {
    ...INLINE_IMAGE(theme)
  },
  marketplaceLogo: {
    width: 50
  },
  zoomInstallButton: {
    width: 180
  },
  explanatoryText: {
    fontStyle: 'italic',
    margin: '30px 0'
  }
});

const ZoomUserGuideUsing = ({ classes }) => (
  <section className={classes.innerWrapper}>
    <p
      className={classes.header}
      id="using"
    >
      Using LGN Games for Zoom
    </p>
    <p className={classes.text}>
      1. <b>Start or join a Zoom meeting</b>. This step is important! If you do not
      start or join a meeting before starting a game, Zoom won&apos;t know to put everyone
      in the same room for your LGN game session.
    </p>
    <p className={classes.text}>
      2. Once you have joined the Zoom meeting, click on the &quot;Apps&quot; tab at the bottom
      of the meeting window, and select the desired LGN app. For this example, we&apos;ll
      be using LGN Poker.
    </p>
    <CustomImage
      alt="zoom meeting app select"
      className={classes.image}
      src="/zoom-user-guide/zoom-meeting-app-select.png"
    />
    <p className={classes.text}>
      3. Once the game loads, you&apos;ll be ready to play your selected LGN game in the app
      view while interacting with the people in your meeting! Continue following the prompts
      in your selected game to configure and begin your game.
    </p>
    <CustomImage
      alt="zoom app view buttons"
      className={classes.image}
      src="/zoom-user-guide/meeting-and-poker.png"
    />
  </section>
);

ZoomUserGuideUsing.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuideUsing
);
