import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import MediaPlatformCopyInviteButton from '../containers/MediaPlatformCopyInviteButton';
import FullscreenButton from './FullscreenButton';
import MediaPlatformGameCodeButton from '../containers/MediaPlatformGameCodeButton';
import { MEDIA_PLATFORM_CONSUMER_TOOLBAR_HEIGHT } from '../styles/global';
import CustomImage from '../containers/CustomImage';
import ExitFullscreenButton from './ExitFullscreenButton';

const INVITE_BUTTONS_WRAPPER_PADDING = 8;

const styles = (theme) => ({
  toolbar: {
    height: MEDIA_PLATFORM_CONSUMER_TOOLBAR_HEIGHT,
    boxSizing: 'border-box',
    borderTop: 0,
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'initial'
    }
  },
  logoWrapper: {
    flex: 1
  },
  logo: {
    width: 150
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  button: {
    width: 150,
    '&:last-child': {
      marginRight: 0
    }
  },
  gameCodeButton: {
    width: 190
  },
  inviteButtonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px rgba(255, 255, 255, 0.2)',
    padding: INVITE_BUTTONS_WRAPPER_PADDING,
    borderRadius: 4,
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8
    }
  },
  inviteButtons: {
    display: 'flex'
  },
  inviteButtonsLabel: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: 13,
    marginBottom: 5
  },
  fullscreenButton: {
    marginBottom: 9
  }
});

const MediaPlatformToolbar = ({
  classes, onFullscreenClick, style,
  gameCode, gameLogo, isFullscreen
}) => (
  <div
    className={classes.toolbar}
    style={style}
  >
    <div className={classes.logoWrapper}>
      <CustomImage
        className={classes.logo}
        src={gameLogo}
      />
    </div>
    <div className={classes.buttonsWrapper}>
      <div className={classes.inviteButtonsWrapper}>
        <span className={classes.inviteButtonsLabel}>Invite Friends</span>
        <div className={classes.inviteButtons}>
          <MediaPlatformGameCodeButton
            className={classNames(classes.button, classes.gameCodeButton)}
            gameCode={gameCode}
          />
          <MediaPlatformCopyInviteButton className={classes.button} />
        </div>
      </div>
      {isFullscreen ? (
        <ExitFullscreenButton className={classNames(classes.button, classes.fullscreenButton)} />
      ) : (
        <FullscreenButton
          className={classNames(classes.button, classes.fullscreenButton)}
          onClick={onFullscreenClick}
        />
      )}
    </div>
  </div>
);

MediaPlatformToolbar.defaultProps = {
  style: {}
};

MediaPlatformToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  gameCode: PropTypes.string.isRequired,
  gameLogo: PropTypes.string.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  onFullscreenClick: PropTypes.func.isRequired,
  // The style property allows for passing dynamically calculated styles from the parent component.
  style: PropTypes.object
};

export default
withStyles(styles)(
  MediaPlatformToolbar
);
