export default {
  PAGE_LOAD: 'PAGE_LOAD',
  GAME_LOAD_COMPLETE: 'GAME_LOAD_COMPLETE',
  LOGIN_DIALOG_OPEN: 'LOGIN_DIALOG_OPEN',
  LOGIN_DIALOG_CLOSE: 'LOGIN_DIALOG_CLOSE',
  GAME_CTA_CLICK: 'GAME_CTA_CLICK',
  GUEST_PLAY_CLICK: 'GUEST_PLAY_CLICK',
  LOGIN_CLICK: 'LOGIN_CLICK',
  REGISTER_CLICK: 'REGISTER_CLICK',
  GUEST_PLAY_ERROR: 'GUEST_PLAY_ERROR',
  REGISTER_ERROR: 'REGISTER_ERROR',
  MEDIA_PERMISSIONS_ALLOWED: 'MEDIA_PERMISSIONS_ALLOWED',
  MEDIA_PERMISSIONS_DENIED: 'MEDIA_PERMISSIONS_DENIED',
  GAME_HOSTED: 'GAME_HOSTED',
  GAME_JOINED: 'GAME_JOINED'
};
