import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  logo: {
    marginTop: 35,
    width: 500,
    transition: 'width 300ms',
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
      width: 350,
      borderRight: 0,
      paddingRight: 0,
    }
  }
});

const LGNPokerLogo = ({ classes, className }) => (
  <CustomImage
    alt="live game night logo"
    className={classNames(classes.logo, className)}
    src="/lgn-poker-logo.png"
  />
);

LGNPokerLogo.defaultProps = {
  className: ''
};

LGNPokerLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default
withStyles(styles)(
  LGNPokerLogo
);
