import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import MediaPlatformToolbar from './MediaPlatformToolbar';

const styles = {
  gameAndToolbarWrapper: {
    height: '100%',
    boxSizing: 'border-box'
  },
  iframe: {
    boxSizing: 'border-box',
    border: 0
  },
};

const MediaPlatformGame = ({
  classes, className, iframeSrc,
  iframeHeight, iframeWidth, game,
  gameCode, isFullscreen, onFullscreenClick
}) => {
  // Let Kitsune know if a user is active by sending a message any time they
  // move the mouse outside of the game iframe.
  useEffect(() => {
    const handleMouseOver = () => {
      if (window.Kitsune && window.Kitsune.sendNotAFK) {
        window.Kitsune.sendNotAFK();
      }
    };

    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  return (
    <div className={classNames(classes.gameAndToolbarWrapper, className)}>
      <iframe
        allow="camera *; microphone *; fullscreen; autoplay;"
        className={classes.iframe}
        height={iframeHeight}
        src={iframeSrc}
        title={game.name}
        width={iframeWidth}
      />
      <MediaPlatformToolbar
        gameCode={gameCode}
        gameLogo={game.media.logo}
        isFullscreen={isFullscreen}
        onFullscreenClick={onFullscreenClick}
      />
    </div>
  );
};

MediaPlatformGame.defaultProps = {
  className: ''
};

MediaPlatformGame.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  game: PropTypes.shape({
    media: PropTypes.shape({
      logo: PropTypes.string.isRequired
    }).isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  gameCode: PropTypes.string.isRequired,
  iframeHeight: PropTypes.number.isRequired,
  iframeSrc: PropTypes.string.isRequired,
  iframeWidth: PropTypes.number.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  onFullscreenClick: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  MediaPlatformGame
);
