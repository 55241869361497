import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MediaPlatformMetaData = ({ game, location }) => (
  <Helmet>
    <meta
      content={`Play ${game.name} live with friends!`}
      name="description"
    />
    <title>{`${game.name} - Play`}</title>
    <link
      href={`${location.host}${location.path}`}
      rel="canonical"
    />
  </Helmet>
);

MediaPlatformMetaData.propTypes = {
  game: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }).isRequired
};

export default MediaPlatformMetaData;
