import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Warning as WarningIcon, Build as BuildIcon } from '@material-ui/icons';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT, ACTION_BUTTON
} from '../styles/global';
import CustomButton from './CustomButton';
import CustomImage from '../containers/CustomImage';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme),
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    color: 'yellow',
    fontSize: 100,
    marginBottom: 25
  },
  buildIcon: {
    color: 'silver',
    fontSize: 32,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  image: {
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: 400
    }
  },
  otherGamesLink: {
    ...ACTION_BUTTON(theme)
  }
});

const Maintenance = ({ classes, gameName, image }) => (
  <div className={classes.wrapper}>
    {image && (
      <CustomImage
        alt="maintenance"
        className={classes.image}
        src={image}
      />
    )}
    <p className={classes.header}>Counting down to live...</p>
    <WarningIcon className={classes.warningIcon} />
    <p className={classes.text}>
      <BuildIcon className={classes.buildIcon} />
      {gameName} is under maintenance, but we&apos;ll be LIVE shortly!
    </p>
    <CustomButton
      className={classes.otherGamesLink}
      isInternalLinkButton
      linkPath="/games"
    >
      view game catalog
    </CustomButton>
  </div>
);

Maintenance.defaultProps = {
  image: ''
};

Maintenance.propTypes = {
  classes: PropTypes.object.isRequired,
  gameName: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default
withStyles(styles)(
  Maintenance
);
