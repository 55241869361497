import { ShowLoginDialog, HideLoginDialog, SetLoginDialogTab } from '../enums/action-type';

/**
 * Show the login dialog with the specified tab pre-selected.
 * @param {string} tab - 'login' or 'register'
 */
export const showLoginDialog = (tab) => ({ type: ShowLoginDialog, tab });

export const hideLoginDialog = () => ({ type: HideLoginDialog });

export const setLoginDialogTab = (tab) => ({ type: SetLoginDialogTab, tab });
