import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, ACTION_BUTTON, CONTENT_HEADER,
  CONTENT_TEXT
} from '../styles/global';
import CustomButton from './CustomButton';
import { isZoom } from '../lib/env';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  cardsWrapper: {
    height: 220,
    [theme.breakpoints.down('xs')]: {
      height: 110
    }
  },
  cards: {
    display: 'flex',
    marginBottom: 35
  },
  card: {
    backgroundColor: 'rgba(234, 223, 211, 1)',
    height: 145,
    width: 105,
    fontSize: 80,
    borderRadius: 9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 12,
    opacity: 0,
    animationName: '$fadeIn',
    animationDuration: 400,
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    '&:nth-child(4)': {
      animationDelay: 200
    },
    '&:nth-child(2)': {
      animationDelay: 400
    },
    '&:nth-child(3)': {
      animationDelay: 600
    },
    [theme.breakpoints.down('xs')]: {
      height: 88,
      width: 62,
      fontSize: 60,
      margin: 8
    }
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  redSuit: {
    color: 'red'
  },
  blackSuit: {
    color: 'black'
  },
  viewCatalogButton: {
    ...ACTION_BUTTON(theme)
  }
});

const getErrorMessage = (errorCode) => {
  const parsedErrorCode = parseInt(errorCode, 10);
  switch (parsedErrorCode) {
    case 1000:
      return '1000: We encountered an unexpected error. Please wait a bit and try installing the app again.';
    case 1001:
      return '1001: No authorization code received. Please wait a bit and try installing the app again.';
    case 1002:
      return '1002: The request took too long to process. Please try installing the app again.';
    case 1003:
      return '1003: The request took too long to process. Please try installing the app again.';
    case 1004:
      return '1004: The request took too long to process. Please try installing the app again.';
    case 1005:
      return '1005: We had a communication issue with Zoom. Please wait a bit and try again.';
    case 1006:
      return 'There was a problem registering your account. Please try again and, if the problem persists, contact our support team.';
    case 2000:
      return '2000: We encountered an unexpected error. Please wait a bit and try opening the app again.';
    case 2001:
      return '2001: Your request timed out. Please try loading the app again.';
    case 2002:
      return '2002: We couldn\'t find your account. Please try installing the app again.';
    default:
      return 'We\'re experiencing technical difficulties.';
  }
};

const Error = ({ classes }) => {
  const params = new URLSearchParams(window.location.search);
  const errorCode = params.get('error');
  const errorMessage = getErrorMessage(errorCode);

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>We&apos;re sorry, something&apos;s gone wrong!</p>
      <div className={classes.cards}>
        <div className={classNames(classes.card, classes.redSuit)}>♦</div>
        <span className={classNames(classes.card, classes.blackSuit)}>♣</span>
        <span className={classNames(classes.card, classes.redSuit)}>♥</span>
        <span className={classNames(classes.card, classes.blackSuit)}>♠</span>
      </div>
      <p className={classes.text}>{errorMessage}</p>
      <p className={classes.text}>
        Please take a look at our <Link to="/support">support page</Link> to learn how to get help.
      </p>
      {!isZoom() && (
        <CustomButton
          className={classes.viewCatalogButton}
          isInternalLinkButton
          linkPath="/games"
        >
          view game catalog
        </CustomButton>
      )}
    </div>
  );
};

Error.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Error
);
