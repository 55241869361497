import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createMuiTheme } from '@material-ui/core/styles';
import { LIGHT_ACCENT } from './palette';

const breakpoints = createBreakpoints({});

export default createMuiTheme({
  palette: {
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#0f7001',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&:disabled': {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      }
    },
    MuiFilledInput: {
      root: {
        width: 400,
        borderRadius: 6,
        [breakpoints.down('xs')]: {
          width: 300
        },
        border: 'solid 2px rgba(255, 255, 255, 0.2)',
        backgroundColor: '#45454b',
        marginBottom: 25,
        color: 'white',
        '&$focused': {
          backgroundColor: '#000000',
          border: 'solid 2px #457ad2'
        },
        '&:hover': {
          backgroundColor: '#45454b',
          '@media (hover: none)': {
            backgroundColor: '#45454b',
          }
        },
        '&.has-error': {
          border: 'solid 2px red'
        }
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiInputLabel: {
      filled: {
        color: 'white'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'white'
        }
      }
    },
    MuiDialog: {
      container: {
        backgroundColor: 'rgba(0, 0, 0, 1)'
      },
      paper: {
        color: '#fff',
        backgroundColor: 'rgba(38, 38, 43, 1)',
        border: 'solid 1px rgba(92, 92, 96, 1)',
        padding: 35
      }
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 25,
        padding: 0,
        fontSize: 20,
        textTransform: 'capitalize'
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingTop: 0
        }
      }
    },
    MuiDialogActions: {
      root: {
        padding: 0,
        marginTop: 70,
        justifyContent: 'center'
      }
    },
    MuiDialogContentText: {
      root: {
        color: '#fff'
      }
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 25
      }
    },
    MuiCheckbox: {
      root: {
        color: '#fff',
        '&.has-error': {
          color: 'red'
        }
      },
      colorSecondary: {
        '&$checked': {
          color: '#33496e'
        }
      }
    },
    MuiList: {
      root: {
        backgroundColor: '#45454b',
        color: 'white'
      }
    },
    MuiListItemText: {
      root: {
        color: '#000'
      }
    },
    MuiIconButton: {
      root: {
        color: '#fff'
      }
    },
    MuiTabs: {
      root: {
        borderBottom: 'solid 1px #494953',
        minHeight: 30
      },
      flexContainer: {
        justifyContent: 'center'
      },
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        minWidth: 94,
        minHeight: 30,
        '@media (min-width: 600px)': {
          minWidth: 94,
          minHeight: 30
        },
        '&$selected': {
          backgroundColor: 'rgba(73, 73, 83, 1)',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5
        }
      },
    }
  }
});
