import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  wrapper: {
    fontSize: 24,
    color: 'White',
    padding: 20,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      borderRadius: 0,
      width: '100%'
    }
  }
});

const AnnouncementBanner = ({
  classes, text, className, style
}) => (
  <p
    className={classNames(classes.wrapper, className)}
    style={style}
  >
    {text}
  </p>
);

AnnouncementBanner.defaultProps = {
  className: '',
  style: {}
};

AnnouncementBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  AnnouncementBanner
);
