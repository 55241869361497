import {
  SetIsMediaPlatformActive, SetHasMediaPermissions, SetIsPokerActive,
  SetIsCheckingMediaPermissions
} from '../enums/action-type';

const initialState = {
  isActive: false,
  isPokerActive: false,
  hasMediaPermissions: false,
  isCheckingMediaPermissions: false
};

export default (state = initialState, action) => {
  const stateClone = { ...state };

  switch (action.type) {
    case SetIsMediaPlatformActive: {
      stateClone.isActive = action.isActive;
      return stateClone;
    }
    case SetIsPokerActive: {
      stateClone.isPokerActive = action.isActive;
      return stateClone;
    }
    case SetHasMediaPermissions: {
      stateClone.hasMediaPermissions = action.hasMediaPermissions;
      return stateClone;
    }
    case SetIsCheckingMediaPermissions: {
      stateClone.isCheckingMediaPermissions = action.isCheckingMediaPermissions;
      return stateClone;
    }
    default:
      return state;
  }
};
