import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, Dialog, DialogContent, DialogContentText, DialogActions, CircularProgress
} from '@material-ui/core';
import DialogTitleWithLogo from './DialogTitleWithLogo';
import CustomButton from './CustomButton';

const styles = () => ({
  dialogPaper: {
    height: 350
  },
  dialogContent: {
    overflowY: 'clip'
  },
  text: {
    textAlign: 'center',
    lineHeight: 1.5
  },
  progressSpinner: {
    color: '#6CAAFF'
  }
});

const ReauthDialog = ({ classes, open, onAccept, disconnectReason }) => {
  const [showLoading, setShowLoading] = useState(false);

  const handleClick = () => {
    setShowLoading(true);

    onAccept()
      .finally(() => setShowLoading(false));
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogTitleWithLogo text="Session Timeout" />
        <DialogContentText className={classes.text}>
          Your session has expired for the following reason:
          <p className={classes.text}>{disconnectReason}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showLoading ? (
          <CircularProgress className={classes.progressSpinner} />
        ) : (
          <CustomButton
            onClick={handleClick}
            size="large"
          >
            Renew Session
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

ReauthDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  disconnectReason: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default
withStyles(styles)(
  ReauthDialog
);
