import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobileDevice } from '../lib/browser';
import Route from '../enums/route';

const useMobileRedirect = (gameSlug = '') => {
  const history = useHistory();
  const { pathname } = window.location;

  useEffect(() => {
    if (isMobileDevice() && pathname !== Route.POKER_AFFILIATE) {
      if (gameSlug) {
        history.push(`/mobile?game=${gameSlug}`);
      } else {
        history.push('/mobile');
      }
    }
  }, []);
};

export default useMobileRedirect;
