import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { hideGlobalSnackbar as hideGlobalSnackbarAction } from '../actions/global-snackbar';

const styles = {
  wrapper: {
    display: 'fixed',
    bottom: 50
  },
  button: {
    color: '#fff'
  }
};

const mapStateToProps = (state) => ({
  globalSnackbar: state.globalSnackbar
});

const mapDispatchToProps = (dispatch) => ({
  hideGlobalSnackbar: () => dispatch(hideGlobalSnackbarAction())
});

const GlobalSnackbar = ({ classes, globalSnackbar, hideGlobalSnackbar }) => (
  <Snackbar
    action={(
      <IconButton
        className={classes.button}
        onClick={() => hideGlobalSnackbar()}
      >
        <CloseIcon />
      </IconButton>
    )}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    autoHideDuration={5000}
    className={classes.wrapper}
    disableWindowBlurListener
    message={globalSnackbar.message}
    onClose={(event, reason) => {
      // This prevents the snackbar from being closed on 'clickaway.'
      if (reason === 'timeout') {
        hideGlobalSnackbar();
      }
    }}
    open={globalSnackbar.open}
  />
);

GlobalSnackbar.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired
  }).isRequired,
  globalSnackbar: PropTypes.shape({
    message: PropTypes.string,
    open: PropTypes.bool.isRequired
  }).isRequired,
  hideGlobalSnackbar: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    GlobalSnackbar
  )
);
