import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles, TextField } from '@material-ui/core';
import { getGames as getGamesAction } from '../actions/game';
import { logout as logoutAction } from '../actions/user';
import {
  CONTENT_WRAPPER, CONTENT_TEXT, LIGHT_BOX_SHADOW
} from '../styles/global';
import LoginRequired from './LoginRequired';
import GlobalProgressSpinner from '../components/GlobalProgressSpinner';
import CustomButton from '../components/CustomButton';
import useMobileRedirect from '../hooks/useMobileRedirect';
import CustomImage from './CustomImage';
import useLogin from '../hooks/useLogin';
import useGames from '../hooks/useGames';
import useUnsupportedBrowser from '../hooks/useUnsupportedBrowser';
import { sendTrackingEvent as sendTrackingEventAction } from '../actions/tracking';
import TrackingEvent from '../enums/tracking-event';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  logo: {
    width: 400
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
    marginBottom: 40
  },
  text: {
    ...CONTENT_TEXT(theme)
  },
  textField: {
    marginBottom: 0,
    marginRight: 8,
    height: 52,
    width: 150,
    '&$disabled': {
      backgroundColor: 'red'
    }
  },
  input: {
    padding: '8px 0',
    fontSize: 22,
    height: 33,
    textTransform: 'uppercase',
    letterSpacing: 1.3,
    textAlign: 'center'
  },
  filledInput: {
    width: 150
  },
  button: {
    width: 220
  },
  dialogPaper: {
    ...LIGHT_BOX_SHADOW(theme)
  },
});

const mapStateToProps = (state) => ({
  user: state.user,
  games: state.games
});

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(getGamesAction()),
  logout: () => dispatch(logoutAction()),
  sendTrackingEvent: (event, detail) => dispatch(sendTrackingEventAction(event, detail))
});

const JoinGame = ({
  classes, user, games,
  logout, sendTrackingEvent
}) => {
  const [game, setGame] = useState(null);
  const [joinGameCode, setJoinGameCode] = useState('');
  const [newGameCode, setNewGameCode] = useState('');
  const params = useParams();
  const history = useHistory();
  const { slug } = params;

  useUnsupportedBrowser();
  useMobileRedirect(params.slug);
  useLogin();
  useGames();

  useEffect(() => {
    if (games.length) {
      const gameModel = games.find((g) => g.slug === slug);

      if (gameModel) {
        setGame(gameModel);
      } else {
        history.push('/invalid-game');
      }
    }
  }, [games.length]);

  useEffect(() => {
    if (user.sessionID) {
      window.Kitsune.createGameSession()
        .then((response) => setNewGameCode(response.gameSessionCode))
        .catch(() => logout());
    }
  }, [user.sessionID]);

  const handleGameCodeChange = (event) => {
    const { value } = event.target;
    const alphaRegex = /^[A-Za-z]*$/;

    if (value.length > 4 || !alphaRegex.test(value)) {
      return;
    }

    setJoinGameCode(value.toUpperCase());
  };

  const handleJoinGameClick = () => {
    sendTrackingEvent(TrackingEvent.GAME_JOINED, `${game.slug}-${joinGameCode}`);
    history.push(`/games/${game.slug}?code=${joinGameCode}&role=participant`);
  };

  const handleNewGameClick = () => {
    sendTrackingEvent(TrackingEvent.GAME_HOSTED, `${game.slug}-${newGameCode}`);
    history.push(`/games/${game.slug}?code=${newGameCode}&role=host`);
  };

  if (!user.isAuthenticated) {
    return (<LoginRequired game={game} />);
  }

  // If the game hasn't loaded or a new game code hasn't been generated.
  if (!game || !newGameCode) {
    return (<GlobalProgressSpinner />);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <CustomImage
          alt="game logo"
          className={classes.logo}
          src={game.media.logo}
        />
      </div>
      <div className={classNames(classes.row, classes.text)}>
        Enter the game code provided by your host
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputProps={{ // eslint-disable-line
            className: classes.filledInput
          }}
          onChange={handleGameCodeChange}
          placeholder="----"
          value={joinGameCode}
          variant="filled"
        />
        <CustomButton
          className={classes.button}
          disabled={joinGameCode.length < 4}
          onClick={handleJoinGameClick}
        >
          join game
        </CustomButton>
      </div>
      <div className={classNames(classes.row, classes.text)}>
        Want to start a new game?
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputProps={{ // eslint-disable-line
            className: classes.filledInput,
            readOnly: true
          }}
          value={newGameCode}
          variant="filled"
        />
        <CustomButton
          className={classes.button}
          onClick={handleNewGameClick}
        >
          new game
        </CustomButton>
      </div>
    </div>
  );
};

JoinGame.propTypes = {
  classes: PropTypes.object.isRequired,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      media: {
        logo: PropTypes.string.isRequired
      }.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    sessionID: PropTypes.string.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(
    JoinGame
  )
);
