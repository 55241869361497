import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, TextField } from '@material-ui/core';
import CustomButton from './CustomButton';
import CustomImage from '../containers/CustomImage';
import { ENTER } from '../enums/keyboard';
import useKeyup from '../hooks/useKeyup';
import { getSHA256Hash } from '../lib/string';
import { customFetch } from '../lib/http';
import { getLocalServerHostname } from '../lib/env';

const styles = () => ({
  wrapper: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
    zIndex: 9999
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    marginBottom: 25
  },
  error: {
    color: 'rgba(255, 0, 0, 0.9)',
    marginTop: 0
  }
});

const PasscodeModal = ({ classes, onSuccess }) => {
  const [hasError, setHasError] = useState(false);
  const [passcode, _setPasscode] = useState('');
  const passcodeRef = useRef(passcode);

  const setPasscode = (event) => {
    const { value } = event.target;

    passcodeRef.current = value;

    setHasError(false);
    _setPasscode(value);
  };

  const handleSubmit = () => {
    customFetch(`${getLocalServerHostname()}/api/passcode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ passcode: getSHA256Hash(passcodeRef.current) })
    })
      .then(onSuccess)
      .catch(() => setHasError(true));
  };

  useKeyup(ENTER, () => handleSubmit(passcodeRef.current));

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <CustomImage
          className={classes.row}
          src="/logo.png"
        />
        <p className={classes.row}>Enter the passcode to continue</p>
        <TextField
          label="Passcode"
          onChange={(event) => setPasscode(event)}
          type="password"
          value={passcode}
          variant="filled"
        />
        <CustomButton
          className={classes.row}
          disabled={!passcode}
          onClick={() => handleSubmit(passcode)}
        >
          enter
        </CustomButton>
        {hasError && (
          <p className={classes.error}>Please enter a valid passcode.</p>
        )}
      </div>
    </div>
  );
};

PasscodeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  PasscodeModal
);
