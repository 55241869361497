import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';

const ExitFullscreenButton = ({ className, text }) => {
  const handleClick = () => {
    document.exitFullscreen();
  };

  return (
    <CustomButton
      className={className}
      onClick={handleClick}
      size="small"
    >
      {text}
    </CustomButton>
  );
};

ExitFullscreenButton.defaultProps = {
  className: '',
  text: 'exit fullscreen'
};

ExitFullscreenButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

export default ExitFullscreenButton;
