import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT } from '../styles/global';
import ZoomUserGuideTableOfContents from './ZoomUserGuideTableOfContents';
import ZoomUserGuideInstalling from './ZoomUserGuideInstalling';
import ZoomUserGuideUsing from './ZoomUserGuideUsing';
import ZoomUserGuideUninstalling from './ZoomUserGuideUninstalling';
import ZoomUserGuideTroubleshooting from './ZoomUserGuideTroubleshooting';
import ZoomUserGuideFAQ from './ZoomUserGuideFAQ';
import ZoomUserGuideSupport from './ZoomUserGuideSupport';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme)
  }
});

const ZoomUserGuide = ({ classes }) => {
  useEffect(() => {
    const { hash } = window.location;
    const elementToFocusID = hash.substring(1);

    if (elementToFocusID) {
      const elementToFocus = document.getElementById(elementToFocusID);

      if (elementToFocus) {
        elementToFocus.scrollIntoView();
      }
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta
          content="noindex"
          name="robots"
        />
      </Helmet>
      <p className={classes.header}>LGN Games for Zoom User Guide</p>
      <ZoomUserGuideTableOfContents />
      <ZoomUserGuideInstalling />
      <ZoomUserGuideUsing />
      <ZoomUserGuideUninstalling />
      <ZoomUserGuideTroubleshooting />
      <ZoomUserGuideFAQ />
      <ZoomUserGuideSupport />
    </div>
  );
};

ZoomUserGuide.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  ZoomUserGuide
);
