import { ShowLoginDialog, HideLoginDialog, SetLoginDialogTab } from '../enums/action-type';
import LoginDialogTab from '../enums/login-dialog-tab';

const initialState = {
  show: false,
  tab: LoginDialogTab.GUEST
};

export default (state = initialState, action) => {
  const stateClone = { ...state };

  switch (action.type) {
    case ShowLoginDialog: {
      stateClone.show = true;

      if (action.tab) {
        stateClone.tab = action.tab;
      }

      return stateClone;
    }
    case HideLoginDialog: {
      stateClone.show = false;
      return stateClone;
    }
    case SetLoginDialogTab: {
      stateClone.tab = action.tab;
      return stateClone;
    }
    default:
      return state;
  }
};
