import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ProgressiveMedia from '../containers/ProgressiveMedia';
import CustomButton from './CustomButton';
import { isMobileDevice } from '../lib/browser';

const styles = (theme) => ({
  game: {
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: 5,
    boxShadow: '0 0 5px 0px rgb(255 255 255 / 20%)',
    overflow: 'hidden',
    '&:hover': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0
    }
  },
  infoWrapper: {
    width: '100%',
    boxSizing: 'border-box',
    color: '#d9d9d9',
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  gameInfo: {
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    }
  },
  paragraph: {
    color: 'white',
    textDecoration: 'none',
    margin: 0,
    fontSize: 16,
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
      fontSize: 14
    }
  },
  shortDescription: {
    paddingRight: 25
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  image: {
    width: '100%'
  },
  buttons: {
    display: 'flex',
  },
  button: {
    marginRight: 0,
    width: 220,
    [theme.breakpoints.down('xs')]: {
      width: 120
    }
  },
  learnMoreButton: {
    marginRight: 8
  },
});

const mapStateToProps = (state) => ({
  config: state.config
});

const HomeGameSummary = ({ classes, className, game }) => {
  const params = new URLSearchParams(window.location.search);
  const useSmallButton = useMediaQuery('(max-width: 600px)');

  const getPlayLinkPath = () => {
    if (isMobileDevice()) {
      if (game.slug === 'poker') {
        return '/mobile';
      }

      return `/${game.slug}/home?${params.toString()}`;
    }

    return `/games/${game.slug}/join?${params.toString()}`;
  };

  const handleImageSizing = () => {
    const FORTY_FOUR_FIFTEEN_ASPECT_RATIO_DIVISOR = 0.340909090909091;
    const heroImages = document.querySelectorAll('.home-hero-image');

    heroImages.forEach((heroImage) => {
      const currentWidth = heroImage.offsetWidth;
      heroImage.style.height = `${currentWidth * FORTY_FOUR_FIFTEEN_ASPECT_RATIO_DIVISOR}px`; // eslint-disable-line
    });
  };

  useEffect(() => {
    handleImageSizing();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleImageSizing);

    return () => {
      window.removeEventListener('resize', handleImageSizing);
    };
  }, []);

  return (
    <div className={classNames(classes.game, className)}>
      <Link to={getPlayLinkPath()}>
        <ProgressiveMedia
          altText={`${game.name} gameplay`}
          className={classNames(classes.image, 'home-hero-image')}
          mediaSource={game.media.images[0]}
        />
      </Link>
      <div className={classes.infoWrapper}>
        <div className={classes.gameInfo}>
          <p className={classNames(classes.paragraph, classes.name)}>{game.name}</p>
          <p className={classNames(classes.paragraph, classes.shortDescription)}>
            {game.shortDescription}
          </p>
        </div>
        <div className={classes.buttons}>
          <CustomButton
            className={classNames(classes.button, classes.learnMoreButton)}
            isInternalLinkButton
            linkPath={`/${game.slug}/home?${params.toString()}`}
            size={useSmallButton ? 'small' : 'medium'}
          >
            learn more
          </CustomButton>
          {(game.slug === 'poker' || !isMobileDevice()) && (
            <CustomButton
              className={classes.button}
              isInternalLinkButton
              linkPath={getPlayLinkPath()}
              size={useSmallButton ? 'small' : 'medium'}
            >
              play now
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};

HomeGameSummary.defaultProps = {
  className: ''
};

HomeGameSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  config: PropTypes.shape({
    mediaPath: PropTypes.string
  }).isRequired,
  game: PropTypes.shape({
    colors: PropTypes.arrayOf(
      PropTypes.string
    ).isRequired,
    media: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      logo: PropTypes.string
    }).isRequired,
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps)(
    HomeGameSummary
  )
);
