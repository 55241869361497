import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { isZoom } from '../lib/env';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    backgroundColor: '#18181b',
    flexDirection: 'column',
    padding: '60px 25px 0 25px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '60px 5px 0 5px'
    }
  },
  row: {
    paddingBottom: 50,
    textAlign: 'center'
  },
  links: {
    color: '#c71e04',
    wordBreak: 'break-all',
    '& > a': {
      color: 'rgba(247, 247, 248, 1)',
      fontSize: 14,
      padding: '0 8px',
    },
    [theme.breakpoints.down('xs')]: {
      lineHeight: 2
    }
  },
  disclaimerAndCopyright: {
    color: '#0a4ca7',
    fontSize: 14,
    lineHeight: 1.5,
    maxWidth: 800
  }
});

const mapStateToProps = (state) => ({
  user: state.user
});

const Footer = ({ className, classes, user }) => (
  <footer className={classNames(
    classes.wrapper,
    className
  )}
  >
    {!isZoom() && (
      <div className={classNames(
        classes.links,
        classes.row
      )}
      >
        <a
          href="https://www.flowplay.com/company"
          rel="noopener noreferrer"
          target="_blank"
        >
          About
        </a>
        <Link
          target="_blank"
          to="/privacy-policy"
        >
          Privacy
        </Link>
        <Link
          target="_blank"
          to="/terms-of-use"
        >
          Terms
        </Link>
        <Link
          target="_blank"
          to="/support"
        >
          Support
        </Link>
        <Link to="/downloads">
          Downloads
        </Link>
        {user.isAuthenticated && (
          <>
            <Link to="/feedback">
              Send Feedback
            </Link>
          </>
        )}
      </div>
    )}
    <div className={classNames(
      classes.disclaimerAndCopyright,
      classes.row
    )}
    >
      Intended for an adult audience and does not offer real money gambling or an opportunity
      to win real money or prizes. Practice or success at social gambling does not imply success
      at real money gambling. All rights reserved FlowPlay, Inc.
    </div>
    <div className={classNames(
      classes.disclaimerAndCopyright,
      classes.row
    )}
    >
      Copyright &copy; {new Date().getFullYear()}&nbsp;
      <a
        href="https://www.flowplay.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        FlowPlay, Inc.
      </a>
      &nbsp;All Rights Reserved.
    </div>
  </footer>
);

Footer.defaultProps = {
  className: ''
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired
  }).isRequired
};

export default
withStyles(styles)(
  connect(mapStateToProps)(
    Footer
  )
);
