import {
  SetIsMediaPlatformActive, SetHasMediaPermissions, SetIsPokerActive,
  SetIsCheckingMediaPermissions
} from '../enums/action-type';

export const setIsMediaPlatformActive = (isActive) => (
  { type: SetIsMediaPlatformActive, isActive }
);

export const setIsPokerActive = (isActive) => (
  { type: SetIsPokerActive, isActive }
);

export const setHasMediaPermissions = (hasMediaPermissions) => (
  { type: SetHasMediaPermissions, hasMediaPermissions }
);

export const setIsCheckingMediaPermissions = (isCheckingMediaPermissions) => (
  { type: SetIsCheckingMediaPermissions, isCheckingMediaPermissions }
);
