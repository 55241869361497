import { GetHost, GetHostComplete, GetHostError } from '../enums/action-type';
import { customFetch, getServiceError } from '../lib/http';

export const getHost = () => (
  (dispatch) => (
    new Promise((resolve, reject) => {
      // config.json comes from the Node server that hosts this client app rather
      // than the Kitsune server, so make sure that it's looking for it at localhost:83
      // rather than the Kitsune server proxy defined in package.json for development.
      const path = window.location.hostname === 'localhost' ? 'http://localhost:83/config.json' : '/config.json';

      dispatch({ type: GetHost });

      customFetch(path)
        .then((response) => {
          dispatch({ type: GetHostComplete, host: response.main });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: GetHostError });
          reject(getServiceError(error));
        });
    })
  )
);
