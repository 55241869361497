import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomButton from './CustomButton';
import CustomImage from '../containers/CustomImage';
import { BROWSER_BUTTON_ICON } from '../styles/global';
import Browser from '../enums/browser';

const styles = (theme) => ({
  icon: {
    ...BROWSER_BUTTON_ICON(theme)
  }
});

const getBrowserIcon = (browser) => {
  switch (browser) {
    case Browser.EDGE:
      return 'edge-icon.png';
    case Browser.FIREFOX:
      return 'firefox-icon.png';
    case Browser.CHROME:
      return 'chrome-icon.png';
    default:
      return '';
  }
};

const getBrowserName = (browser) => {
  switch (browser) {
    case Browser.EDGE:
      return 'Edge';
    case Browser.FIREFOX:
      return 'Firefox';
    case Browser.CHROME:
      return 'Chrome';
    default:
      return '';
  }
};

const BrowserButton = ({
  className, linkPath, classes, browser, text
}) => (
  <CustomButton
    className={className}
    isExternalLinkButton
    linkPath={linkPath}
  >
    <CustomImage
      className={classes.icon}
      src={getBrowserIcon(browser)}
    />
    {text || getBrowserName(browser)}
  </CustomButton>
);

BrowserButton.defaultProps = {
  className: '',
  text: ''
};

BrowserButton.propTypes = {
  browser: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  linkPath: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default
withStyles(styles)(
  BrowserButton
);
