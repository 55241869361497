import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CustomButton from './CustomButton';
import { grantCookiePermission, denyCookiePermission } from '../lib/browser';
import { LIGHT_BOX_SHADOW } from '../styles/global';
import { DARK, TEXT_COLOR } from '../styles/palette';

const styles = (theme) => ({
  wrapper: {
    position: 'fixed',
    margin: 'auto',
    bottom: 0,
    left: 0,
    right: 0,
    width: 550,
    backgroundColor: DARK,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: 15,
    lineHeight: 1.4,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    ...LIGHT_BOX_SHADOW(theme),
    [theme.breakpoints.down('sm')]: {
      width: 300
    },
    [theme.breakpoints.down('xs')]: {
      width: 200
    }
  },
  text: {
    color: TEXT_COLOR,
    marginRight: 8
  },
  denyButton: {
    backgroundColor: 'rgba(199, 199, 199, 0.9)',
    '&:hover': {
      backgroundColor: 'rgba(199, 199, 199, 0.8)'
    }
  }
});

const CookieBanner = ({ classes, onClick }) => {
  const handleAllowClick = () => {
    grantCookiePermission();
    onClick();
  };

  const handleDenyClick = () => {
    denyCookiePermission();
    onClick();
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>
        We use cookies to collect data in order to improve your gaming experience.
      </p>
      <div>
        <CustomButton
          className={classes.denyButton}
          onClick={handleDenyClick}
          primary={false}
          size="small"
        >
          Deny
        </CustomButton>
        <CustomButton
          onClick={handleAllowClick}
          size="small"
        >
          Allow
        </CustomButton>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default
withStyles(styles)(
  CookieBanner
);
