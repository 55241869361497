import React from 'react';
import Maintenance from './Maintenance';

const PokerMaintenance = () => (
  <Maintenance
    gameName="LGN Poker"
    image="/lgn-poker-logo.png"
  />
);

export default PokerMaintenance;
