import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CONTENT_WRAPPER, CONTENT_HEADER, CONTENT_TEXT_WRAPPER, CONTENT_TEXT
} from '../styles/global';
import BrowserButton from './BrowserButton';
import { getCurrentBrowserName } from '../lib/browser';
import Browser from '../enums/browser';

const styles = (theme) => ({
  wrapper: {
    ...CONTENT_WRAPPER(theme)
  },
  header: {
    ...CONTENT_HEADER(theme)
  },
  textWrapper: {
    ...CONTENT_TEXT_WRAPPER(theme)
  },
  text: {
    ...CONTENT_TEXT(theme),
    '&:last-child': {
      marginBottom: 0
    }
  }
});

const browserName = getCurrentBrowserName();

const getLinkPath = () => {
  switch (browserName) {
    case Browser.EDGE:
      return 'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
    case Browser.FIREFOX:
      return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
    case Browser.CHROME:
      return 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop';
    default:
      return '';
  }
};

const MediaPermissionsHelp = ({ classes }) => (
  <div className={classes.wrapper}>
    <p className={classes.header}>Media Permissions in LGN</p>
    <div className={classes.textWrapper}>
      <p className={classes.text}>
        LGN provides a live gaming experience by using your camera and microphone
        to connect you directly with friends during gameplay.
      </p>
      <p className={classes.text}>
        Please update your browser settings to allow play.livegamenight.com
        to use your camera and microphone, then return to the game browser window
        and refresh to continue playing with video and audio input.
      </p>
      <p className={classes.text}>
        If you&apos;ve previously granted media permissions to your browser,
        please ensure your camera is not in use by another app.
      </p>
      <p className={classes.text}>
        Click the button below for details on how to enable video in {browserName}.
      </p>
    </div>
    <BrowserButton
      browser={browserName}
      linkPath={getLinkPath()}
      text="Learn More"
    />
  </div>
);

MediaPermissionsHelp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  MediaPermissionsHelp
);
