import { GetProductsComplete } from '../enums/action-type';

export default (state = [], action) => {
  switch (action.type) {
    case GetProductsComplete:
      return action.products;
    default:
      return state;
  }
};
