import { getSignatureAndTimestamp, customFetch } from './http';

export const getAgoraToken = (host, channel, userId) => {
  const EIGHT_HOURS_IN_SECONDS = 28800;
  const PORTAL_GAME_ID = '1';
  const { signature, timestamp } = getSignatureAndTimestamp(PORTAL_GAME_ID);

  return customFetch(`${host}kitsune/api/agora/token?channel=${channel}&user_id=${userId}&lifetime=${EIGHT_HOURS_IN_SECONDS}&timestamp=${timestamp}&game_id=${PORTAL_GAME_ID}&signature=${signature}`);
};

export const getGameCodeAsInt = (gameId, roomCode) => {
  // roomCode is 4 chars [A-Z]
  const c0 = roomCode.charCodeAt(0) - 65;
  const c1 = roomCode.charCodeAt(1) - 65;
  const c2 = roomCode.charCodeAt(2) - 65;
  const c3 = roomCode.charCodeAt(3) - 65;

  return (gameId * (2 ** 20)) + (c3 * (2 ** 15)) + (c2 * (2 ** 10)) + (c1 * (2 ** 5)) + c0;
};
