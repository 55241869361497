export default {
  PERSONAL_REQUEST: {
    value: 1,
    text: 'Personal Request'
  },
  LANGUAGE: {
    value: 2,
    text: 'Language'
  },
  VIOLENT_THREAT: {
    value: 3,
    text: 'Violent Threats'
  },
  CRIMINAL: {
    value: 4,
    text: 'Criminal Behavior'
  }
};
